import { EVENT_GTM_PRODUCT_ADD_TO_CART } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const handleSuccessSave = (args, callback, instance) => {
    const {
        activeProduct,
        event,
        carOptions: {
            items,
            insurance: { product, value }
        } = {}
    } = instance.props;

    if (activeProduct) {
        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: activeProduct,
            otherProducts: [...Object.values(items || {}), ...(value ? [{ ...product, insurancePrice: value }] : [])]
        });
    }

    callback(...args);
};

export default {
    'Scandipwa/Component/CarActions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/CarActions/Container': {
        'member-function': {
            handleSuccessSave
        }
    }
};
