import transformToNameValuePair from 'SourceUtil/Form/Transform';
import { formatPrice } from 'Util/Price/Price';

export * from 'SourceUtil/Form/Transform';

/** @namespace Scandipwa/Util/Form/Transform/convertAttributesOptionsToFormFields */
export const convertAttributesOptionsToFormFields = (attributeOptions, isYearOptions = false) => {
    if (isYearOptions) {
        return Object.values(attributeOptions).map(
            ({ label }) => ({ id: label, value: label, label })
        );
    }

    return Object.values(attributeOptions).map(
        ({ label, value_string }) => ({ id: value_string, value: value_string, label })
    );
};

/** @namespace Scandipwa/Util/Form/Transform/createOptionsFromMinMaxPrice */
export function createOptionsFromMinMaxPrice(minPrice, maxPrice, step, currency) {
    const firstBreakpoint = (Math.ceil(minPrice / step) * step);
    // eslint-disable-next-line fp/no-let
    let currentBreakpoint = firstBreakpoint;
    const breakpointsArray = [{
        label: `${__('Under')} ${formatPrice(currentBreakpoint, currency)}`,
        value_string: `${minPrice}_${currentBreakpoint}`
    }];

    while (currentBreakpoint + step < maxPrice) {
        currentBreakpoint += step;
        breakpointsArray.push({
            label: `${formatPrice(currentBreakpoint - step, currency)}-${formatPrice(currentBreakpoint, currency)}`,
            value_string: `${currentBreakpoint - step}_${currentBreakpoint}`
        });
    }

    if (maxPrice - minPrice > step) {
        breakpointsArray.push({
            label: `${__('Over')} ${formatPrice(currentBreakpoint, currency)}`,
            value_string: `${currentBreakpoint}_${maxPrice}`
        });
    } else if (minPrice < currentBreakpoint && maxPrice > currentBreakpoint) {
        breakpointsArray.push({
            label: `${__('Over')} ${formatPrice(currentBreakpoint, currency)}`,
            value_string: `${currentBreakpoint}_${maxPrice}`
        });
    }

    return breakpointsArray.reduce((result, option) => {
        const { value_string } = option;

        // eslint-disable-next-line no-param-reassign
        result[value_string] = option;
        return result;
    }, {});
}

export default transformToNameValuePair;
