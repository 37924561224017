export {
    APPEND_PAGE,
    UPDATE_PRODUCT_LIST_ITEMS,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    appendPage,
    updateProductListItems,
    updateLoadStatus,
    updatePageLoadingStatus
} from 'SourceStore/ProductList/ProductList.action';

export const UPDATE_VISIBLE_PAGES = 'UPDATE_VISIBLE_PAGES';

/** @namespace Scandipwa/Store/ProductList/Action/updateVisiblePages */
export const updateVisiblePages = (visiblePages) => ({
    type: UPDATE_VISIBLE_PAGES,
    visiblePages
});
