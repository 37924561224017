import {
    getInitialState as sourceGetInitialState,
    WishlistReducer as sourceWishlistReducer
} from 'SourceStore/Wishlist/Wishlist.reducer';

import { UPDATE_WISHLIST_LOADED } from './Wishlist.action';

export * from 'SourceStore/Wishlist/Wishlist.reducer';

/** @namespace Scandipwa/Store/Wishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isWishlistLoaded: false
});

/** @namespace Scandipwa/Store/Wishlist/Reducer/WishlistReducer */
export const WishlistReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        isWishlistLoaded
    } = action;

    switch (type) {
    case UPDATE_WISHLIST_LOADED:
        return {
            ...state,
            isWishlistLoaded
        };

    default:
        return sourceWishlistReducer(state, action);
    }
};

export default WishlistReducer;
