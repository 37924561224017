/* eslint-disable react/prop-types, react/no-unused-state */
import { createContext, PureComponent } from 'react';

export const UIContext = createContext({
    isFooterVisible: true
});
UIContext.displayName = 'UIContext';

/** @namespace Scandipwa/Context/ui/UIcontext */
export class UIContextProvider extends PureComponent {
    state = {
        footer: {
            isFooterVisible: true,
            isPdp: false,
            setFooterVisible: this.setFooterVisible.bind(this),
            setIsPdp: this.setIsPdp.bind(this)
        }
    };

    setIsPdp(value) {
        const { footer: { isPdp } } = this.state;

        if (isPdp === value) {
            return;
        }

        this.setState((prevState) => ({
            ...prevState,
            footer: {
                ...prevState.footer,
                isPdp: value
            }
        }));
    }

    setFooterVisible(value) {
        const { footer: { isFooterVisible } } = this.state;

        if (isFooterVisible === value) {
            return;
        }

        this.setState((prevState) => ({
            ...prevState,
            footer: {
                ...prevState.footer,
                isFooterVisible: value
            }
        }));
    }

    render() {
        const { children } = this.props;

        return (
            <UIContext.Provider value={ this.state }>
                { children }
            </UIContext.Provider>
        );
    }
}

export default UIContextProvider;
