export * from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

export const CITY_LIST = [
    'Doha',
    'Al Daayen',
    'Al Khor',
    'Al Rayyan',
    'Al Shahaniyah',
    'Al Shamal',
    'Al Wakra',
    'Economic Zone',
    'Lusail',
    'Marina',
    'Mesaieed',
    'Pearl Qatar',
    'Ras Laffan',
    'Umm Salal',
    'West Bay'
];

export const SALUTATION_LIST = [
    () => __('Mr.'),
    () => __('Ms.')
];

export const SALUTATION_VALUE_LIST = [
    'Mr.',
    'Ms.'
];

export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
