/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { formatPrice } from 'Util/Price';

import './ProductPrice.override.style';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    // Overridden to get correct minimum price from schema
    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            priceRange = {}
        } = this.props;

        const {
            minimum_price: {
                final_price: {
                    value: contentPrice = 0
                } = {}
            } = {}
        } = priceRange || {};

        return isSchemaRequired ? { itemProp: 'price', content: contentPrice } : {};
    }

    /**
     * Overridden to returncorrect highPrice
     */
    renderRegularPrice(price) {
        const {
            value,
            valueFormatted
        } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <del block="ProductPrice" elem="HighPrice">{ valueFormatted }</del>
        );
    }

    renderPrice(price, label) {
        const {
            discountPercentage,
            isWithOptions,
            optionsTotal,
            priceCurrency
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: carPriceFormatted = 0
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        const priceWithOptions = (priceValue + optionsTotal).toFixed(2);
        const totalPrice = isWithOptions
            ? formatPrice(priceWithOptions, priceCurrency)
            : carPriceFormatted;

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { totalPrice }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            isWithOptions,
            optionsTotal,
            priceCurrency
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        const priceWithOptions = (originalPriceValue + optionsTotal).toFixed(2);
        const totalOriginalPrice = isWithOptions
            ? formatPrice(priceWithOptions, priceCurrency)
            : originalPriceFormatted;

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { totalOriginalPrice }
            </del>
        );
    }

    /**
     * Overridden to change configurable product label
     */
    priceLabelTypeMap = {
        ...this.priceLabelTypeMap,
        [PRODUCT_TYPE.configurable]: ''
    };
}

export default ProductPriceComponent;
