import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/UnsubscribeNewsletter/Query */
export class UnsubscribeNewsletterQuery {
    getUnsubscribeMutation(options) {
        const { code = '', id = 0 } = options;

        const mutation = new Field('unsubscribeFromNewsletter');

        mutation.addArgument('code', 'String!', code);
        mutation.addArgument('id', 'Int!', id);

        return mutation;
    }
}

export default new UnsubscribeNewsletterQuery();
