import { MY_VEHICLES_BUY_TABS } from 'Route/MyVehicles/MyVehicles.config';

export * from 'SourceComponent/Router/Router.config';

export const SMS_SENDING_POPUP = 'SMS_SENDING_POPUP';
export const SHOWROOM_BOOKING = 'SHOWROOM_BOOKING';
export const TESTDRIVE_BOOKING = 'TESTDRIVE_BOOKING';
export const SERVICE_BOOKING = 'SERVICE_BOOKING';
export const MY_VEHICLES = 'MY_VEHICLES';
export const MY_ACCOUNT_LOGIN_POPUP = 'MY_ACCOUNT_LOGIN_POPUP_ID';
export const MY_ACCOUNT_FORGOT_PW_POPUP = 'MY_ACCOUNT_FORGOT_PW_POPUP_ID';
export const SELL = 'SELL';
export const PLP_PATH = '/buy';
export const SELL_PATH = '/sell';
export const TEST_DRIVE_PATH = '/testdrive';
export const MY_VEHICLES_PATH = '/myvehicles';
export const MY_VEHICLES_BUY_PATH = `${MY_VEHICLES_PATH}/${MY_VEHICLES_BUY_TABS.id}`;
export const SELL_ORDER_PAGE_URL = '/sell-order';
export const SELL_ORDER_PAGE = 'SELL_ORDER_PAGE';
export const MY_VEHICLES_SELL_PATH = `${MY_VEHICLES_PATH}${SELL_PATH}`;
export const SHOWROOM_PATH = '/showroom';
export const UNSUBSCRIBE_PATH = '/unsubscribe';
export const UNSUBSCRIBE_PAGE = 'UNSUBSCRIBE_PAGE';
