import {
    FUEL_TYPE,
    TRACTION,
    TRANSMISSION
} from 'Component/CategoryConfigurableAttributes/CategoryConfigurableAttributes.config';
import { MILEAGE } from 'Route/ProductPage/ProductPage.config';
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { STRING_ONLY_ATTRIBUTE_CODES } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.config';
import { removeColorCode } from 'Util/Product';

import './ProductAttributeValue.override.style';

/** @namespace Scandipwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderSelectAttribute() {
        const { attribute: { attribute_value, attribute_code, has_swatch } } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const {
            label, labelText, count, swatch_data
        } = attributeOption;

        // skip attributes without valid swatches
        if (!swatch_data && has_swatch) {
            return null;
        }

        const isNoSwatchAttribute = attribute_code === FUEL_TYPE
            || attribute_code === TRACTION
            || attribute_code === TRANSMISSION;

        if (!swatch_data || isNoSwatchAttribute || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(labelText || __('N/A'), null, count);
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(value, labelText, count);
        case '1':
        case '2':
            return this.renderColorOrImageValue(value, label, type === '1');
        default:
            return this.renderStringValue(labelText || __('N/A'), labelText, count);
        }
    }

    renderColorOrImageValue(color, label, isColor) {
        const { isFormattedAsText } = this.props;

        if (isFormattedAsText) {
            return removeColorCode(label) || __('N/A');
        }

        return isColor
            ? super.renderColorValue(color, label)
            : super.renderImageValue(color, label);
    }

    renderMileageAttribute() {
        const { attribute: { attribute_value } } = this.props;

        const nf = new Intl.NumberFormat('en-US');
        const value = nf.format(attribute_value) || parseFloat(attribute_value).toFixed(0);

        return this.renderStringValue(__('%s km', value));
    }

    renderAttributeByType() {
        const { attribute: { attribute_type, attribute_code } } = this.props;

        if (attribute_type === 'price' && attribute_code === MILEAGE) {
            return this.renderMileageAttribute();
        }

        return super.renderAttributeByType();
    }

    render() {
        const { isAvailable, showUnavailableOptions = false } = this.props;
        const isNotAvailable = !isAvailable;

        if (isNotAvailable && !showUnavailableOptions) {
            return null;
        }

        return super.render();
    }
}

export default ProductAttributeValueComponent;
