import { getQueryParam } from 'Util/Url';

/** @namespace CatalogLoadMore/Util/PreviousPageUrl/getPreviousPageUrl */
export const getPreviousPageUrl = (location) => {
    const currentPageNumber = getQueryParam('page', location);

    if (currentPageNumber == 2) {
        return `${location.origin + location.pathname}`;
    }

    return `${location.origin
        + location.pathname}?page=${currentPageNumber && currentPageNumber > 1 ? parseInt(currentPageNumber, 10) - 1 : 1}`;
};
