import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.style';

/** @namespace Scandipwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends ProductListPage {
    /**
     * Overridden to add isPlp flag
     */
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            }
        } = this.props;

        return items.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout }
              isPlp
              { ...this.containerProps() }
            />
        ));
    }
}

export default withRouter(ProductListPageComponent);
