/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { FIELD_TYPE } from 'Component/Field/Field.config';
import RemoveOptionButton from 'Component/RemoveOptionButton';
import ToolTipButton from 'Component/ToolTipButton';
import { FieldFile as SourceFieldFileComponent } from 'SourceComponent/FieldFile/FieldFile.component';
import { ReactComponent as PaperClipIcon } from 'Style/icons/paperclip.svg';

/** @namespace Scandipwa/Component/FieldFile/Component */
export class FieldFile extends SourceFieldFileComponent {
    renderBrowseButton() {
        const {
            attr = {},
            events = {},
            setRef,
            renderErrorPopup
        } = this.props;

        return (
            <div block="FieldFile" elem="BrowseButton">
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ FIELD_TYPE.file }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                />
                { renderErrorPopup() }
                <span block="FieldFile" elem="ButtonText">
                    <PaperClipIcon />
                    { __('Browse') }
                </span>
            </div>
        );
    }

    renderBottom() {
        const { fileName, clearFile } = this.props;

        if (fileName) {
            return (
                <div block="FieldFile" elem="InputField">
                    <span block="FieldFile" elem="FileName">
                        { fileName }
                    </span>
                    <RemoveOptionButton handler={ clearFile } />
                </div>
            );
        }

        return null;
    }

    renderToolTipButton() {
        const {
            hasTooltip, tooltipContent, attr: { id = {} } = {}, isTooltipHoverable, isMobile, tooltipDirection
        } = this.props;

        const isHoverable = isTooltipHoverable && !isMobile;

        if (hasTooltip) {
            return (
                <ToolTipButton
                  isHoverable={ isHoverable }
                  tooltipDirection={ tooltipDirection }
                  tooltipContent={ tooltipContent }
                  id={ id }
                />
            );
        }

        return null;
    }

    renderSublabeContent() {
        const { subLabel } = this.props;

        if (Array.isArray(subLabel)) {
            return subLabel.map((line) => <p>{ line }</p>);
        }

        return (subLabel);
    }

    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="FieldFile" elem="SubLabelText">
                { this.renderSublabeContent() }
            </div>
        );
    }

    renderDesktop() {
        const {
            label = '',
            fileName,
            additionalField,
            requiredTag
        } = this.props;

        return (
            <div block="FieldFile" elem="FileWrapper">
                <div block="FieldFile" elem="Top" mods={ { hasFile: !!fileName } }>
                    <div block="FieldFile" elem="TextWrapper">
                        <span block="FieldFile" elem="LabelText">
                            { label }
                            { requiredTag }
                            { this.renderToolTipButton() }
                        </span>
                        { this.renderSubLabel() }
                    </div>
                    { this.renderBrowseButton() }
                </div>
                { additionalField }
                <div block="FieldFile" elem="Bottom" mods={ { isFileSelected: !!fileName } }>
                    { this.renderBottom() }
                </div>
            </div>
        );
    }

    renderMobile() {
        const {
            label = '',
            fileName,
            additionalField,
            requiredTag
        } = this.props;

        return (
            <div block="FieldFile" elem="FileWrapper" mods={ { hasFile: !!fileName } }>
                <div block="FieldFile" elem="Top">
                    <div block="FieldFile" elem="TextWrapper">
                        <span block="FieldFile" elem="LabelText">
                            { label }
                            { requiredTag }
                            { this.renderToolTipButton() }
                        </span>
                        { this.renderSubLabel() }
                    </div>
                </div>
                { additionalField }
                <div block="FieldFile" elem="Bottom" mods={ { isFileSelected: !!fileName } }>
                    { this.renderBottom() }
                </div>
                { this.renderBrowseButton() }
            </div>
        );
    }

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return this.renderMobile();
        }

        return this.renderDesktop();
    }
}
export default FieldFile;
