import { connect } from 'react-redux';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    ACCOUNT_LOGIN_URL,
    ACCOUNT_URL
} from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    STATE_LOGGED_IN,
    STATE_SIGN_IN
} from './MyAccountOverlay.config';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Scandipwa/Component/MyAccountOverlay/Container */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    // Overridden to add return to pdp and my vehicle logic after registering
    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const { isOverlayVisible } = this.props;
        const {
            location: {
                pathname,
                state: {
                    isMyVehicle = false,
                    isPdp = false,
                    prevLocation = '',
                    prevSearch = ''
                } = {}
            }
        } = history;

        const {
            isSignedIn,
            hideActiveOverlay,
            isCheckout,
            goToPreviousHeaderState,
            redirectToDashboard
        } = this.props;

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (isSignedIn !== prevIsSignedIn) {
            hideActiveOverlay();

            if (isCheckout) {
                goToPreviousHeaderState();
            }
        }

        if (
            newMyAccountState !== STATE_LOGGED_IN
            && pathname.includes(ACCOUNT_URL)
            && !isOverlayVisible
        ) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL) });
        }

        // Added is pdp or is my vehicle check
        if (newMyAccountState === STATE_LOGGED_IN) {
            if (pathname.includes(ACCOUNT_URL) && !isPdp && !isMyVehicle) {
                history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
            } else if (
                !pathname.includes(CHECKOUT_URL)
                && redirectToDashboard
                && !isPdp
                && !isMyVehicle
            ) {
                history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
            } else if ((isPdp && prevLocation) || (isMyVehicle && prevLocation)) {
                // added `shouldAddToCart` to determine whether should continue with cart creating or should not
                history.push({ pathname: prevLocation, search: prevSearch }, { shouldAddToCart: true });
            }
        }
    }

    // Overridden to move notification to other function
    static getDerivedStateFromProps(props, state) {
        const {
            isPasswordForgotSend,
            isOverlayVisible,
            isMobile
        } = props;

        const {
            isPasswordForgotSend: currentIsPasswordForgotSend,
            state: myAccountState
        } = state;

        const { location: { pathname, state: { isForgotPassword } = {} } } = history;

        const stateToBeUpdated = {};
        const customerIsSignedIn = isSignedIn();

        if (!isMobile) {
            if (!isOverlayVisible && !customerIsSignedIn) {
                if (pathname !== '/forgot-password' && !isForgotPassword) {
                    stateToBeUpdated.state = STATE_SIGN_IN;
                }
            } else if (!isOverlayVisible && customerIsSignedIn) {
                stateToBeUpdated.state = STATE_LOGGED_IN;
            }
        }

        if (myAccountState !== STATE_LOGGED_IN && customerIsSignedIn) {
            stateToBeUpdated.state = STATE_LOGGED_IN;
        }

        if (myAccountState === STATE_LOGGED_IN && !customerIsSignedIn) {
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        if (isPasswordForgotSend !== currentIsPasswordForgotSend) {
            stateToBeUpdated.isPasswordForgotSend = isPasswordForgotSend;
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        return Object.keys(stateToBeUpdated).length ? stateToBeUpdated : null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
