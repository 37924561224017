/* eslint-disable max-lines */
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductsField() {
        const products = new Field('products')
            .addFieldList(this._getProductFieldList());

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getProductFieldList() {
        const { isSupportive = false, isTestDrive = false } = this.options;

        switch (true) {
        case isSupportive:
            return this._getSupportiveProductFields();
        case isTestDrive:
            return this._getTestDriveCarsFieldList();
        default:
            return this._getProductFields();
        }
    }

    _getTestDriveCarsFieldList() {
        const items = new Field('items').addFieldList([
            'name',
            'sku'
        ]);

        return [items];
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [
                this._getItemsField(),
                'total_count'
            ];
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    /*
    * Overridden to add isOrder flag
    */
    _getCartProductInterfaceFields(isOrder = false, isSingleOrder = false) {
        const singleOrderExcludedFields = !isSingleOrder ? [
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getCartConfigurableProductFragment(),
            this._getProductLinksField()
        ] : [];

        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            this._getProductThumbnailField(),
            this._getAttributesField(false, !isOrder),
            ...(isOrder ? [this._getCategoriesField()] : []),
            ...singleOrderExcludedFields
        ];
    }

    /*
    * Added automatic related product fields to product interface
     */
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct = false,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields except for stock fields (JAID-658) returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'salable_qty',
            this._getPriceRangeField()
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to huge number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(
                this._getAttributesField(isVariant)
            );
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getCustomizableProductFragment(),
                this._getCategoriesField()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        // added auto related fields
        if (isForLinkedProducts) {
            fields.push(
                this._getProductLinksField(),
                this._getARPField()
            );
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
            );

            // for variants of PDP requested product
            // added auto related fields
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    /*
    *   Added automatic related field
     */
    _getARPField() {
        return new Field('automatic_related_products')
            .addFieldList(this._getARPFields());
    }

    /*
    * Added auto related field list
     */
    _getARPFields() {
        return [
            'position',
            'link_type',
            'linked_product_sku',
            'rule_id',
            'block_name',
            'one_product_added',
            'insurance_rule',
            'related_products_type'
        ];
    }

    _getMediaGalleryFields() {
        return [
            ...super._getMediaGalleryFields(),
            'gallery_image_type'
        ];
    }

    _getSupportiveProductFields() {
        const { isPlp = false, isForWishlist = false } = this.options;

        const supportiveFieldList = this._getSupportiveProductFieldList();
        const variantsFieldName = isPlp && !isForWishlist ? 'variants_plp' : 'variants';

        const variantsField = new Field(variantsFieldName)
            .setAlias('variants')
            .addField(new Field('product').addFieldList(supportiveFieldList));

        const items = new Field('items').addFieldList([
            ...supportiveFieldList,
            new Fragment('ConfigurableProduct').addField(variantsField)
        ]);

        return [items];
    }

    _getSupportiveProductFieldList() {
        const {
            isSingleProduct = false
        } = this.options;

        const fields = [
            'id',
            'sku',
            'stock_status',
            this._getStockItemField()
        ];

        if (isSingleProduct) {
            fields.push(this._getARPField());
        }

        return fields;
    }

    _getFilterArgumentMap() {
        return {
            ...super._getFilterArgumentMap(),
            productIDArray: (id) => ({ id: { in: id } }),
            customerGroupId: () => ([])
        };
    }
}
export default new ProductListQuery();
