import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    HomeSlider,
    NewProducts,
    ProductListWidget,
    RecentlyViewedWidget,
    WidgetFactory as SourceWidgetFactory
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    AUTOMATIC_RELATED_PRODUCTS,
    FLEXIBLE_FORMS,
    SLIDER
} from './WidgetFactory.config';

import './WidgetFactory.override.style.scss';

export const FlexibleFormsWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
    '../../../packages/@scandiweb/flexibleforms/src/component/FlexibleFormsWidget'
));

export const AutomaticRelatedProductsWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-automatic-related" */
    '../RelatedProductsWidget'
));

export {
    ProductListWidget,
    NewProducts,
    HomeSlider,
    RecentlyViewedWidget
};

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [FLEXIBLE_FORMS]: {
            component: FlexibleFormsWidget
        },
        [AUTOMATIC_RELATED_PRODUCTS]: {
            component: AutomaticRelatedProductsWidget,
            fallback: this.renderRelatedProductsPlaceholder.bind(this)
        }
    };

    renderRelatedProductsPlaceholder() {
        return (
            <div
              block="WidgetFactory"
              elem="RelatedProductsPlaceholder"
            />
        );
    }

    renderDefaultFallback() {
        const { type } = this.props;

        if (type === SLIDER) {
            return this.renderSliderFallback();
        }

        return super.renderDefaultFallback();
    }

    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            formId,
            ruleId = null,
            conditionsEncoded,
            buttonType
        } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            if (type === AUTOMATIC_RELATED_PRODUCTS) {
                return (
                    <Widget
                      form_id={ formId }
                      rule_id={ ruleId }
                      sliderId={ sliderId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                      buttonType={ buttonType }
                    />
                );
            }

            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget
                      form_id={ formId }
                      rule_id={ ruleId }
                      sliderId={ sliderId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                      buttonType={ buttonType }
                    />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;
