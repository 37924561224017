import { formatPrice } from 'Util/Price';
import { getQueryParam } from 'Util/Url';

export { getFiltersCount } from 'SourceUtil/Category/Filters';

/** @namespace Scandipwa/Util/Category/Filters/getCategoryFiltersInitialState */
export function getCategoryFiltersInitialState() {
    const { location } = window;
    const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

    return selectedFiltersString.reduce((acc, filter) => {
        if (!filter) {
            return acc;
        }
        const [key, value] = filter.split(':');

        return { ...acc, [key]: value.split(',') };
    }, {});
}

/** @namespace Scandipwa/Util/Category/Filters/getPriceFilterLabel */
export const getPriceFilterLabel = (from, to, currencyCode, reverse = false) => {
    const priceFrom = formatPrice(from, currencyCode);
    const priceTo = formatPrice(to, currencyCode);

    if (from === '*') {
        return __('Up to %s', priceTo);
    }

    if (to === '*') {
        return __('From %s', priceFrom);
    }

    if (reverse) {
        return `${ priceTo } - ${ priceFrom }`;
    }

    return `${ priceFrom } - ${ priceTo }`;
};
