import { Field } from 'Util/Query';

import ProductLabelProviderQuery from '../query/ProductLabelProvider.query';

export const _getSupportiveProductFieldList = (args, method, instance) => {
    const fields = method.apply(instance, args);

    fields.push(new Field('am_label').addFieldList(ProductLabelProviderQuery.getItemFieldList()));

    return fields;
};

export const _getProductInterfaceFields = (args, method, instance) => {
    const fields = method.apply(instance, args);

    const {
        isARP = false
    } = instance.options;

    if (isARP) {
        fields.push(new Field('am_label').addFieldList(ProductLabelProviderQuery.getItemFieldList()));
    }

    return fields;
};

export const _getProductFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);

    fields.push(new Field('am_label').addFieldList(ProductLabelProviderQuery.getItemFieldList()));

    return fields;
};

export default {
    'Scandipwa/Query/ProductList/Query': {
        'member-function': {
            _getSupportiveProductFieldList,
            _getProductInterfaceFields
        }
    },
    'Scandipwa/Query/AutomaticRelatedProducts/Query': {
        'member-function': {
            _getProductFields
        }
    }
};
