/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
/** @namespace Scandipwa/Component/Product/Component */
export class Product extends SourceProduct {
    /**
     * New method to render price itemprop
     */
    renderProductItemProp() {
        const {
            productPrice: {
                price: {
                    finalPrice: {
                        value,
                        currency
                    } = {}
                } = {}
            } = {},
            stockMeta,
            metaLink,
            description
        } = this.props;

        const roundedPrice = Math.round(value);

        return (
            <>
                <meta itemProp="description" content={ description } />
                <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
                    <meta itemProp="priceCurrency" content={ currency } />
                    <meta itemProp="price" content={ roundedPrice } />
                    <meta itemProp="availability" content={ stockMeta } />
                    <meta itemProp="url" content={ metaLink } />
                </div>
            </>
        );
    }
}

export default Product;
