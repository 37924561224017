/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, react/prop-types */
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { NOTIFICATION_LIFETIME } from 'Component/Notification/Notification.config';
import SmsVerificationQuery from 'Query/SmsVerification.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { getGuestQuoteId } from 'Util/Cart';
import { noopFn } from 'Util/Common';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import SmsCodePopup from './SmsCodePopup.component';
import { CODE_LENGTH, SMS_CODE_POPUP } from './SmsCodePopup.config';

/** @namespace Scandipwa/Component/SmsCodePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[SMS_CODE_POPUP] || {},
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/SmsCodePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace Scandipwa/Component/SmsCodePopup/Container */
export class SmsCodePopupContainer extends PureComponent {
    state = {
        isLoading: false,
        value: '',
        touchStartCoordinatesY: '',
        isSuccess: false
    };

    otpSignInVerifiedTimeOutRef = createRef();

    // eslint-disable-next-line react/sort-comp
    resendCode = async () => {
        const { payload: { phone, onResend = () => {} }, showNotification } = this.props;

        const options = {
            quote_id: getGuestQuoteId(),
            phone: phone || '1111111'
        };

        await fetchMutation(SmsVerificationQuery.getCreationMutation(options))
            .then(
                /** @namespace Scandipwa/Component/SmsCodePopup/Container/SmsCodePopupContainer/fetchMutation/then */
                async () => {
                    await onResend();

                    showNotification('success', __('Code resent'));
                }
            );
    };

    onFieldChange = (e) => {
        const code = e?.currentTarget?.value;
        this.setState({ value: code });
    };

    closePopup(isVerified = false, isPdp = false) {
        const {
            payload: { onCancel = noopFn } = {},
            hideActivePopup
        } = this.props;

        if (!isPdp) {
            hideActivePopup();
        }

        if (!isVerified) {
            onCancel();
        }
    }

    onClose() {
        const {
            payload: { onCancel = noopFn } = {}
        } = this.props;
        const { isSuccess } = this.state;

        if (!isSuccess) {
            onCancel();
        }
    }

    validatePhoneNumber(form, fields) {
        const {
            payload: {
                onSuccess, onError, isPdp, isSignIn = false
            }, showNotification
        } = this.props;
        const { verification_code } = transformToNameValuePair(fields);

        if (verification_code?.length >= CODE_LENGTH) {
            this.setState({ isLoading: true });
            const options = {
                quote_id: getGuestQuoteId(),
                code: verification_code
            };

            if (isSignIn) {
                onSuccess(
                    verification_code,
                    this.otpSignInVerified.bind(this),
                    this.stopLoading.bind(this),
                    NOTIFICATION_LIFETIME
                );
            } else {
                fetchMutation(SmsVerificationQuery.getValidateMutation(options)).then(
                    /** @namespace Scandipwa/Component/SmsCodePopup/Container/SmsCodePopupContainer/validatePhoneNumber/then/finally/fetchMutation/then */
                    ({ validateSmsOTPCode = false } = {}) => {
                        if (validateSmsOTPCode) {
                            this.setState({ isSuccess: true });
                            setTimeout(() => {
                                this.closePopup(true, isPdp);
                            }, NOTIFICATION_LIFETIME);

                            onSuccess(verification_code);
                        }
                    },
                    /** @namespace Scandipwa/Component/SmsCodePopup/Container/SmsCodePopupContainer/validatePhoneNumber/then/finally/fetchMutation/then/catch */
                    (error) => {
                        onError();
                        showNotification('error', getErrorMessage(error));
                    }
                )
                    .finally(
                        /** @namespace Scandipwa/Component/SmsCodePopup/Container/SmsCodePopupContainer/validatePhoneNumber/then/finally */
                        () => this.setState({ isLoading: false, value: '' })
                    );
            }
        }
    }

    onTouchAndMoveDown(e) {
        const { touchStartCoordinatesY } = this.state;
        const currentMoveCoordinateY = e.touches[0].clientY;
        const coordinatesDifference = currentMoveCoordinateY - touchStartCoordinatesY;

        // eslint-disable-next-line no-magic-numbers
        if (coordinatesDifference > 10) {
            this.closePopup();
        }
    }

    onTouchAndMoveUp(e) {
        const { touchStartCoordinatesY } = this.state;
        const currentMoveCoordinateY = e.touches[0].clientY;
        const coordinatesDifference = currentMoveCoordinateY - touchStartCoordinatesY;

        // eslint-disable-next-line no-magic-numbers
        if (coordinatesDifference > -10) {
            this.closePopup();
        }
    }

    onTouchStart(e) {
        this.setState({ touchStartCoordinatesY: e.touches[0].clientY });
    }

    containerFunctions = {
        validatePhoneNumber: this.validatePhoneNumber.bind(this),
        resendCode: this.resendCode.bind(this),
        onTouchAndMoveDown: this.onTouchAndMoveDown.bind(this),
        onTouchAndMoveUp: this.onTouchAndMoveUp.bind(this),
        onTouchStart: this.onTouchStart.bind(this),
        onFieldChange: this.onFieldChange.bind(this),
        onClose: this.onClose.bind(this)
    };

    containerProps() {
        const {
            isMobile
        } = this.props;
        const { isSuccess } = this.state;

        return {
            isSuccess,
            isMobile
        };
    }

    resetSuccess() {
        this.setState({ isSuccess: false });
    }

    otpSignInVerified() {
        this.setState({ isSuccess: true });
        this.otpSignInVerifiedTimeOutRef.current = setTimeout(() => {
            this.closePopup(true);
        }, NOTIFICATION_LIFETIME);
        this.setState({ isLoading: false, value: '' });
    }

    stopLoading() {
        this.setState({ isLoading: false });
    }

    componentDidUpdate(prevProps) {
        const { payload } = this.props;
        if (prevProps?.payload?.phone !== payload?.phone) {
            this.resetSuccess();
        }
    }

    componentWillUnmount() {
        const { current = null } = this.otpSignInVerifiedTimeOutRef || {};

        clearTimeout(current);
    }

    render() {
        return (
            <SmsCodePopup
              { ...this.containerProps() }
              { ...this.containerFunctions }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsCodePopupContainer);
