export * from 'SourceStore/NoMatch/NoMatch.action';
export const UPDATE_NOMATCH_IS_PDP = 'UPDATE_NOMATCH_IS_PDP';

/**
 * Update router to show PDP specific NoMatch page
 * @param  {Boolean} isPdp New isPdp value
 * @return {void}
 * @namespace Scandipwa/Store/NoMatch/Action/updateIsPdp */
export const updateIsPdp = (isPdp) => ({
    type: UPDATE_NOMATCH_IS_PDP,
    isPdp
});
