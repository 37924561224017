import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import MyAccountForgotPasswordPopup from 'Component/MyAccountForgotPasswordPopup';
import MyAccountLoginPopup from 'Component/MyAccountLoginPopup';
import {
    BEFORE_ITEMS_TYPE,
    MENU,
    MY_ACCOUNT_FORGOT_PW_POPUP,
    MY_ACCOUNT_LOGIN_POPUP,
    MY_VEHICLES,
    MY_VEHICLES_PATH,
    SELL,
    SELL_ORDER_PAGE,
    SELL_ORDER_PAGE_URL,
    SELL_PATH,
    SHOWROOM_BOOKING,
    SMS_SENDING_POPUP,
    SWITCH_ITEMS_TYPE,
    TEST_DRIVE_PATH,
    TESTDRIVE_BOOKING,
    UNSUBSCRIBE_PAGE,
    UNSUBSCRIBE_PATH
} from 'Component/Router/Router.config';
import SmsCodePopup from 'Component/SmsCodePopup';
import { ORDER_RESERVATION_URL } from 'Route/OrderReservation';
import { SHOWROOM_BOOKING_URL } from 'Route/ShowroomBooking/ShowroomBooking.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

export const SellOrderPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "sell-order-page" */ 'Route/SellOrderPage')
);
export const MyVehicles = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "my-vehicle-page" */ 'Route/MyVehicles')
);
export const SellComponent = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "sell-page" */ 'Route/Sell')
);
export const TestdriveBooking = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "test-drive-booking-page" */ 'Route/TestdriveBooking')
);
export const ShowroomBooking = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "showroom-booking-page" */ 'Route/ShowroomBooking')
);
export const OrderReservation = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "order-reservation-page" */ 'Route/OrderReservation')
);
export const UnsubscribePage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "unsubscribe-page" */ 'Route/UnsubscribePage')
);

export {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
};

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    removedRouters = [
        MENU
    ];

    /**
    * Overridden to remove wishlist, shared wishlist and search
    * Added showroom booking page
    */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE].filter(
            ({ name }) => !this.removedRouters.includes(name)
        ),
        {
            component: <Route
              path={ withStoreRegex(SHOWROOM_BOOKING_URL) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <ShowroomBooking { ...props } /> }
            />,
            position: 90,
            name: SHOWROOM_BOOKING
        },
        {
            component: <Route
              path={ withStoreRegex(`${TEST_DRIVE_PATH}/:carUrl`) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <TestdriveBooking { ...props } /> }
            />,
            position: 91,
            name: TESTDRIVE_BOOKING
        },
        {
            component: <Route
              path={ withStoreRegex(`${MY_VEHICLES_PATH}/:tabId?`) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <MyVehicles { ...props } /> }
            />,
            position: 92,
            name: MY_VEHICLES
        },
        {
            component: <Route
              path={ withStoreRegex(SELL_PATH) }
                // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <SellComponent { ...props } /> }
            />,
            position: 94,
            name: SELL
        },
        {
            component: <Route
              path={ withStoreRegex(`${ORDER_RESERVATION_URL}/:orderId/:offerStep?`) }
                // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <OrderReservation { ...props } /> }
            />,
            position: 100,
            name: MY_VEHICLES
        },
        {
            component: <Route
              path={ withStoreRegex(`${SELL_ORDER_PAGE_URL}/:orderId/:offerStep?`) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <SellOrderPage { ...props } /> }
            />,
            position: 97,
            name: SELL_ORDER_PAGE
        },
        {
            component: <Route
              path={ withStoreRegex(`${UNSUBSCRIBE_PATH}/:code?/:id?`) }
                // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <UnsubscribePage { ...props } /> }
            />,
            position: 105,
            name: UNSUBSCRIBE_PAGE
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <SmsCodePopup />,
            position: 40,
            name: SMS_SENDING_POPUP
        },
        {
            component: <MyAccountLoginPopup />,
            position: 41,
            name: MY_ACCOUNT_LOGIN_POPUP
        },
        {
            component: <MyAccountForgotPasswordPopup />,
            position: 42,
            name: MY_ACCOUNT_FORGOT_PW_POPUP
        }
    ];
}

export default RouterComponent;
