import { withRouter } from 'react-router-dom';

import {
    LoginAccountComponent as SourceLoginAccountComponent
} from 'SourceRoute/LoginAccount/LoginAccount.component';

import './LoginAccount.override.style';

/** @namespace Scandipwa/Route/LoginAccount/Component */
export class LoginAccountComponent extends SourceLoginAccountComponent {
    /**
     * Created to move rendering logic to separate function
     */
    renderWrapperHeader() {
        return (
            <div block="LoginAccount" elem="WrapperHeader">
                <h1>{ __('Log In') }</h1>
                <div block="RequiredMark">
                    <p>{ __('Required Fields') }</p>
                    <span block="Field" elem="Label" mods={ { isRequired: true } }>
                        { ' *' }
                    </span>
                </div>
            </div>
        );
    }

    /**
     * Overridden to change labels and placeholder texts
     */
    renderSignInWrapper() {
        return (
            <div block="LoginAccount" elem="SignInWrapper">
                { this.renderWrapperHeader() }
                { this.renderSignIn() }
            </div>
        );
    }

    /**
     * Overridden to change labels and placeholder texts
     */
    renderCreateAccountWrapper() {
        const { onCreateAccountClick } = this.props;

        return (
            <div block="LoginAccount" elem="CreateAccount">
                <h3>{ __('Not signed up yet?') }</h3>
                <p>
                    { __('Please signup for an account to receive more benefits.') }
                </p>
                <button
                  block="Button"
                  mix={ { block: 'LoginAccount', elem: 'CreateAccountButton' } }
                  onClick={ onCreateAccountClick }
                >
                    { __('Sign Up') }
                </button>
            </div>
        );
    }
}

export default withRouter(
    LoginAccountComponent
);
