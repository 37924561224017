/* eslint-disable max-len */
import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

import { DEFAULT_CLOSE_ICON, SEARCH_CLOSE_ICON } from './CloseIcon.config';

import './CloseIcon.style.scss';

/** @namespace Scandipwa/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIcon {
    renderMap = {
        [DEFAULT_CLOSE_ICON]: this.renderDefaultCloseIcon,
        [SEARCH_CLOSE_ICON]: this.renderSearchCloseIcon
    };

    render() {
        const { type = DEFAULT_CLOSE_ICON } = this.props;

        return this.renderMap[type]?.() || this.renderMap[DEFAULT_CLOSE_ICON];
    }

    renderDefaultCloseIcon() {
        return (
            <svg
              block="CloseIcon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17.4246 1.51485L1.51471 17.4247C1.22181 17.7176 1.22181 18.1925 1.51471 18.4854C1.8076 18.7783 2.28247 18.7783 2.57537 18.4854L18.4853 2.57551C18.7782 2.28261 18.7782 1.80774 18.4853 1.51485C18.1924 1.22195 17.7175 1.22195 17.4246 1.51485Z" />
                <path d="M18.4852 17.4246L2.57525 1.51471C2.28236 1.22181 1.80748 1.22181 1.51459 1.51471C1.2217 1.8076 1.2217 2.28247 1.51459 2.57537L17.4245 18.4853C17.7174 18.7782 18.1923 18.7782 18.4852 18.4853C18.778 18.1924 18.778 17.7175 18.4852 17.4246Z" />
            </svg>
        );
    }

    renderSearchCloseIcon() {
        return (
            <svg block="CloseIcon" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 1L6 5.4988L9.5 9" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M9.5 1L5.0012 5.4988L1.5 9" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            </svg>

        );
    }
}

export default CloseIconComponent;
