import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';

export const ProductSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "productSlider" */
    './ProductSlider.component'
));

/** @namespace Scandipwa/Component/ProductSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/ProductSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/ProductSlider/Container */
export class ProductSliderContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        products: PropTypes.arrayOf(ProductType),
        isForReviews: PropTypes.bool,
        reviews: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        isForReviews: false,
        products: [],
        reviews: []
    };

    containerProps = () => {
        const {
            device,
            products,
            isForReviews,
            reviews
        } = this.props;

        return {
            device,
            products,
            isForReviews,
            reviews
        };
    };

    renderSliderPlaceHolder() {
        return (
            <div
              block="ProductSlider"
              elem="Placeholder"
            />
        );
    }

    render() {
        return (
            <Suspense fallback={ this.renderSliderPlaceHolder() }>
                <ProductSlider
                  { ...this.containerProps() }
                />
            </Suspense>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderContainer);
