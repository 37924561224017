/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { ReactComponent as Tick } from 'Style/icons/tick-icon.svg';

import { SMS_CODE_POPUP } from './SmsCodePopup.config';

import './SmsCodePopup.style.scss';

/** @namespace Scandipwa/Component/SmsCodePopup/Component */
export class SmsCodePopupComponent extends PureComponent {
    renderForm() {
        const { value, onFieldChange, validatePhoneNumber } = this.props;

        return (
            <Form onSubmit={ validatePhoneNumber }>
            <div>
            <Field
              type={ FIELD_TYPE.text }
              attr={ {
                  id: 'verification_code',
                  name: 'verification_code',
                  placeholder: __('Enter confirmation code (4 digits)'),
                  value
              } }
              events={ {
                  onChange: onFieldChange
              } }
              validationRule={ {
                  isRequired: true,
                  range: {
                      min: 4
                  }
              } }
              mix={ { block: 'SmsCodePopup', elem: 'Field' } }
            />
            </div>
                <button type="submit" block="Button" mix={ { block: 'SmsCodePopup', elem: 'ValidateButton' } }>
                { __('Validate') }
                </button>
            </Form>
        );
    }

    renderTopCloseButton() {
        const {
            onTouchAndMoveDown, onTouchStart, isMobile
        } = this.props;

        if (!isMobile) {
            // Popup component renders close button anyway
            return null;
        }

        return (
            <button
              block="SmsCodePopup"
              elem="TopCloseBtn"
              onTouchStart={ onTouchStart }
              onTouchMove={ onTouchAndMoveDown }
              aria-label="Top close button"
            />
        );
    }

    renderBottomCloseButton() {
        const { onTouchAndMoveUp, onTouchStart } = this.props;
        return (
            <button
              block="SmsCodePopup"
              elem="BottomCloseBtn"
              onTouchStart={ onTouchStart }
              onTouchMove={ onTouchAndMoveUp }
              aria-label="Bottom close button"
            />
        );
    }

    renderNotification() {
        return (
            <div
              block="SmsCodePopup"
              elem="Notification"
            >
                <div block="SmsCodePopup" elem="NotificationContentWrapper">
                <div block="SmsCodePopup" elem="NotificationSymbol">
                    <Tick />
                </div>
                <p block="SmsCodePopup" elem="NotificationText">
                    { __('Mobile number verified!') }
                </p>
                </div>
            </div>
        );
    }

    renderContent() {
        const { resendCode, isSuccess } = this.props;

        if (isSuccess) {
            return this.renderNotification();
        }

        return (
            <div block="SmsCodePopup" elem="ContentWrapper">
              <div block="SmsCodePopup" elem="Title">
                  { __('Please enter the one time password to verify your account') }
              </div>
              { this.renderForm() }
                <p block="SmsCodePopup" elem="Text">{ __('Haven’t received the code?') }</p>
                  <div block="SmsCodePopup" elem="ButtonWrapper">
                  <button
                    type="button"
                    onClick={ resendCode }
                    block="SmsCodePopup"
                    elem="ResendButton"
                  >
                      { __('Resend the code') }
                  </button>
                  </div>
            </div>
        );
    }

    render() {
        const { isLoading, onClose } = this.props;

        return (
          <Popup
            id={ SMS_CODE_POPUP }
            onClose={ onClose }
            mix={ { block: 'SmsCodePopup', elem: 'Popup' } }
          >
              { this.renderContent() }
              <Loader isLoading={ isLoading } />
          </Popup>
        );
    }
}

export default SmsCodePopupComponent;
