export * from 'SourceStore/Cart/Cart.action';

export const UPDATE_IS_ADDING = 'UPDATE_IS_ADDING';
export const UPDATE_ORDER_ID = 'UPDATE_ORDER_ID';

/** @namespace Scandipwa/Store/Cart/Action/updateIsAdding */
export const updateIsAdding = (isAdding) => ({
    type: UPDATE_IS_ADDING,
    isAdding
});

/** @namespace Scandipwa/Store/Cart/Action/updateOrderId */
export const updateOrderId = (orderId) => ({
    type: UPDATE_ORDER_ID,
    orderId
});
