/* eslint-disable max-lines */
import {
    UC_COLOR,
    UC_FUEL,
    UC_MAKE,
    UC_YEAR
} from 'Component/ProductActions/ProductActions.config';
import { MILEAGE } from 'Route/ProductPage/ProductPage.config';
import { getCarFromOrder, getOptionsFromOrder } from 'Util/Orders/Orders';
import { roundPrice } from 'Util/Price';
import { getAttribute, getAttributeMatchToCodeAndValue, getAttributeValue } from 'Util/Product/Extract';
import { changeProductNameToParentName } from 'Util/Product/Product';

import {
    CATEOGRY_LIMIT, GROUPED, NOT_APPLICABLE
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { CONFIGURABLE, SIMPLE } from '../util/Product/Types';

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    const { name = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return name;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants[0].name;
    }

    return name;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return sku;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    const categoryString = limitedCategories.join('/');
    return categoryString.charAt(0) === '/' ? categoryString.substring(1) : categoryString;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => {
    if (qty) {
        return { quantity: qty };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants, configurableVariantIndex) => {
    if (type_id === SIMPLE) {
        return -1;
    } if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }
    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true, insurancePrice = 0) => {
    if (insurancePrice) {
        return insurancePrice;
    }

    if (price && notInGetProduct) {
        return price;
    }

    const { search } = variant;

    if (search) {
        const {
            price_range: {
                price: {
                    finalPrice: {
                        value: discountValue = null
                    } = {},
                    regularPrice: {
                        value = 0
                    } = {}
                } = {}
            } = {}
        } = variant;

        return type_id !== GROUPED
            ? +roundPrice(discountValue || value) || 0
            : 0;
    }

    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: discountValue = null
                } = {},
                regular_price: {
                    value = 0
                } = {}
            } = {}
        } = {}
    } = variant;
    const oneOfPrices = discountValue || value;

    if (!oneOfPrices) {
        const priceFromAttribute = getAttributeValue(variant?.attributes, 'price');

        if (priceFromAttribute) {
            return +roundPrice(priceFromAttribute?.replace(/,/g, ''));
        }
    }

    return type_id !== GROUPED
        ? +roundPrice(discountValue || value) || 0
        : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product) => {
    const {
        attributes: {
            brand
        } = {}
    } = product;

    if (brand === undefined) {
        return NOT_APPLICABLE;
    }

    const {
        attribute_value
    } = brand;

    return attribute_value;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const {
        variants,
        attributes: {
            size
        }
    } = product;

    if (size === undefined || type_id === SIMPLE || variants[0] === undefined) {
        return NOT_APPLICABLE;
    }

    const configIndex = !configurableVariantIndex ? 0 : configurableVariantIndex;

    const {
        attribute_options
    } = size;

    const {
        attributes: {
            size: {
                attribute_value
            }
        }
    } = variants[configIndex];

    const { swatch_data: { value } } = attribute_options[attribute_value];
    return value;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPosition */
export const getPosition = (position, click) => {
    if (click && position) {
        return { position };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBaseCustomDimensions */
export const getBaseCustomDimensions = (item, click) => {
    const { sku: actionSku, product: { variants = [], dimensions, type_id } } = item;

    if (type_id === SIMPLE || click) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint ? correctVarint.dimensions : false;

        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getDataAttribute */
export const getDataAttribute = (product, attributeName) => {
    const { attributes } = product;
    const result = getAttribute(product, attributes, attributeName)
        || getAttributeMatchToCodeAndValue(product, attributes, attributeName)
     || getAttributeValue(attributes, attributeName);

    if (result === undefined) {
        return NOT_APPLICABLE;
    }

    return result;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (item, click = false, notInGetProduct = true) => {
    const {
        qty = false, price, product: {
            type_id = SIMPLE, sku: parentSku, position = 0, name: parentName, variants, insurancePrice = 0
        }, product
    } = item;

    const data = {
        id: parentSku,
        name: parentName,
        price: (type_id === CONFIGURABLE && notInGetProduct === false)
            ? getPrice(variants[0], type_id, price, notInGetProduct, insurancePrice)
            : getPrice(product, type_id, price, notInGetProduct, insurancePrice),
        brand: getDataAttribute(product, UC_MAKE),
        variant: getDataAttribute(product, UC_COLOR),
        ...getQty(qty),
        ...getPosition(position, click),
        dimension1: getDataAttribute(product, UC_YEAR),
        dimension2: getDataAttribute(product, MILEAGE),
        dimension3: getDataAttribute(product, UC_FUEL),
        dimension4: parentSku
    };

    return data;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/prepareCarAndOptionsFromOrder */
export const prepareCarAndOptionsFromOrder = (order) => {
    const car = getCarFromOrder(order);
    const product = changeProductNameToParentName(car);
    const { items } = getOptionsFromOrder(order);
    const options = Object.values(items);
    return { product, options };
};
