import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace Scandipwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
}

export default AddToCartComponent;
