import { EVENT_GTM_FORM_SUBMIT_SERVICE } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const successSubmitForm = async (args, callback, instance) => {
    const { event } = instance.props;

    event(EVENT_GTM_FORM_SUBMIT_SERVICE);

    await callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Scandipwa/Route/ServiceBooking/Container': {
        'member-function': {
            successSubmitForm
        }
    },
    'Scandipwa/Route/ServiceBooking/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
