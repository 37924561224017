import { connect } from 'react-redux';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator';

import Field from './Field.component';
import { FIELD_TYPE } from './Field.config';

/** @namespace Scandipwa/Component/Field/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/Field/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    state = {
        ...this.state,
        characterCount: 0
    };

    /*
     * Created to check is CapsLock button is pressed
     */
    handleKeyPressDown(keyEvent) {
        if (keyEvent.getModifierState('CapsLock')) {
            this.setState({ warning: true });
        } else {
            this.setState({ warning: false });
        }
    }

    /**
     * Created to make warning off when focus lost
     */
    handleBlur() {
        this.setState({ warning: false });
    }

    /*
     * Overridden to add handle functions to container functions
     */
    containerFunctions = {
        ...this.containerFunctions,
        handleKeyPressDown: this.handleKeyPressDown.bind(this),
        handleBlur: this.handleBlur.bind(this),
        onShowPassword: this.togglePasswordVisibility.bind(this),
        countCharacters: this.countCharacters.bind(this)
    };

    /*
     * Overridden to pass handle methods to component via props
     */
    containerProps() {
        const { warning, characterCount, isHidePassword } = this.state;
        const {
            isPasswordToggle,
            labelLink,
            maxCharacterCount = 0,
            hasTooltip,
            tooltipContent,
            subLabel,
            hasCheckmark,
            isMobile,
            tooltipDirection,
            isTooltipHoverable
        } = this.props;

        return {
            ...super.containerProps(),
            warning,
            isPasswordToggle,
            labelLink,
            characterCount,
            maxCharacterCount,
            isHidePassword,
            hasTooltip,
            tooltipContent,
            subLabel,
            hasCheckmark,
            tooltipDirection,
            isMobile,
            isTooltipHoverable
        };
    }

    componentDidMount() {
        const { attr: { defaultValue = '' } } = this.props;

        if (defaultValue) {
            this.setState({ characterCount: defaultValue.length });
        }
    }

    countCharacters(e) {
        const count = e.target.value.length;
        this.setState({ characterCount: count });
    }

    togglePasswordVisibility() {
        const { isPasswordToggle } = this.props;

        if (!isPasswordToggle) {
            return;
        }

        const { type } = this.fieldRef;

        switch (type) {
        case FIELD_TYPE.text:
            this.fieldRef.type = FIELD_TYPE.password;
            this.setState({ isHidePassword: false });

            break;
        case FIELD_TYPE.password:
            this.fieldRef.type = FIELD_TYPE.text;
            this.setState({ isHidePassword: true });

            break;
        default:
        }
    }

    /**
     * Overridden to validate value from data if passed
     */
    validate(data) {
        const { validationRule, type, attr: { name } = {} } = this.props;
        const refValue = type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio
            ? !!this.fieldRef.checked
            : this.fieldRef.value;

        const value = data && data.value ? data.value : refValue;

        const [dataFile] = data?.files || [];
        const [refFile] = this.fieldRef?.files || [];

        const file = dataFile || refFile;

        const response = validate(
            file && (type === FIELD_TYPE.file || type === FIELD_TYPE.image) ? file : value,
            validationRule
        );

        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }
            data.detail.errors.push(output);
        }
        this.setState({ validationResponse: output });

        return output;
    }

    /**
     * Overridden to pass data for validation
     */

    validateOnEvent(hook, ...args) {
        if (hook) {
            const { attr, type } = this.props;
            const { value } = this.fieldRef;
            hook(...[...args, {
                ...attr, fieldRef: this.fieldRef, value, type
            }]);
        }
        const [data] = args;
        this.validate(data);
    }

    render() {
        return (
            <Field
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldContainer);
