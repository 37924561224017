/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ChevronIcon from 'Component/ChevronIcon';
import { RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import { SearchOverlay as SourceSearchOverlay } from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import { SEARCH_URL } from './SearchOverlay.config';

import './SearchOverlay.override.style.scss';
/** @namespace Scandipwa/Component/SearchOverlay/Component */
export class SearchOverlay extends SourceSearchOverlay {
    renderShowSearchResults() {
        const { totalCount, searchCriteria } = this.props;

        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        const searchUrl = trimmedSearch !== '' ? `${SEARCH_URL}/${search}` : SEARCH_URL;

        return (
            <div
              block="SearchOverlay"
              elem="ShowResultsWrapper"
            >
                <Link
                  to={ searchUrl }
                  block="SearchOverlay"
                  elem="ShowResultsLink"
                >
                    <ChevronIcon direction={ RIGHT } />
                    { __('Show all search results (%s)', totalCount) }
                </Link>
            </div>
        );
    }

    render() {
        const { isHideOverlay, searchCriteria } = this.props;

        if (!searchCriteria.trim()) {
            return null;
        }

        if (isHideOverlay) {
            return (
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                    { this.renderShowSearchResults() }
                </article>
            );
        }

        return (
            <Overlay
              id="search"
              mix={ { block: 'SearchOverlay' } }
            >
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                    { this.renderShowSearchResults() }
                </article>
            </Overlay>
        );
    }
}

export default SearchOverlay;
