import { connect } from 'react-redux';

import {
    ATTRIBUTE_CODES_ARRAY
} from 'Component/ProductActions/ProductActions.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import fromCache from 'Util/Cache/Cache';
import { history } from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import {
    convertQueryStringToKeyValuePairs,
    objectToUri,
    setQueryParamsWithoutHistory
} from 'Util/Url';

/** @namespace Scandipwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    description: state.MetaReducer.description
});

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    /*
    * JAID-136 - Overridden to add new default state
    */
    state = {
        ...this.state,
        firstLoad: true,
        configurableAttributePrices: {}
    };

    /**
     * Overridden to add more props
     */
    containerProps() {
        const { description } = this.props;

        return {
            ...super.containerProps(),
            description,
            stockMeta: this.getStockMeta(),
            metaLink: this.getMetaLink()
        };
    }

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        /**
         * Used determine which attributes should be changed when variant is not found based on key value pair
         * Ex: one engine attribute is selected in the left menu, but the variant that contains that specific engine was deleted.
         * In order to get an existing variant, we need to change attributes that come after engine, i.e. color_exterior or color_interior values
         * so that they match an existing variant
         */
        const { parameters: prevParameters, selectedProduct } = this.state;
        const { product: { variants, configurable_options } } = this.props;

        const { location } = history;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const configurableOptionsKeys = Object.keys(configurable_options);
        const newIndex = Object.keys(parameters).length === configurableOptionsKeys.length
            ? getVariantIndex(variants, parameters, true)
            // Not all parameters are selected yet or there are no product in stock, therefore variantIndex must be invalid
            : -1;

        /**
         * Overridden to get variant if no product was found after searching based on selected parameter
         */
        if (newIndex !== -1) {
            // If variant was found, set it as the selected product
            const newProduct = variants[newIndex];

            if (newProduct && newProduct !== selectedProduct) {
                this.setState({
                    selectedProduct: newProduct,
                    parameters
                });
                setQueryParamsWithoutHistory(parameters, location, history);
            }

            return;
        }

        // Otherwise, get the variant that has the selected key=>value pair and all the already selected attributes
        const newProduct = variants.find(
            (variant) => {
                // First, check whether a product is in stock or not
                if (!fromCache(getProductInStock, [variant])) {
                    return false;
                }

                // Get the ranks that are before the attribute
                const indexOfKey = ATTRIBUTE_CODES_ARRAY.indexOf(key);
                const ranksBeforeKey = indexOfKey !== -1
                    ? ATTRIBUTE_CODES_ARRAY.slice(0, indexOfKey)
                    : [];

                // If it is the first attribute, get the first variant that has that specific value
                if (ranksBeforeKey.length === 0) {
                    return variant.attributes?.[key].attribute_value === value;
                }

                // Filter based on lower attribute values
                const checkLowerRankedAttributes = ranksBeforeKey.every(
                    (element) => variant.attributes?.[element].attribute_value === parameters[element]
                );

                return variant.attributes?.[key].attribute_value === value && checkLowerRankedAttributes;
            }
        );

        // Get new params from the selected variant
        const paramsAfterNewIndex = configurableOptionsKeys
            .reduce((params, option) => {
                // eslint-disable-next-line no-param-reassign
                params[option] = newProduct.attributes?.[option].attribute_value;

                return params;
            }, {});

        // Set the new parameters and the new product
        if (newProduct && newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters: paramsAfterNewIndex
            });
            setQueryParamsWithoutHistory(paramsAfterNewIndex, location, history);
        }
    }

    /**
     * Method that returns stock status
     */
    getStockMeta() {
        const activeProduct = this.getActiveProduct();

        const inStock = getProductInStock(activeProduct);

        if (!inStock) {
            return 'https://schema.org/OutOfStock';
        }

        return 'https://schema.org/InStock';
    }

    /**
     * Method that returns pathname and query
     */
    getLink(key, value) {
        const { location: { search, pathname } } = history;
        const obj = {
            ...convertQueryStringToKeyValuePairs(search)
        };

        if (key) {
            obj[key] = value;
        }

        const query = objectToUri(obj);

        return `${pathname}${query}`;
    }

    /**
     * Method that returns meta link
     */
    getMetaLink() {
        return window.location.origin + this.getLink().replace(/\?.*/, '');
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
