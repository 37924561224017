import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductMonthlyPrice from './ProductMonthlyPrice.component';

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEnabled: state.ConfigReducer.monthly_payment_display_enable,
    downpayment: state.ConfigReducer.monthly_payment_display_downpayment,
    interest: state.ConfigReducer.monthly_payment_display_interest,
    installments: state.ConfigReducer.monthly_payment_display_instalments,
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container */
export class ProductMonthlyPriceContainer extends PureComponent {
    static propTypes = {
        isEnabled: PropTypes.bool.isRequired,
        downpayment: PropTypes.string.isRequired,
        interest: PropTypes.string.isRequired,
        installments: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        block: PropTypes.string,
        elem: PropTypes.string,
        isClickable: PropTypes.bool,
        id: PropTypes.string.isRequired,
        isInfoButton: PropTypes.bool
    };

    static defaultProps = {
        block: 'ProductMonthlyPrice',
        elem: 'ProductMonthlyPriceContainer',
        isClickable: false,
        isInfoButton: true
    };

    containerProps() {
        const {
            isEnabled,
            downpayment,
            interest,
            installments,
            price,
            currency,
            block,
            elem,
            isClickable,
            id,
            isInfoButton,
            isMobile
        } = this.props;

        return {
            isEnabled,
            downpayment,
            interest,
            installments,
            price,
            currency,
            block,
            elem,
            isClickable,
            id,
            isInfoButton,
            isMobile
        };
    }

    render() {
        return (
            <ProductMonthlyPrice
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMonthlyPriceContainer);
