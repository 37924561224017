/* eslint-disable consistent-return */
import { connect } from 'react-redux';

import {
    MY_ACCOUNT_LOGIN_POPUP_ID,
    MY_ACCOUNT_TEXT_CONSTANTS
} from 'Component/MyAccountLoginPopup/MyAccountLoginPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchProps, mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
}
from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

export {
    mapStateToProps
};

/** @namespace Scandipwa/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchProps(dispatch),
    showLoginPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_LOGIN_POPUP_ID, payload))
});

/** @namespace Scandipwa/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    /**
     * Overridden to disable loader only after the product was added to the wishlist
     */
    componentDidUpdate(prevProps) {
        const { productsInWishlist: prevProductsInWishlist } = prevProps;
        const {
            isAddingWishlistItem, productsInWishlist,
            magentoProduct: [{ sku }] = []
        } = this.props;
        const {
            location: {
                state: {
                    toggleWishlistFromAccountCreation = {},
                    toggleWishlistFromAccountCreation: {
                        sku: skuToToggle,
                        add
                    } = {}
                } = {}
            }
        } = history;

        if (toggleWishlistFromAccountCreation && sku && sku === skuToToggle) {
            this.toggleProductInWishlist(add);
            history.replace({}, {});
        }

        if (JSON.stringify(productsInWishlist) !== JSON.stringify(prevProductsInWishlist) && !isAddingWishlistItem) {
            this.setWishlistButtonLoading(false);
        }
    }

    async toggleProductInWishlist(add = true) {
        const {
            showLoginPopup,
            magentoProduct: [{ sku }] = [],
            wishlistId
        } = this.props;

        const { location: { pathname, search } } = history;

        if (!isSignedIn()) {
            return showLoginPopup({
                title: typeof MY_ACCOUNT_TEXT_CONSTANTS.wishlistHeaderText === 'function'
                    ? MY_ACCOUNT_TEXT_CONSTANTS.wishlistHeaderText()
                    : MY_ACCOUNT_TEXT_CONSTANTS.wishlistHeaderText,
                stateToPush: {
                    prevLocation: pathname,
                    prevSearch: search,
                    toggleWishlistFromAccountCreation: {
                        wishlistId,
                        sku,
                        add
                    }
                },
                onSignIn: this.toggleWishlist.bind(this, add)
            });
        }

        return this.toggleWishlist(add);
    }

    async toggleWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            isAddingWishlistItem,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId
        } = this.props;

        if (isAddingWishlistItem) {
            return null;
        }

        this.setWishlistButtonLoading(true);

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);
        if (!wishlistItem) {
            return;
        }

        const {
            wishlist: {
                id: itemId
            }
        } = wishlistItem;

        return removeProductFromWishlist({ item_id: itemId });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
