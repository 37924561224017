/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ClickOutside from 'Component/ClickOutside';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    /**
     * Adds close button on the bottom of the page
     */
    renderBottomCloseBtn() {
        return (
            <div block="Popup" elem="BottomCloseBtn">
                <button
                  block="Popup"
                  elem="Button"
                  onClick={ this.hidePopupAndGoBack }
                >
                    <span>{ __('Close') }</span>
                </button>
            </div>
        );
    }

    renderCloseButton() {
        const { isNonClosable } = this.props;

        if (isNonClosable) {
            return null;
        }

        return super.renderCloseButton();
    }

    hidePopupAndGoBack() {
        const { isNonClosable } = this.props;

        if (isNonClosable) {
            return;
        }

        super.hidePopupAndGoBack();
    }

    renderHeader() {
        const {
            title,
            hasCloseBtn,
            isTooltipMobile
        } = this.props;

        if (isTooltipMobile) {
            return null;
        }

        return (
            <header block="Popup" elem="Header" mods={ { hasTitle: !!title } }>
                <div block="Popup" elem="HeaderWrapper">
                    { this.renderTitle() }
                    { !hasCloseBtn && this.renderCloseButton() }
                </div>
            </header>
        );
    }

    /**
     * Overridden to update popup content structure
     * JAID-112 - Added conditional render for bottom close button
     */
    renderContent() {
        const {
            children, contentMix, hasCloseBtn
        } = this.props;

        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    { !hasCloseBtn
                        && this.renderHeader() }
                    <div block="Popup" elem="ChildrenWrapper">
                        { this.renderNotifications() }
                        { children }
                    </div>
                    { hasCloseBtn && this.renderBottomCloseBtn() }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
