import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    hideActiveOverlay,
    hideActivePopup
} from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

import ToolTipButton from './ToolTipButton.component';
import { TOOLTIP_POPUP_PREFIX } from './ToolTipButton.config';

/** @namespace Scandipwa/Component/ToolTipButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandipwa/Component/ToolTipButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showToolTipPopup: (id, payload = {}) => dispatch(showPopup(id, payload)),
    hideActivePopup: () => dispatch(hideActivePopup()),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/ToolTipButton/Container */
export class ToolTipButtonContainer extends PureComponent {
    static propTypes = {
        tooltipContent: PropTypes.node.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        showToolTipPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        hideActivePopup: PropTypes.func.isRequired,
        isHoverable: PropTypes.bool,
        tooltipDirection: PropTypes.string
    };

    static defaultProps = {
        isHoverable: false,
        tooltipDirection: 'top'
    };

    state = {
        isActive: false
    };

    containerFunctions = {
        clickHandler: this.clickHandler.bind(this),
        showTooltip: this.showTooltip.bind(this),
        hideTooltip: this.hideTooltip.bind(this),
        setActive: this.setActive.bind(this, false)
    };

    toggleTooltip(e) {
        const { isActive } = this.state;

        if (!isActive) {
            this.showTooltip(e);
        } else {
            this.hideTooltip(e);
        }
    }

    showTooltip(e) {
        e.preventDefault();
        const { id, showToolTipPopup } = this.props;
        showToolTipPopup(`${TOOLTIP_POPUP_PREFIX}-${id}`);
        this.setActive(true);
    }

    hideTooltip(e) {
        e.preventDefault();
        const { hideActiveOverlay, hideActivePopup } = this.props;
        hideActiveOverlay();
        hideActivePopup();
        this.setActive(false);
    }

    setActive(active) {
        this.setState({ isActive: active });
    }

    clickHandler(e) {
        const { isHoverable } = this.props;
        if (!isHoverable) {
            this.toggleTooltip(e);
        }

        e.preventDefault();
    }

    containerProps() {
        const { isActive } = this.state;
        const {
            tooltipContent, id, isHoverable, tooltipDirection
        } = this.props;

        return {
            isActive,
            tooltipContent,
            id,
            isHoverable,
            tooltipDirection
        };
    }

    render() {
        return (
            <ToolTipButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolTipButtonContainer);
