import {
    ConfigReducer as sourceConfigReducer,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Config/Config.reducer';

import { UPDATE_CONFIG_LOADED } from './Config.action';

export * from 'SourceStore/Config/Config.reducer';

/** @namespace Scandipwa/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isConfigLoaded: false
});

/** @namespace Scandipwa/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        isConfigLoaded
    } = action;

    switch (type) {
    case UPDATE_CONFIG_LOADED:
        return {
            ...state,
            isConfigLoaded
        };

    default:
        return sourceConfigReducer(state, action);
    }
};

export default ConfigReducer;
