import {
    BODYSTYLE,
    COLOR,
    FUEL,
    GEARBOX,
    MILEAGE,
    POWER,
    YEAR
} from 'Route/ProductPage/ProductPage.config';

export const SUMMARY_ATTRIBUTES_ARRAY = [
    YEAR,
    MILEAGE,
    FUEL,
    POWER,
    COLOR,
    BODYSTYLE,
    GEARBOX
];
