/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { SearchItem as SourceSearchItem } from 'SourceComponent/SearchItem/SearchItem.component';
import {
    getPrice
} from 'Util/Product/Extract';

import './SearchItem.override.style.scss';
/** @namespace Scandipwa/Component/SearchItem/Component */
export class SearchItem extends SourceSearchItem {
    renderContent() {
        const {
            product: {
                name,
                type_id: type,
                price_tiers: priceTiers,
                price_range: priceRange,
                dynamic_price: dynamicPrice
            }
        } = this.props;

        return (
            <div block="SearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <h4 block="SearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </h4>
                <ProductPrice
                  meta
                  price={ getPrice(priceRange, dynamicPrice, {}, type) }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  mix={ { block: 'SearchItem', elem: 'Price' } }
                />
            </div>
        );
    }

    renderImage() {
        const {
            product: { name },
            imgSrc
        } = this.props;

        if (name && !imgSrc) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchItem"
              elem="Image"
              src={ imgSrc }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
              ratio="custom"
            />
        );
    }
}

export default SearchItem;
