/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

import ProductReviewRating from 'Component/ProductReviewRating';
import ProductSlider from 'Component/ProductSlider';

import { MAX_STAR_SCORE } from './WebsiteReviews.config';

import './WebsiteReviews.style';

/** @namespace Scandipwa/Component/WebsiteReviews/Component */
export class WebsiteReviewsComponent extends PureComponent {
    renderHeader() {
        const { blockHeading } = this.props;
        return <h2 block="WebsiteReviews" elem="Heading">{ blockHeading }</h2>;
    }

    renderReviewSummary() {
        const { blockSubHeading, overallStars } = this.props;
        const hundredPercents = 100;
        const summary = (overallStars / MAX_STAR_SCORE) * hundredPercents;

        return (
            <div block="WebsiteReviews" elem="Summary">
                <ProductReviewRating summary={ summary } count={ overallStars } />
                <h3 block="WebsiteReviews" elem="SummaryHeading">{ blockSubHeading }</h3>
            </div>
        );
    }

    renderReviews() {
        const { reviewsData } = this.props;
        return <ProductSlider isForReviews reviews={ reviewsData } />;
    }

    render() {
        return (
            <div block="WebsiteReviews">
                <div block="WebsiteReviews" elem="Wrapper">
                    { this.renderHeader() }
                    { this.renderReviewSummary() }
                    { this.renderReviews() }
                </div>
            </div>
        );
    }
}

export default WebsiteReviewsComponent;
