import {
    EVENT_GTM_PRODUCT_CLICK
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

/** ProductCard */
const registerSharedElement = (args, callback, instance) => {
    const { event, position: positionProp } = instance.props;
    const { pathname } = window.location;
    const { linkTo: { state: { prevCategoryId } } } = instance.props;

    const {
        currentVariantIndex: configurableVariantIndex,
        selectedFilters,
        product
    } = instance.props;

    const { position: positionProduct } = product;

    const position = positionProp || positionProduct;

    const productToPass = Object.keys(selectedFilters).length
        ? {
            ...product, configurableVariantIndex, prevCategoryId, position, pathname
        }
        : {
            ...product, prevCategoryId, position, pathname
        };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);

    callback(...args);
};

const toggleInsuranceAdded = (args, callback, instance) => {
    callback(...args);

    const { event } = instance.props;
    const product = instance.getFirstInsurance();
    const {
            price: {
                finalPrice: {
                    value = 0,
                } = {}
            } = {}
    } = instance.getInsurancePrice();

    event(EVENT_GTM_PRODUCT_CLICK, { ...product, insurancePrice: value });
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        event,
        selectedFilters,
        position = 0
    } = instance.props;

    return {
        ...callback(...args),
        selectedFilters,
        position,
        event
    };
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement
        }
    },
    'Component/ProductCard/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    },
    'Scandipwa/Component/ProductMainViewInsurance/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/ProductMainViewInsurance/Container': {
        'member-function': {
            toggleInsuranceAdded
        }
    },
};
