import {
    getInitialState as sourceGetInitialState,
    MyAccountReducer as sourceMyAccountReducer
} from 'SourceStore/MyAccount/MyAccount.reducer';

import { IS_LOADING_NEWSLETTER_UNSUBSCRIBE, UPDATE_CUSTOMER_EXISTS } from './MyAccount.action';

/** @namespace Scandipwa/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isExistingCustomer: false,
    isLoadingNewsletterSubscribe: false
});

/** @namespace Scandipwa/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const { isExistingCustomer, isLoadingNewsletterSubscribe = false } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_EXISTS:
        return {
            ...state,
            isExistingCustomer
        };

    case IS_LOADING_NEWSLETTER_UNSUBSCRIBE:
        return {
            ...state,
            isLoadingNewsletterSubscribe
        };

    default:
        return sourceMyAccountReducer(state, action);
    }
};

export default MyAccountReducer;
