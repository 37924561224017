/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import CalendarIcon from 'Component/CalendarIcon';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    DatePickerComponent as SourceDatePickerComponent
} from 'SourceComponent/DatePicker/DatePicker.component';
import { noopFn } from 'Util/Common';

import './DatePicker.override.style';

/** @namespace Scandipwa/Component/DatePicker/Component */
export class DatePickerComponent extends SourceDatePickerComponent {
    static propTypes = {
        ...this.propTypes,
        selectedDate: PropTypes.instanceOf(Date),
        fieldType: PropTypes.oneOf([
            FIELD_TYPE.date,
            FIELD_TYPE.dateWithMonthYearSelector
        ]),
        setRef: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        // To have empty placeholder
        selectedDate: null,
        fieldType: FIELD_TYPE.date,
        setRef: noopFn
    };

    /**
     * Overridden to add CalendarIcon to input
     */
    renderCustomInput({ value, onClick }, ref) {
        const {
            selectedDate,
            uid,
            setRef,
            placeholder,
            isDisabled
        } = this.props;

        return (
            <label htmlFor={ uid }>
                <input
                  id={ uid }
                  name={ uid }
                  value={ value }
                  type={ FIELD_TYPE.text }
                  onClick={ onClick }
                  ref={ (elem) => {
                      setRef(elem);
                      ref(elem);
                  } }
                  data-date={ selectedDate }
                  placeholder={ placeholder }
                  inputMode="none"
                  readOnly
                  disabled={ isDisabled }
                />
                <CalendarIcon isDisabled={ isDisabled } />
            </label>
        );
    }

    /**
     * Overridden to pass includeDates
     */
    render() {
        const {
            selectedDate,
            handleChange,
            showTimeSelect,
            showTimeSelectOnly,
            maxDate,
            minDate,
            dateFormat,
            timeFormat,
            isClearable,
            availableDates,
            fieldType,
            value,
            filteredDays,
            useValue
        } = this.props;

        const isHeaderWithMonthAndYearSelector = fieldType === FIELD_TYPE.dateWithMonthYearSelector;
        const className = `FieldDate-Calendar${
            isHeaderWithMonthAndYearSelector ? ' FieldDate-Calendar_withMonthYearSelector' : ''}`;

        const DateInput = forwardRef(this.renderCustomInput.bind(this));

        const filterWorkdays = (date) => {
            if (filteredDays.length) {
                const day = date.getDay();
                return filteredDays.some((arrayDay) => arrayDay === day);
            }

            return true;
        };

        return (
            <DatePicker
              selected={ useValue ? value : value || selectedDate }
              onChange={ handleChange }
              showTimeSelect={ showTimeSelect }
              showTimeSelectOnly={ showTimeSelectOnly }
              timeFormat={ timeFormat }
              timeIntervals={ 15 }
              dateFormat={ dateFormat }
              maxDate={ maxDate }
              includeDates={ availableDates }
              isClearable={ isClearable }
              customInput={ <DateInput /> }
              calendarClassName={ className }
              showPopperArrow={ false }
              // eslint-disable-next-line react/jsx-no-bind
              filterDate={ filterWorkdays }
              minDate={ minDate }
              // eslint-disable-next-line react/jsx-no-bind
              formatWeekDay={ (weekdayName) => weekdayName.substring(0, 1) }
              dateFormatCalendar="LLL yyyy"
              disabledKeyboardNavigation
              showMonthDropdown={ isHeaderWithMonthAndYearSelector }
              showYearDropdown={ isHeaderWithMonthAndYearSelector }
              scrollableYearDropdown={ isHeaderWithMonthAndYearSelector }
              scrollableMonthDropdown={ isHeaderWithMonthAndYearSelector }
              yearDropdownItemNumber={ 80 }
            />
        );
    }
}

export default DatePickerComponent;
