import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import { ProductType } from 'Type/ProductList.type';

import './ProductFeatures.style';

/** @namespace Scandipwa/Component/ProductFeatures/Component */
export class ProductFeaturesComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        attributeColumns: PropTypes.arrayOf(PropTypes.array).isRequired,
        getAttributeValueArray: PropTypes.func.isRequired
    };

    renderAttributeValue(attributeValue) {
        const trimmedValue = attributeValue?.trim();

        return (
            <li
              block="ProductFeatures"
              elem="ListItem"
            >
                { trimmedValue }
            </li>
        );
    }

    renderAttribute(attributeCode) {
        const {
            product: {
                attributes = {}
            } = {},
            getAttributeValueArray
        } = this.props;

        const attribute = attributes[attributeCode] || {};

        const {
            attribute_label,
            attribute_code
        } = attribute;

        const attributeValuesArray = getAttributeValueArray(attribute);

        return (
                <ExpandableContent
                  key={ attribute_code }
                  heading={ attribute_label }
                  isArrow
                  renderIcon
                  mix={ {
                      block: 'ProductFeatures',
                      elem: 'Expandable'
                  } }
                >
                    <ul
                      block="ProductFeatures"
                      elem="List"
                    >
                        { attributeValuesArray.map(this.renderAttributeValue.bind(this)) }
                    </ul>
                </ExpandableContent>
        );
    }

    renderColumn(columnFeatures) {
        return (
            <div
              block="ProductFeatures"
              elem="Column"
            >
                { columnFeatures.map(this.renderAttribute.bind(this)) }
            </div>
        );
    }

    renderContent() {
        const { isMobile, attributeColumns } = this.props;

        if (isMobile) {
            const attributeCodesInOneArray = [...attributeColumns[0], ...attributeColumns[1]];

            return (
                attributeCodesInOneArray.map(this.renderAttribute.bind(this))
            );
        }

        return attributeColumns.map(this.renderColumn.bind(this));
    }

    render() {
        const { attributeColumns } = this.props;

        if (!attributeColumns[0].length && !attributeColumns[1].length) {
            return null;
        }

        return (
            <div block="ProductFeatures">
                <h2
                  block="ProductFeatures"
                  elem="Heading"
                >
                    { __('Features') }
                </h2>
                <div
                  block="ProductFeatures"
                  elem="Content"
                >
                    { this.renderContent() }
                </div>
            </div>
        );
    }
}

export default ProductFeaturesComponent;
