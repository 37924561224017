import { EVENT_GTM_FORM_SUBMIT_SELL_CAR_STAGE2 } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const postFormData = (args, callback, instance) => {
    const { event } = instance.props;
    const original = callback(...args);

    original.then(() => {
        event(EVENT_GTM_FORM_SUBMIT_SELL_CAR_STAGE2);
    });
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

export default {
    'Scandipwa/Component/UploadImagesSellStep/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/UploadImagesSellStep/Container': {
        'member-function': {
            containerProps,
            postFormData
        }
    }
};
