export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_BASE_ORDER_INFO = 'UPDATE_BASE_ORDER_INFO';
export const UPDATE_BASE_ORDER_ITEMS = 'UPDATE_BASE_ORDER_ITEMS';
export const UPDATE_RESERVATION_PAYMENT_DATA = 'UPDATE_RESERVATION_PAYMENT_DATA';
export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
export const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO';
export const UPDATE_IS_TRADE_IN_USED = 'UPDATE_IS_TRADE_IN_USED';

/** @namespace Scandipwa/Store/Reservation/Action/updateOrder */
export const updateOrder = (payload) => ({
    type: UPDATE_ORDER,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateStatus */
export const updateStatus = (payload) => ({
    type: UPDATE_STATUS,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateBaseOrderInfo */
export const updateBaseOrderInfo = (payload) => ({
    type: UPDATE_BASE_ORDER_INFO,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateOrderItems */
export const updateOrderItems = (payload) => ({
    type: UPDATE_BASE_ORDER_ITEMS,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateReservationPaymentData */
export const updateReservationPaymentData = (payload) => ({
    type: UPDATE_RESERVATION_PAYMENT_DATA,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updatePaymentInfo */
export const updatePaymentInfo = (payload) => ({
    type: UPDATE_PAYMENT_INFO,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateDeliveryInfo */
export const updateDeliveryInfo = (payload) => ({
    type: UPDATE_DELIVERY_INFO,
    payload
});

/** @namespace Scandipwa/Store/Reservation/Action/updateIsTradeInUsed */
export const updateIsTradeInUsed = (payload) => ({
    type: UPDATE_IS_TRADE_IN_USED,
    payload
});
