/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { MY_ACCOUNT_LOGIN_POPUP, MY_VEHICLES_PATH } from 'Component/Router/Router.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountDispatcher,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { getGuestQuoteId } from 'Util/Cart';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import { SMS_CODE_POPUP } from '../SmsCodePopup/SmsCodePopup.config';
import MyAccountSignIn from './MyAccountSignIn.component';

export * from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    is_sms_enabled: state.ConfigReducer.sms_signup_verification_enable,
    isPdp: state.PopupReducer.popupPayload?.[MY_ACCOUNT_LOGIN_POPUP]?.stateToPush?.isPdp
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    getOTPSignInValid: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOTPSignInValid(options, dispatch)
    ),
    otpSignIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.otpSignIn(options, dispatch)
    ),
    showSmsPopup:
        (phone, onSuccess, onError = () => {}, onResend = () => {}, isPdp, isSignIn = false) => dispatch(
            showPopup(SMS_CODE_POPUP, {
                onSuccess, onError, phone, onResend, isPdp, isSignIn
            })
        ),
    createGuestEmptyCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createGuestEmptyCart(dispatch)
    )
});
/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...SourceMyAccountSignInContainer.propTypes,
        isPdp: PropTypes.bool,
        isOTPSignIn: PropTypes.bool,
        is_sms_enabled: PropTypes.bool,
        getOTPSignInValid: PropTypes.func.isRequired,
        otpSignIn: PropTypes.func.isRequired,
        showSmsPopup: PropTypes.func.isRequired,
        createGuestEmptyCart: PropTypes.func.isRequired

    };

    static defaultProps = {
        ...SourceMyAccountSignInContainer.defaultProps,
        isOTPSignIn: false,
        is_sms_enabled: false
    };

    onSuccessSubmitTimeOutRef = createRef();

    /* JAID-257 Overriden to add back to previous page functionality */
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            isOverlayNext = false
        } = this.props;

        const { state } = history?.location;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        try {
            await signIn({ ...fieldPairs, isOverlayNext });
            onSignIn();
            if (state?.isMyVehicle) {
                history.push(appendWithStoreCode(state.prevLocation));
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    async onOTPSignInSuccess(form, fields) {
        const {
            getOTPSignInValid,
            showNotification,
            setLoadingState,
            showSmsPopup,
            createGuestEmptyCart,
            isPdp
        } = this.props;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        const { phone } = fieldPairs;

        try {
            await createGuestEmptyCart();
            const options = {
                ...fieldPairs,
                quote_id: getGuestQuoteId()
            };
            const isOTPSignInValid = await getOTPSignInValid(options);

            if (isOTPSignInValid.generateLoginOTPCode) {
                showSmsPopup(
                    phone,
                    this.onSuccessfulOTPVerification.bind(this, options),
                    () => {},
                    async () => {
                        await getOTPSignInValid(options);
                    },
                    isPdp,
                    true
                );
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            setLoadingState(false);
        }
    }

    async onSuccessfulOTPVerification(fieldPairs, otp_code, onSuccess, stopLoading, proceedDelay) {
        const {
            otpSignIn,
            showNotification,
            setLoadingState,
            isOverlayNext = false
        } = this.props;

        try {
            const [
                // eslint-disable-next-line no-unused-vars
                _,
                showPasswordResetOnLogin = false,
                passwordResetToken = '',
                showEmailField = false
            ] = await otpSignIn({
                ...fieldPairs, otp_code, quote_id: getGuestQuoteId(), proceedDelay, isOverlayNext
            });

            onSuccess();
            stopLoading();

            this.onSuccessSubmitTimeOutRef.current = setTimeout(
                () => {
                    this.onSuccessSubmitAfterOPT(showPasswordResetOnLogin, passwordResetToken, showEmailField);
                    setLoadingState(false);
                },
                proceedDelay
            );
        } catch (error) {
            showNotification('error', getErrorMessage(error));
            setLoadingState(false);
            stopLoading();
        }
    }

    async onSuccessSubmitAfterOPT(showPasswordResetOnLogin, passwordResetToken, showEmailField) {
        const {
            onSignIn,
            isPdp
        } = this.props;
        const { state } = history?.location;

        const isRedirectedToPasswordResetFromPdp = showPasswordResetOnLogin && passwordResetToken && isPdp;
        await onSignIn(isRedirectedToPasswordResetFromPdp);

        if (state?.isMyVehicle && !showPasswordResetOnLogin) {
            history.push(appendWithStoreCode(state.prevLocation));
        }

        if (showPasswordResetOnLogin && passwordResetToken) {
            history.push(
                appendWithStoreCode(`/customer/account/createPassword?token=${passwordResetToken}${showEmailField ? '&email=true' : ''}`),
                { pathToRedirect: appendWithStoreCode(MY_VEHICLES_PATH) }
            );
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        onOTPSignInSuccess: this.onOTPSignInSuccess.bind(this)
    };

    containerProps() {
        const { isOTPSignIn, is_sms_enabled } = this.props;

        return {
            ...super.containerProps(),
            isOTPSignIn,
            is_sms_enabled
        };
    }

    componentWillUnmount() {
        const { current = null } = this.onSuccessSubmitTimeOutRef;

        clearTimeout(current);
    }

    render() {
        return (
            <MyAccountSignIn
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
