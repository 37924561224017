/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';
import {
    ACTIVE_SLIDE_PERCENT
} from 'SourceComponent/Slider/Slider.config';

import './Slider.override.style.scss';

/** @namespace Scandipwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    /**
     * Overridden to remove slider arrows
     */
    renderArrows() {
        const { isOneImage } = this.props;
        return isOneImage ? null : super.renderArrows();
    }

    /**
     * Overridden to remove crumbs on mobile
     */
    renderCrumbs() {
        const { isOneImage } = this.props;
        return isOneImage ? null : super.renderCrumbs();
    }

    /**
     * Overridden to set active image based on direction
     */
    calculateNextSlide(state) {
        const { isVertical } = this.props;
        const {
            translateX,
            translateY,
            lastTranslateX,
            lastTranslateY
        } = state;

        const lastTranslate = isVertical ? lastTranslateY : lastTranslateX;
        const translate = isVertical ? translateY : translateX;

        const { onActiveImageChange } = this.props;

        const slideSize = this.getSlideWidth();

        const fullSliderSize = this.getFullSliderWidth();

        const dir = this.getDir();
        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = dir === 1 ? translate > lastTranslate : translate < lastTranslate;

        if (!translate) {
            return this.onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize);
        }

        if ((dir === 1 && translate >= 0) || (dir === -1 && translate < 0)) {
            onActiveImageChange(0);

            return 0;
        }

        if ((dir === 1 && translate < -fullSliderSize) || (dir === -1 && translate > fullSliderSize)) {
            const activeSlide = Math.round(fullSliderSize / (-slideSize * dir));
            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'ceil' : 'floor'](activeSlidePosition);
            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math[dir === 1 ? 'floor' : 'ceil'](activeSlidePosition);
            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide * dir);

        return activeSlide;
    }

    /**
     * Overridden to add conditions for right to left direction
     */
    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const {
            onActiveImageChange,
            device: {
                isMobile
            },
            onClick,
            disableSingleClick = false,
            isImageZoomPopupActive = false
        } = this.props;

        const dir = this.getDir();

        if (disableSingleClick || isImageZoomPopupActive || isMobile) {
            return -(dir * prevActiveSlider);
        }

        if (onClick) {
            onClick();

            return -prevActiveSlider;
        }

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);
        const elementPositionInDOM = this.draggableRef.current.getBoundingClientRect().x;

        // Calculated slider position based on direction
        const sliderPosition = -prevActiveSlider * dir;

        const realElementPositionInDOM = elementPositionInDOM - lastTranslate;
        const mousePositionInElement = originalX - realElementPositionInDOM;

        if (isMobile) {
            return sliderPosition;
        }

        // If the direction is rtl, slider position should always be greater than 0 when trying to scroll right
        const isLastRGalleryElem = (dir === 1 && -fullSliderPoss < sliderPosition)
        || (dir === -1 && sliderPosition > 0);

        if (slideSize / 2 < mousePositionInElement && isLastRGalleryElem) {
            const activeSlide = sliderPosition - 1;
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        // If the direction is rtl, slide position must be lower than the total number of slides when trying to scroll to the left
        const isLastLGalleryElem = (dir === -1 && fullSliderPoss > sliderPosition)
        || (dir === 1 && lastTranslate);

        if (slideSize / 2 > mousePositionInElement && isLastLGalleryElem) {
            const activeSlide = sliderPosition + 1;
            onActiveImageChange(-activeSlide * dir);

            return activeSlide;
        }

        return sliderPosition;
    }

    /**
     * JUC-166 Overridden to add additional wrapper
     * it will cut edges so we cant see the artifacts.
     * Also moved arrows outside of the "overflow:hidden" area
     */
    render() {
        const { mix, isImageZoomPopupActive } = this.props;

        if (isImageZoomPopupActive) {
            return super.render();
        }

        return (
            <>
                <div
                  block="Slider"
                  elem="AdditionalWrapper"
                >
                    <div
                      block="Slider"
                      mix={ mix }
                      ref={ this.getSliderRef() }
                    >
                        { this.renderSliderContent() }
                        { this.renderCrumbs() }
                    </div>
                </div>
                { this.renderArrows() }
            </>
        );
    }
}

export default SliderComponent;
