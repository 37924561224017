import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    MY_ACCOUNT_LOGIN_POPUP_ID
} from 'Component/MyAccountLoginPopup/MyAccountLoginPopup.config';
import { STATE_LOGGED_IN } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'Component/MyAccountOverlay/MyAccountOverlay.container';
import { LoginAccountContainer } from 'Route/LoginAccount/LoginAccount.container';
import { ACCOUNT_REGISTRATION_URL } from 'Route/MyAccount/MyAccount.config';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountLoginPopup from './MyAccountLoginPopup.component';

/** @namespace Scandipwa/Component/MyAccountLoginPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    payload: state.PopupReducer.popupPayload[MY_ACCOUNT_LOGIN_POPUP_ID] || {}
});

/** @namespace Scandipwa/Component/MyAccountLoginPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setHeaderState: (headerState) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, headerState))
});

/** @namespace Scandipwa/Component/MyAccountLoginPopup/Container */
export class MyAccountLoginPopupContainer extends LoginAccountContainer {
    static propTypes = {
        hidePopup: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            title: PropTypes.string,
            stateToPush: PropTypes.shape({
                prevLocation: PropTypes.string,
                prevSearch: PropTypes.string
            }),
            onSignIn: PropTypes.func,
            isOverlayNext: PropTypes.bool
        }).isRequired,
        setHeaderState: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...LoginAccountContainer.defaultProps,
        payload: {
            title: '',
            stateToPush: {},
            onSignIn: () => {},
            isOverlayNext: false
        }
    };

    // overridden to disable LoginPage logic
    componentDidMount() {}

    componentDidUpdate(prevProps, prevState) {
        const { isSignedIn: prevIsSignedIn } = prevProps;
        const { state: oldMyAccountState } = prevState;
        const { state: newMyAccountState } = this.state;
        const {
            isSignedIn,
            hideActiveOverlay
        } = this.props;

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (
            newMyAccountState === STATE_LOGGED_IN
            || isSignedIn !== prevIsSignedIn
        ) {
            hideActiveOverlay();
        }
    }

    onCreateAccountClick() {
        const {
            payload: { stateToPush },
            hideActiveOverlay
        } = this.props;

        history.push(
            appendWithStoreCode(ACCOUNT_REGISTRATION_URL),
            stateToPush
        );
        hideActiveOverlay();
    }

    containerProps() {
        const {
            payload: {
                title,
                onSignIn,
                isOverlayNext = false
            }
        } = this.props;

        return {
            ...super.containerProps(),
            title,
            onSignIn,
            isOverlayNext
        };
    }

    render() {
        return (
            <MyAccountLoginPopup
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountLoginPopupContainer);
