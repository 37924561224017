import UIContextProvider from 'src/context/ui';

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

/** @namespace Scandipwa/Component/App/Component */
export class AppComponent extends SourceApp {
    renderUIProvider(children) {
        return (
            <UIContextProvider key="UIContext">
                { children }
            </UIContextProvider>
        );
    }

    contextProviders = [
        ...this.contextProviders,
        this.renderUIProvider.bind(this)
    ];
}

export default AppComponent;
