import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        fieldValue: PropTypes.string.isRequired
    };

    /**
     * Overridden to hide placeholder option if disabled
     */
    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true,
            isPlaceholder = false,
            selected = false
        } = option;

        const {
            attr: { hidePlaceholderOption }
        } = this.props;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled || !isAvailable }
              hidden={ hidePlaceholderOption && isPlaceholder }
              selected={ selected }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    }

    /**
     * Overridden to add isSelected mod
     */
    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, fieldValue, options, handleSelectListOptionClick
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isSelected: !!fieldValue || !!attr.value } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { options.map(this.renderNativeOption.bind(this)) }
            </select>
        );
    }

    /**
     * Overridden to not render placeholder option if disabled
     */
    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;
        const {
            attr: { hidePlaceholderOption },
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        if (hidePlaceholderOption && isPlaceholder) {
            return null;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded && isAvailable ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }

    renderOptions() {
        const {
            options,
            isExpanded,
            expandingIsOver
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ {
                  isExpanded,
                  expandingIsOver
              } }
            >
                { options.map(this.renderOption.bind(this)) }
            </ul>
        );
    }

    // overriden to change tabindex to -1
    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="-1"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
