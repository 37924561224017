import {
    UPDATE_NOMATCH,
    UPDATE_NOMATCH_IS_PDP
} from './NoMatch.action';

/** @namespace Scandipwa/Store/NoMatch/Reducer/getInitialState */
export const getInitialState = () => ({
    noMatch: false,
    isPdp: false
});

/** @namespace Scandipwa/Store/NoMatch/Reducer/NoMatchReducer */
export const NoMatchReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_NOMATCH:
        const { noMatch } = action;

        return {
            ...state,
            noMatch,
            isPdp: !noMatch ? false : state.isPdp
        };

    case UPDATE_NOMATCH_IS_PDP:
        const { isPdp } = action;

        return {
            ...state,
            isPdp
        };

    default:
        return state;
    }
};

export default NoMatchReducer;
