import ProductListQuery from 'Query/ProductList.query';
import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    _getOrdersField(options) {
        const ordersField = super._getOrdersField(options);

        // eslint-disable-next-line no-magic-numbers
        return ordersField.addArgument('pageSize', 'Int!', 30);
    }

    _getOrderItemsFields(isSingleOrder) {
        return [
            ...super._getOrderItemsFields(isSingleOrder),
            this._getOrderListProductsField(),
            this._getLabelField(),
            this.getReservationDataPaymentField(),
            this.getTestDriveDataField(),
            this._getOrderPaymentMethodsField(),
            'quote_number',
            'quote_expiration_date',
            'info_message',
            'test_drive'
        ];
    }

    _getOrderListProductsField() {
        return new Field('order_list_products')
            .addFieldList(this._getOrderListProductsFields());
    }

    _getOrderListProductsFields() {
        return [
            'name',
            'is_car',
            'sku',
            this._getOrderListProductImageField(),
            ProductListQuery._getPriceRangeField(),
            ProductListQuery._getCategoriesField(),
            ProductListQuery._getAttributesField(false, true)
        ];
    }

    _getOrderListProductImageField() {
        return new Field('image')
            .addFieldList(this._getOrderListProductImageFields());
    }

    _getOrderListProductImageFields() {
        return [
            'label',
            'path',
            'url'
        ];
    }

    _getLabelField() {
        return new Field('label')
            .addFieldList([
                'text',
                'status',
                'sf_status'
            ]);
    }

    _getBaseOrderInfoFields() {
        return [
            'created_at',
            'currency_code',
            'grand_total',
            'id',
            'increment_id',
            'status',
            'status_label',
            'sub_total',
            'total_qty_ordered',
            'ihf_status'
        ];
    }

    _getOrderByIdField(orderId) {
        const orderProducts = new Field('order_products').addFieldList([
            ...ProductListQuery._getCartProductInterfaceFields(true, true),
            this._getOrderListProductImageField(),
            'is_car'
        ]);
        const baseOrderInfo = new Field('base_order_info').addFieldList(this._getBaseOrderInfoFields());

        return new Field('getOrderById')
            .addArgument('id', 'Int!', orderId)
            .addField(orderProducts)
            .addField(baseOrderInfo)
            .addField(this._getOrderItemsProductsField())
            .addField(this.getReservationDataPaymentField());
    }

    getReservationDataPaymentField() {
        return new Field('reservation_payment_data')
            .addFieldList(this._getReservationDataPaymentFieldList());
    }

    getTestDriveDataField() {
        return new Field('test_drive_details')
            .addFieldList(this._getTestDriveDataFieldList());
    }

    _getReservationDataPaymentFieldList() {
        return [
            'amount',
            'id',
            'masked_cc',
            'name',
            'payment_date',
            'processed',
            'type',
            'currency_code'
        ];
    }

    _getTestDriveDataFieldList() {
        return [
            'sku',
            'vin',
            'date',
            'start_time',
            'end_time',
            'additional_information',
            'showroom_id',
            'showroom_name',
            'booking_id'
        ];
    }

    getCancelOrderMutation(orderId) {
        return new Field('cancelOrder')
            .addArgument('orderId', 'ID!', orderId);
    }

    getUpdatePaymentMutation(paymentInformation) {
        return new Field('updatePaymentInformation')
            .addArgument('paymentInformation', 'PaymentInformationInput!', paymentInformation)
            .addFieldList(this._getOrderPaymentMethodFields());
    }

    _getOrderPaymentMethodFields() {
        return [
            'name',
            'type',
            new Field('additional_data')
                .addFieldList([
                    'name',
                    'value'
                ])
        ];
    }

    getCheckVinAvailabilityMutation(orderId) {
        return new Field('checkVinAvailability')
            .addArgument('orderId', 'ID!', orderId);
    }

    getDeleteAllOrdersMutation() {
        return new Field('deleteAllOrders');
    }

    getOrderAndSellListQuery(options) {
        return new Field('customer')
            .addFieldList(this._getOrderAndSellListFields(options));
    }

    _getOrderAndSellListFields(options) {
        return [
            this._getOrdersField(options),
            this._getSellRequestsField()
        ];
    }

    _getSellRequestsField() {
        const sellRequestsField = new Field('sellRequests');

        return sellRequestsField
            .addFieldList(this._getSellRequestsFields());
    }

    _getSellRequestsFields() {
        return [
            'id',
            'customer_id',
            'brand',
            'model',
            'trim',
            'model_year',
            'mileage',
            'condition',
            'request_status',
            'preliminary_price',
            'updated_at',
            'created_at',
            'license_number',
            'other_brand',
            'other_model',
            'is_showroom_booked',
            this._getSellRequestsImagesFields()
        ];
    }

    _getSellRequestsImagesFields() {
        return new Field('images').addFieldList(['img_front', 'img_vr_back']);
    }

    getPaymentApiCallMutation(input) {
        return new Field('sendPaymentApiCall')
            .addArgument('orderPaymentInput', 'OrderPaymentApiCallInput!', input)
            .addFieldList([
                'status',
                'message'
            ]);
    }
}

export default new OrderQuery();
