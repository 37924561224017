/* eslint-disable max-len */
export const CANCEL_QUOTATION_POPUP = 'cancel_quotation_pupup';

export const VIN_CHECK_POPUP = 'vin_check_popup';

export const ERROR_CANCEL_MESSAGE = __('Couldn\'t cancel an order');

export const MY_VEHICLES_BUY_TABS = {
    id: 'buy',
    name: () => __('BUY CAR')
};

export const MY_VEHICLES_SELL_TABS = {
    id: 'sell',
    name: () => __('SELL CAR')
};
