/* eslint-disable max-lines */
import { TYPE_CATEGORY, TYPE_CMS_PAGE, TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import { isSignedIn } from 'Util/Auth';

import {
    HOMEPAGE,
    LANG_AR,
    LANG_ENG,
    LOGIN,
    LOGOUT,
    NOT_APPLICABLE,
    NOT_FOUND,
    ROOT,
    SELL_PAGE,
    SELL_PATH,
    STORE_ENG
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { baseProductData } from './baseProduct.data';

/** @namespace GtmNew/EventData/General/Data/routes */
export const routes = () => [
    'cart',
    'checkout',
    'account',
    'wishlist',
    'forgot-password',
    'contact',
    'compare',
    'blog',
    'stores',
    'search',
    'testdrive',
    'showroom',
    'service',
    'brochure',
    'myvehicles/buy',
    'myvehicles/sell',
    'reservation'
];

/** @namespace GtmNew/EventData/General/Data/getStoreView */
export const getStoreView = (store) => {
    const { ConfigReducer: { base_link_url = window.location.href, code } = {} } = store || {};
    const { pathname: storePrefix } = new URL(base_link_url);

    const url1stPart = storePrefix.split('/')[1];

    const urlStoreCode = window.storeList.includes(url1stPart) ? url1stPart : STORE_ENG;

    return urlStoreCode || code;
};

/** @namespace GtmNew/EventData/General/Data/getUserAdditionalData */
export const getUserAdditionalData = (store) => {
    const {
        customer: {
            userExistingCustomer = NOT_APPLICABLE,
            userLifetimeValue = 0,
            userLifetimeOrders = 0
        } = {}
    } = store.MyAccountReducer;

    return {
        userExistingCustomer,
        userLifetimeValue: parseInt(userLifetimeValue, 10),
        userLifetimeOrders
    };
};

/** @namespace GtmNew/EventData/General/Data/getLanguage */
export const getLanguage = (store) => (getStoreView(store) === STORE_ENG ? LANG_ENG : LANG_AR);

/** @namespace GtmNew/EventData/General/Data/getCustomerID */
export const getCustomerID = (store) => {
    if (isSignedIn()) {
        return { customerId: store.MyAccountReducer.customer.id || 0 };
    }

    return { customerId: NOT_APPLICABLE };
};

/** @namespace GtmNew/EventData/General/Data/getCustomerName */
export const getCustomerName = (store) => {
    const { firstname, lastname } = store.MyAccountReducer.customer;

    if (isSignedIn()) {
        return { customer_name: `${firstname} ${lastname}` };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getCustomerEmail */
export const getCustomerEmail = (store) => {
    if (isSignedIn()) {
        return { CustomerEmail: store.MyAccountReducer.customer.email || '' };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getLoginState */
export const getLoginState = () => {
    if (isSignedIn()) {
        return { userLoginState: LOGIN };
    }

    return { userLoginState: LOGOUT };
};

/** @namespace GtmNew/EventData/General/Data/getPath */
export const getPath = (pathname = '/', storeView = '') => {
    if (pathname.includes(storeView)) {
        return pathname.replace(`/${storeView}`, '');
    }

    return pathname;
};

/** @namespace GtmNew/EventData/General/Data/getPageType */
export const getPageType = (store, pathname = window.location.pathname) => {
    const storeView = getStoreView(store);
    const path = getPath(pathname, storeView);

    // If user Lands on Homepage
    if (path === ROOT) {
        return { pageType: HOMEPAGE };
    }

    // Add it separately to prevent duplicate triggering on 'myvehicles/sell' path
    if (path === SELL_PATH) {
        return { pageType: SELL_PAGE };
    }

    // If user Lands on one of React routes
    const page = routes().find((route) => {
        if (path.includes(route)) {
            return { pageType: route };
        }
    });

    if (page !== ROOT && page !== undefined) {
        return { pageType: page };
    }

    // if one Rewrite
    const { urlRewrite: { type, notFound = false } } = store.UrlRewritesReducer;
    if (notFound) {
        return { pageType: NOT_FOUND };
    }
    if (type === TYPE_CMS_PAGE) {
        return { pageType: `cmspage${path}` };
    }
    if (type === TYPE_CATEGORY) {
        return { pageType: TYPE_CATEGORY.toLowerCase() };
    }
    if (type === TYPE_PRODUCT) {
        return { pageType: TYPE_PRODUCT.toLowerCase() };
    }
    if (type) {
        return { pageType: type.toLowerCase() };
    }

    return { pageType: '' };
};

/** @namespace GtmNew/EventData/General/Data/getProducts */
export const getProducts = (items, event = '') => {
    // eslint-disable-next-line no-undef
    const fields = [];

    items.map((item) => {
        fields.push(baseProductData(item, false, false, event));
    });

    return fields;
};

/** @namespace GtmNew/EventData/General/Data/getCart */
export const getCart = (store) => {
    const { cartTotals: { items = [] } } = store.CartReducer;

    if (items.length) {
        return {
            cart: getProducts(items)
        };
    }
};

// General Event Data
/** @namespace GtmNew/EventData/General/Data/getGeneralEventData */
export const getGeneralEventData = (store) => {
    if (store) {
        return {
            storeView: getStoreView(store),
            language: getLanguage(store),
            ...getLoginState(),
            ...getCustomerID(store),
            ...getPageType(store),
            ...getUserAdditionalData(store)
        };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getGeneralPurchaseData */
export const getGeneralPurchaseData = (store, additional_data) => {
    if (store && additional_data) {
        const { userExistingCustomer } = getUserAdditionalData(store);

        return {
            ...getCustomerID(store),
            customer_status: userExistingCustomer ? 'existing' : 'not existing'
        };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getPurchaseShippingData */
export const getPurchaseShippingData = (shippingAddress, additional_data) => {
    if (shippingAddress && additional_data) {
        const {
            city = NOT_APPLICABLE,
            postcode = NOT_APPLICABLE,
            region = NOT_APPLICABLE,
            region_id = NOT_APPLICABLE,
            street_address = NOT_APPLICABLE
        } = shippingAddress;

        return {
            shipping_city: city,
            shipping_region: region,
            shipping_country_id: region_id,
            shipping_street: street_address.replace(/\r?\n|\r/g, ' '),
            shipping_postcode: postcode
        };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getPurchaseProduct */
export const getPurchaseProduct = (items = []) => {
    const products = [];

    items.map((item) => {
        products.push({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            brand: item.brand,
            dimension1: item.dimension1,
            dimension2: item.dimension2,
            dimension3: item.dimension3
        });
    });

    return products;
};
