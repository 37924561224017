/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace Scandipwa/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    renderSwatch(option) {
        const { attribute_values = [], attribute_code = '' } = option;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                { attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
            </div>
        );
    }

    /**
     * Overridden to switch attribute titles to h2
     */
    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable,
            inStock,
            parameters
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_code,
                attribute_label,
                attribute_options,
                attribute_id
            } = option;

            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option);
            }

            if (!inStock && !isSwatch) {
                return null;
            }

            const selectedOption = parameters[attribute_code];
            const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

            return (
                <div key={ attribute_id }>
                    <h2 block="ProductConfigurableAttributes" elem="Title">
                        { attribute_label }
                        { isSwatch && (
                            <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                                { selectedOptionLabel }
                            </span>
                        ) }
                    </h2>
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                </div>
            );
        });
    }
}

export default ProductConfigurableAttributesComponent;
