/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';

import { KEY_MILEAGE_SEARCH, KEY_YEAR } from './CategoryFilterOverlayContainer.plugin';

export const PriceSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/PriceSlider'
));

export const YearSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/YearSlider'
));

export const MileageSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/MileageSlider'
));

/**
 * Overridden to change h3 to div
 */
const renderPriceSwatch = (args, callback, instance) => {
    const {
        parameters,
        updateConfigurableVariant,
        configurable_options: {
            price: {
                attribute_code,
                attribute_label
            } = {}
        } = {},
        priceSliderKey,
        currencyCode
    } = instance.props;

    return (
        <div block="SliderAttrWrapper">
            <div
              block="SliderAttrWrapper"
              elem="Title"
            >
                { __('Price') }
                { `, ${currencyCode}` }
            </div>
            <PriceSlider
              key={ priceSliderKey }
              parameters={ parameters }
              priceCode={ attribute_code }
              priceLabel={ attribute_label }
              updateConfigurableVariant={ updateConfigurableVariant }
            />
        </div>
    );
};

/**
 * Overridden to change h3 to div
 */
const renderConfigurableOption = (args, callback, instance) => {
    const [{ attribute_code }] = args;

    if (attribute_code === KEY_YEAR) {
        const {
            parameters,
            updateConfigurableVariant,
            configurable_options: {
                year: {
                    attribute_code,
                    attribute_label
                } = {}
            } = {}
        } = instance.props;

        return (
            <div block="SliderAttrWrapper">
               <div
                 block="SliderAttrWrapper"
                 elem="Title"
               >
                { __('Year') }
               </div>
               <YearSlider
                 parameters={ parameters }
                 priceCode={ attribute_code }
                 priceLabel={ attribute_label }
                 updateConfigurableVariant={ updateConfigurableVariant }
                 param_code={ KEY_YEAR }
               />
            </div>

        );
    }

    if (attribute_code === KEY_MILEAGE_SEARCH) {
        const {
            parameters,
            updateConfigurableVariant,
            configurable_options: {
                mileage_search: {
                    attribute_code,
                    attribute_label
                } = {}
            } = {}
        } = instance.props;

        return (
            <div block="SliderAttrWrapper">
                <div block="SliderAttrWrapper" elem="Title">{ __('Mileage, km') }</div>
                <MileageSlider
                  parameters={ parameters }
                  priceCode={ attribute_code }
                  priceLabel={ attribute_label }
                  updateConfigurableVariant={ updateConfigurableVariant }
                  param_code={ KEY_MILEAGE_SEARCH }
                />
            </div>
        );
    }

    return callback(...args);
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderPriceSwatch,
            renderConfigurableOption
        }
    }
};
