export const renderProductSlider = (args, callback) => {
    if (args && args[0].length === 0) {
        return callback(...args);
    }

    const productsWithPos = args[0].map((product, index) => ({ ...product, position: index + 1 }));

    return callback(...[productsWithPos, ...args.slice(1, args.length)]);
};

export default {
    'Scandipwa/Component/RelatedProductsBlock/Component': {
        'member-function': {
            renderProductSlider
        }
    }
};
