export const WHAT_CUSTOMER_SAY_HEADING = 'WhatCustomersSay-Heading';
export const WHAT_CUSTOMER_SAY_SUB_HEADING = 'WhatCustomersSay-SubHeading';
export const WEBSITE_REVIEWS_WRAPPER = 'WebsiteReviews-Wrapper';
export const WEBSITE_REVIEWS_CARD = 'WebsiteReviews-Card';
export const WEBSITE_REVIEWS_STARS = 'WebsiteReviews-ReviewStars';
export const WEBSITE_REVIEWS_DATE = 'WebsiteReviews-ReviewDate';
export const WEBSITE_REVIEWS_HEADING = 'WebsiteReviews-Heading';
export const WEBSITE_REVIEWS_CONTENT = 'WebsiteReviews-Content';

export const MAX_STAR_SCORE = 5;
