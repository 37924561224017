import { withRouter } from 'react-router-dom';

import Loader from 'Component/Loader';
import MyAccountForgotPassword from 'Component/MyAccountForgotPassword';
import Popup from 'Component/Popup';
import { ForgotPasswordComponent } from 'Route/ForgotPassword/ForgotPassword.component';

import { FORGOT_PASSWORD_POPUP_ID } from './MyAccountForgotPasswordPopup.config';

import './MyAccountForgotPasswordPopup.style.scss';

/** @namespace Scandipwa/Component/MyAccountForgotPasswordPopup/Component */
export class MyAccountForgotPasswordPopupComponent extends ForgotPasswordComponent {
    renderForgotPassword() {
        const {
            state,
            onFormError,
            handleSignIn,
            handleCreateAccount,
            setSignInState,
            setLoadingState,
            isCheckout
        } = this.props;

        return (
            <MyAccountForgotPassword
              state={ state }
              onFormError={ onFormError }
              handleSignIn={ handleSignIn }
              handleCreateAccount={ handleCreateAccount }
              setLoadingState={ setLoadingState }
              setSignInState={ setSignInState }
              isCheckout={ isCheckout }
              isPopUp
            />
        );
    }

    render() {
        const { isLoading, device: { isMobile } } = this.props;

        return (
            <Popup
              id={ FORGOT_PASSWORD_POPUP_ID }
              mix={ { block: 'MyAccountForgotPasswordPopup' } }
              clickOutside={ !isMobile }
              hasCloseBtn={ isMobile }
            >
                <div block="ForgotPassword" elem="InnerWrapper">
                    <Loader isLoading={ isLoading } />
                    { this.renderForgotPasswordWrapper() }
                    { this.renderAdditionalContent() }
                </div>
            </Popup>
        );
    }
}

export default withRouter(
    MyAccountForgotPasswordPopupComponent
);
