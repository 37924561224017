/* eslint-disable no-magic-numbers */
import { DEFAULT_SELECTED_MONTH } from 'Component/LeasingEstimate/LeasingEstimate.config';
import getStore from 'Util/Store';

export {
    calculateFinalPrice, formatCurrency, roundPrice, getLowestPriceTiersPrice
} from 'SourceUtil/Price/Price';

export const SIX_MONTHS = 6;

/**
 * Overriden to include currencyDisplay option
 */
/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return new Intl.NumberFormat(language, {
        style: 'currency', currency, currencyDisplay: 'code', minimumFractionDigits: 0
    }).format(price);
};

/**
 * Added calculation for price per month
 */
/** @namespace Scandipwa/Util/Price/calculatePricePerMonth */
export const calculatePricePerMonth = (price) => {
    const {
        ConfigReducer:
            {
                monthly_payment_display_downpayment: downPaymentPercent = 20,
                monthly_payment_display_interest: interestRatePercent = 9,
                monthly_payment_display_instalments: numberOfMonths = 12
            }
    } = getStore().getState() || {};

    const downPaymentInDecimals = downPaymentPercent / 100;
    const interestRate = interestRatePercent / 100;
    const numberOfYears = numberOfMonths / 12;
    const priceAfterDP = price * (1 - downPaymentInDecimals);
    const pricePerMonth = (priceAfterDP + (priceAfterDP * interestRate * numberOfYears)) / numberOfMonths;

    return Math.round(pricePerMonth);
};

/** @namespace Scandipwa/Util/Price/calculateLeasingEstimate */
export const calculateLeasingEstimate = (price, downPayment = 0, months = DEFAULT_SELECTED_MONTH) => {
    const {
        ConfigReducer:
            {
                leasing_estimate_down_payment_percent: defaultDownPaymentPercent = 20,
                leasing_estimate_interest: interestRatePercent = 9
            }
    } = getStore().getState() || {};

    const selectedDownPayment = downPayment !== 0 ? downPayment : defaultDownPaymentPercent;
    const downPaymentInDecimals = selectedDownPayment / 100;
    const interestRate = interestRatePercent / 100;
    const numberOfYears = months / 12;
    const priceAfterDP = price * (1 - downPaymentInDecimals);

    const pricePerMonth = (priceAfterDP + (priceAfterDP * interestRate * numberOfYears)) / months;

    return Math.round(pricePerMonth);
};

/**
 * Returns leasing terms labels with different translation for 6 months
 * /
/** @namespace Scandipwa/Util/Price/getLeasingTermsLabel */
export const getLeasingTermsLabel = (months, isCapitalized = false) => {
    const monthsNumber = Number(months);
    if (isCapitalized) {
        return monthsNumber === SIX_MONTHS ? __('%s Months_6', months) : __('%s Months', months);
    }

    return monthsNumber === SIX_MONTHS ? __('%s months_6', months) : __('%s months', months);
};
