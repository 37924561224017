import {
    BOOKING_SUCCESS,
    CONFIRMATION_REQUIRED
} from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import NewsletterUnsubscribeQuery from 'Query/UnsubscribeNewsletter.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    setIsExistingCustomer,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLoadingNewsletterUnsubscribe
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken
} from 'Util/Auth';
import { setGuestQuoteId } from 'Util/Cart';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerExists(options = {}, dispatch) {
        const query = MyAccountQuery.getIsEmailAvailable(options);

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerExists/executePost/then */
            (response) => {
                dispatch(setIsExistingCustomer(!response.isEmailAvailable.is_email_available));
            }
        );
    }

    // Overridden to return reservation response
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer, bookingResult } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                if (bookingResult) {
                    const { start_time } = bookingResult;

                    if (start_time) {
                        return this.signIn({ email, password }, dispatch).then(
                            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/signIn/then */
                            () => BOOKING_SUCCESS
                        );
                    }
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    // Overridden to remove merge carts mutation
    async signIn(options = {}, dispatch) {
        const { isOverlayNext = false } = options;

        dispatch(updateIsLoading(true));

        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        this.handleCartAndWishlistOnSignIn(dispatch);

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));

        if (!isOverlayNext) {
            dispatch(hideActiveOverlay());
        }

        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    async handleCartAndWishlistOnSignIn(dispatch) {
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
    }

    async getOTPSignInValid(options = {}, dispatch) {
        dispatch(updateIsLoading(true));

        const generateLoginOTPCodeMutation = MyAccountQuery.getGenerateLoginOTPCodeMutation(options);

        const result = await fetchMutation(generateLoginOTPCodeMutation);
        dispatch(updateIsLoading(false));

        return result;
    }

    async otpSignIn(options = {}, dispatch) {
        const { proceedDelay, isOverlayNext } = options;

        dispatch(updateIsLoading(true));

        const generateCustomerTokenOTP = MyAccountQuery.getGenerateCustomerTokenOTPMutation(options);

        const result = await fetchMutation(generateCustomerTokenOTP);
        const {
            generateCustomerTokenOTP: {
                token,
                showPasswordResetOnLogin = false,
                passwordResetToken = '',
                showEmailField = false
            }
        } = result;

        setTimeout(async () => {
            setAuthorizationToken(token);

            this.handleCartAndWishlistOnSignIn(dispatch);

            await this.requestCustomerData(dispatch);

            dispatch(updateCustomerSignInStatus(true));
            dispatch(updateIsLoading(false));

            // If this flag is true don't close the overlay because showing the next overlay will close the current one automatically
            if (!isOverlayNext) {
                dispatch(hideActiveOverlay());
            }

            dispatch(showNotification('success', __('You are successfully logged in!')));
        }, proceedDelay);

        return [true, showPasswordResetOnLogin, passwordResetToken, showEmailField];
    }

    async unsubscribeFromNewsletter(options = {}, dispatch) {
        dispatch(updateIsLoadingNewsletterUnsubscribe(true));

        const unsubscribeMutation = NewsletterUnsubscribeQuery.getUnsubscribeMutation(options);

        await fetchMutation(unsubscribeMutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/unsubscribeFromNewsletter/then/finally/fetchMutation/then */
            () => {
                dispatch(updateIsLoadingNewsletterUnsubscribe(false));
                dispatch(showNotification('success', __('You have successfully unsubscribed!')));
            },
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/unsubscribeFromNewsletter/then/finally/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoadingNewsletterUnsubscribe(false));
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        ).finally(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/unsubscribeFromNewsletter/then/finally */
            () => {
                history.push(appendWithStoreCode('/'));
            }
        );

        return true;
    }
}

export default new MyAccountDispatcher();
