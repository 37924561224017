/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MAKE, MODEL } from 'Route/ProductPage/ProductPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { calculatePricePerMonth, formatPrice } from 'Util/Price/Price';
import { getAttributeValue } from 'Util/Product/Extract';

import './ProductCard.override.style';

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        isPPMEnabled: PropTypes.bool
    };

    /**
     * Overridden to pass the button mix
     */
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            device
        } = this.props;

        return (
            <AddToCart
              mix={ [
                  { block: this.className, elem: 'AddToCart' },
                  { block: 'Button', mods: { isHollow: !device.isMobile } }
              ] }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              getActiveProduct={ getActiveProduct }
            />
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { inStock } = this.props;

        if (inStock) {
            return super.renderCardLinkWrapper(children, mix);
        }

        return (
            <div
              block="ProductCard"
              elem="Link"
              mix={ mix }
            >
                { children }
            </div>
        );
    }

    /**
     * Overridden to delete reviews
     */
    renderReviews() {
        return null;
    }

    /**
     * Overridden to change text in the button and add isHollow property
     * JAID-201 - Removed notification
     */
    renderAddToCart() {
        const {
            layout,
            inStock,
            device,
            product
        } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (inStock && this.requiresConfiguration()) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button', mods: { isHollow: !device.isMobile } } }
                  disabled={ isPlaceholder }
                >
                    { __('View') }
                </button>
            );
        }

        if (inStock && !this.requiresConfiguration()) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button', mods: { isHollow: !device.isMobile } } }
                  disabled={ isPlaceholder }
                >
                    { __('View') }
                </button>

            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>
                        { __('Sold out') }
                    </p>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    /**
     * Overridden to render short description
     */

    renderShortDescription() {
        const { product: { short_description: { html } = {} }, product } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (isPlaceholder) {
            return (
                <p block="ProductCard" elem="ShortDescription">
                    <TextPlaceholder content="" length="long" />
                </p>
            );
        }

        if (!html) {
            return null;
        }

        return (
            <p block="ProductCard" elem="ShortDescription">
                <Html content={ html } />
            </p>
        );
    }

    /**
     * Overridden to add hasAdditionalLogoOnLoader prop
     */
    renderPicture(mix = {}) {
        const {
            product: {
                id,
                name,
                small_image: { url: imageUrl = '' } = {}
            }
        } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              hasAdditionalLogoOnLoader
              src={ imageUrl }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ imageUrl }
                />
            </>
        );
    }

    /**
     * Overridden render add "add to cart button", change order for rendering brand&name and delete visibleOnHover
     * Removed short description
     */
    renderCardContent() {
        const { renderContent, isProductSlider } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure" mods={ { isProductSlider } }>
                            { this.renderPicture() }
                            { this.renderWishlistButton() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content" mods={ { isProductSlider } }>
                        <div block="ProductCard" elem="Info">
                            { this.renderCustomName() }
                        </div>
                        { this.renderPrice() }
                        { !isProductSlider && this.renderPricePerMonth() }
                        { !isProductSlider && this.renderAttributeLabels() }
                        { !isProductSlider && this.renderAddToCart() }
                    </div>
                </>
            ))
        );
    }

    /**
     * Added to render h3 instead of div
     */
    renderCustomName() {
        const { product: { name, attributes }, isPlp, isWishlist } = this.props;

        if (isPlp || isWishlist) {
            const brand = getAttributeValue(attributes, MAKE);
            const model = getAttributeValue(attributes, MODEL);
            const content = brand || model ? `${brand} ${model}` : null;

            return (
                <h3 block={ this.className } elem="Name">
                    <TextPlaceholder content={ content } length="medium" />
                </h3>
            );
        }

        return (
            <h3 block={ this.className } elem="Name">
                <TextPlaceholder content={ name } length="medium" />
            </h3>
        );
    }

    renderPricePerMonth() {
        const {
            isPPMEnabled,
            productPrice: {
                price: {
                    finalPrice: {
                        currency,
                        value
                    } = {}
                } = {}
            } = {},
            product
        } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;
        const pricePerMonth = formatPrice(calculatePricePerMonth(value), currency);

        if (!isPPMEnabled) {
            return null;
        }

        const content = isPlaceholder ? this.renderTextPlaceholder() : `${pricePerMonth}/${__('monthly installment')}`;

        return (
            <div block="ProductCard" elem="PricePerMonthWrapper">
                <p block="ProductCard" elem="PricePerMonth">
                    { content }
                </p>
            </div>
        );
    }

    renderAttributeLabels() {
        const { attributesLabels } = this.props;

        return (
            <div block="ProductCard" elem="AttributeLabels">
                { attributesLabels.map((label) => {
                    if (!label) {
                        return null;
                    }

                    return (
                        <span
                          block="ProductCard"
                          elem="AttributeLabel"
                        >
                            { label }
                        </span>
                    );
                }) }
            </div>
        );
    }

    /**
    * Overridden to add wrapper to the placeholder for proper styling
     */
    renderPrice() {
        const { product } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (isPlaceholder) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    { this.renderTextPlaceholder() }
                </div>
            );
        }

        return super.renderPrice();
    }
}

export default ProductCardComponent;
