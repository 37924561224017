/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const EVENT_SEARCH = 'eventSearch';
export const SEARCH = 'search';
export const SEARCH_CATEGORY = 'site search';
export const RESULTS_LOADED = 'Results loaded';
export const NO_RESULTS_FOUND = 'No Results Found';
export const SELL_PAGE = 'sell';
export const MY_WISHLIST = 'my-wishlist';
export const WISHLIST = 'wishlist';
export const GROUPED = 'grouped';
export const HOMEPAGE = 'homepage';
export const LOGIN = 'Logged in';
export const LOGOUT = 'Logged out';
export const NOT_APPLICABLE = 'N/A';
export const CATEGORY_PRODUCT_LIST = 'CategoryProductList';
export const PRODUCT_LIST_WIDGET = 'ProductListWidget';
export const CATEOGRY_LIMIT = 5;
export const NOT_FOUND = 'notFound';
export const CHECKOUT = 'checkout';
export const ZERO = 0;
export const ONE = 1;
export const ROOT = '/';
export const SELL_PATH = '/sell';
export const EXCEPTION_REGEX_LIST = [
    // Added this exception to prevent duplicate triggering general event when loading reservation page first time
    /reservation\/\w+\/?$/
];
export const STORE_ENG = 'eng';
export const LANG_ENG = 'English';
export const LANG_AR = 'Arabic';
export const PRODUCT_DETAIL_DELAY = 500;
