/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
import {
    ATTRIBUTE_CODES_ARRAY,
    COLOR_EXTERIOR,
    COLOR_INTERIOR,
    ENGINE,
    STEPS_ORDER,
    TRIM
} from 'Component/ProductActions/ProductActions.config';
import { PRODUCT_ATTS_POPUP_ID } from 'Component/ProductAttributes/ProductAttributes.config';
import { PRODUCT_BUILD_ANOTHER_POPUP_ID } from 'Component/ProductBuildAnother/ProductBuildAnother.config';
import { showPopup } from 'Store/Popup/Popup.action';
import fromCache from 'Util/Cache/Cache';
import { getProductInStock } from 'Util/Product/Extract';
import getStore from 'Util/Store';
import { getQueryParam } from 'Util/Url';

export * from 'SourceUtil/Product/Product';

/** @namespace Scandipwa/Util/Product/showSummaryPopup */
export const showSummaryPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(PRODUCT_ATTS_POPUP_ID, { title: __('Summary') }));
};

/** @namespace Scandipwa/Util/Product/showBuildAnotherPopup */
export const showBuildAnotherPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(PRODUCT_BUILD_ANOTHER_POPUP_ID));
};

/** @namespace Scandipwa/Util/Product/triggerPopup */
export const triggerPopup = (id, options) => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(id, options));
};

/**
 * JAID-533 - added additional check for variant whether it's in stock or not,
 *            replaced immutable initial value with mutation
 * @namespace Scandipwa/Util/Product/filterConfigurableOptions */
export const filterConfigurableOptions = (options, variants) => Object.values(options)
    .reduce((acc, option) => {
        const {
            attribute_values,
            attribute_code
        } = option;

        // show option if it exist as variant for configurable product
        const filteredOptions = attribute_values.reduce((acc, value) => {
            const variantsWithAttr = variants.filter(({ attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return value === foundValue;
            });

            if (variantsWithAttr.some((variant) => fromCache(getProductInStock, [variant]))) {
                acc.push(value);
            }

            return acc;
        }, []);

        acc[attribute_code] = {
            ...option,
            attribute_values: filteredOptions
        };

        return acc;
    }, {});

/*
* Added to process attributes for the summary block
*
*/
/** @namespace Scandipwa/Util/Product/getCarAttributesWithValues */
export const getCarAttributesWithValues = (attributeNames, product = {}) => {
    const { attributes: initialAttributes = {}, parameters = {} } = product;

    const attributes = {};

    if (initialAttributes) {
        attributeNames.forEach((value) => {
            attributes[value] = initialAttributes[value];
        });
    }

    return Object.entries(attributes).reduce((acc, [key, val = {}]) => {
        const { attribute_label = '', attribute_value = '' } = val;

        if (attribute_value) {
            return { ...acc, [attribute_label]: val };
        }

        const valueIndexFromParameter = parameters[key];

        if (valueIndexFromParameter) {
            return { ...acc, [attribute_label]: { ...val, attribute_value: valueIndexFromParameter } };
        }

        return { ...acc, [attribute_label]: val };
    }, {});
};

/*
* JAID-201 - Removed toggle functionality of configurable attributes
*/
/** @namespace Scandipwa/Util/Product/getNewParameters */
export const getNewParameters = (parameters, key, value = '') => ({
    ...parameters,
    [key]: value.toString()
});

// TODO:
// will probably have to add some logic to cache or save the filtered variants
// in order to reduce the number of times this method is called

/** @namespace Scandipwa/Util/Product/getMinimumConfigurableAttrPrice */
export const getMinimumConfigurableAttrPrice = (
    attribute_code, attribute_value, variants, parameters, currentStepNumber
) => {
    const minimumPriceForAttr = { value: -1, currency: 'USD' };

    // eslint-disable-next-line fp/no-let
    let filteredVariants = variants;

    // determine the variants that have the same attributes as the selected ones by the user
    for (let i = 0; i < currentStepNumber - 1 && i < ATTRIBUTE_CODES_ARRAY.length - 1; i++) {
        const attr_code = ATTRIBUTE_CODES_ARRAY[i];

        filteredVariants = filteredVariants.filter(
            (element) => element.attributes?.[attr_code]?.attribute_value === parameters?.[attr_code]
        );
    }

    // filter out current variant that is being selected
    if (currentStepNumber > 0 && currentStepNumber < STEPS_ORDER.length) {
        filteredVariants = filteredVariants.filter(
            (element) => element.attributes[attribute_code]?.attribute_value === attribute_value
        );
    } else {
        return {};
    }

    // determine minimum price for the filtered variants
    filteredVariants.forEach((element) => {
        const {
            price_range: {
                minimum_price: {
                    regular_price: {
                        value,
                        currency
                    }
                }
            },
            stock_item: {
                in_stock
            }
        } = element;

        if (in_stock && (value < minimumPriceForAttr.value || minimumPriceForAttr.value === -1)) {
            minimumPriceForAttr.value = value;
            minimumPriceForAttr.currency = currency;
        }
    });

    if (minimumPriceForAttr.value === -1) {
        return {};
    }

    return minimumPriceForAttr;
};

// Filter media array in product object according to the mediaType
// Returns new objest with filtered media array.
/** @namespace Scandipwa/Util/Product/filterProductAccordingToMediaType */
export const filterProductAccordingToMediaType = (product, mediaType) => {
    const { media_gallery_entries: mediaGalleryEntries = [] } = product;
    const filteredMediaGalleryEntries = mediaGalleryEntries.filter(
        ({ gallery_image_type: galleryImageType }) => galleryImageType === mediaType
    )
        .map((entry, idx) => ({ ...entry, position: idx }));

    return { ...product, media_gallery_entries: filteredMediaGalleryEntries };
};

// Find media entry in product media gallery according to the mediaType
// Returns media entry from product
/** @namespace Scandipwa/Util/Product/findProductAccordingToMediaType */
export const findProductAccordingToMediaType = (product, mediaType) => {
    const { media_gallery_entries: mediaGalleryEntries = [] } = product;
    return mediaGalleryEntries.find(({ types }) => types.includes(mediaType));
};

/** @namespace Scandipwa/Util/Product/hasMediaWithType */
export const hasMediaWithType = (
    mediaGalleryEntries,
    mediaType
) => mediaGalleryEntries.some(({ gallery_image_type }) => gallery_image_type === mediaType);

/**
 * Checks if configurable product attributes are found in the url as params
 */
/** @namespace Scandipwa/Util/Product/hasAttributesInUrlParams */
export const hasAttributesInUrlParams = (location) => ATTRIBUTE_CODES_ARRAY.reduce((prev, current) => getQueryParam(current, location) && prev, true);

/**
 * Returns all the configurable attributes as an object from the url
 */
/** @namespace Scandipwa/Util/Product/getParamsFromUrl */
export const getParamsFromUrl = (location) => {
    const parameters = {};
    ATTRIBUTE_CODES_ARRAY.forEach((element) => {
        parameters[element] = getQueryParam(element, location);
    });

    return parameters;
};

/**
 * Get attributes with values but without options, attributes without values but with option
 * Returns attributes with values and options
 * @namespace Scandipwa/Util/Product/addOptionsToAttributes
 */
export const addOptionsToAttributes = (attributes, variantAttributes) => {
    const attributesWithOptions = Object.entries(variantAttributes)
        .reduce((acc, [key, value]) => {
            if (key in attributes) {
                const { attribute_options } = attributes[key];
                acc[key] = { ...value, attribute_options };
            }

            return acc;
        }, {});

    return attributesWithOptions;
};

// Moved away from the component (JAID-287)
// Returns an array with car and selected options
/** @namespace Scandipwa/Util/Product/prepareCarWithOptions */
export const prepareCarWithOptions = (carSKU = '', options = {}) => Object.values(options)
    .reduce((acc, product) => {
        const { sku, type_id } = product;

        if (type_id === 'dynamic') {
            return [...acc, {
                sku, quantity: 1, dynamic_price: product?.productPrice?.price?.finalPrice?.value, base_product_sku: carSKU
            }];
        }

        return [...acc, { sku, quantity: 1 }];
    }, [{ sku: carSKU, quantity: 1 }]);

/*
** Change name of the product to the car_model from attributes
 */
/** @namespace Scandipwa/Util/Product/changeProductNameToParentName */
export const changeProductNameToParentName = (product) => (product ? {
    ...product,
    name: product?.name.split('-')?.[0]
} : {});

/**
 * Removes the color code `color_name (XXX)` -> `color_name`
 * @namespace Scandipwa/Util/Product/removeColorCode */
export const removeColorCode = (colorString) => colorString.replace(/\s\(\w{1,3}\)$/i, '');

/*
** JAID-320 Returns the appropriate subheading text in productSteps
 */
/** @namespace Scandipwa/Util/Product/getSelectedValueText */
export const getSelectedValueText = (value, label, name, code) => {
    switch (code) {
    case TRIM:
        return `${name} ${value}`;
    case ENGINE:
        return value;
    case COLOR_EXTERIOR:
    case COLOR_INTERIOR:
        return `${label} : ${removeColorCode(value)}`;
    default:
        return `${label} : ${value}`;
    }
};
