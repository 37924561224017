/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { USAGE_BOTH, USAGE_ONLY_SERVICE, USAGE_ONLY_STORE_LIST } from '../../route/StoreFinder/StoreFinder.config';
import {
    UPDATE_LOAD_STATUS,
    UPDATE_STORE_FINDER
} from './StoreFinder.action';

export const SERVICE_FILTER = 'service';

export const initialState = {
    storeListCities: [],
    storeListMapped: {},
    storeByName: {},
    storeById: {},
    storeList: [],
    serviceListCities: [],
    serviceListMapped: {},
    serviceByName: {},
    serviceById: {},
    serviceList: [],
    isLoading: true
};

/** @namespace Storefinder/Store/StoreFinder/Reducer/StoreFinderReducer */
export const StoreFinderReducer = (state = initialState, action) => {
    const { type, storeList = [], isLoading } = action;

    switch (type) {
    case UPDATE_STORE_FINDER:
        const filteredStoreList = storeList.filter(({ usage }) => usage === USAGE_ONLY_STORE_LIST || usage === USAGE_BOTH);
        const storeListObject = filteredStoreList.reduce((cities, store) => {
            const {
                city, longitude, latitude, store_name, identifier
            } = store;

            if (city && longitude && latitude) {
                if (!cities.storeListCities.includes(city)) {
                    cities.storeListCities.push(city);
                }

                if (!cities.storeListMapped[city]) {
                    // eslint-disable-next-line no-param-reassign
                    cities.storeListMapped[city] = [];
                }

                cities.storeListMapped[city].push(store);

                // eslint-disable-next-line no-param-reassign
                cities.storeByName[store_name] = store;

                // eslint-disable-next-line no-param-reassign
                cities.storeById[identifier] = store;
            }

            return cities;
        }, {
            storeListCities: [], storeListMapped: {}, storeByName: {}, storeById: {}
        });

        const filteredServiceList = storeList.filter(({ usage }) => usage === USAGE_ONLY_SERVICE || usage === USAGE_BOTH);
        const serviceListObject = filteredServiceList.reduce((cities, store) => {
            const {
                city, longitude, latitude, store_name, identifier
            } = store;

            if (city && longitude && latitude) {
                if (!cities.serviceListCities.includes(city)) {
                    cities.serviceListCities.push(city);
                }

                if (!cities.serviceListMapped[city]) {
                    // eslint-disable-next-line no-param-reassign
                    cities.serviceListMapped[city] = [];
                }

                cities.serviceListMapped[city].push(store);

                // eslint-disable-next-line no-param-reassign
                cities.serviceByName[store_name] = store;

                cities.serviceById[identifier] = store;
            }

            return cities;
        }, {
            serviceListCities: [], serviceListMapped: {}, serviceByName: {}, serviceById: {}
        });

        return {
            ...state,
            ...storeListObject,
            storeList: filteredStoreList,
            ...serviceListObject,
            serviceList: filteredServiceList,
            isLoading: false
        };
    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default StoreFinderReducer;
