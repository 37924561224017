import {
    ASSISTANT,
    CLIMATIZATION,
    COMFORT,
    CONVENIENCE,
    GEARBOX,
    LIGHTS,
    MULTIMEDIA,
    OTHER_EQUIPMENT,
    PACKAGES,
    SAFETY,
    SEATS,
    WHEELS
} from 'Route/ProductPage/ProductPage.config';

export const FEATURES_ATTRIBUTE_CODES = [
    GEARBOX,
    SAFETY,
    ASSISTANT,
    LIGHTS,
    MULTIMEDIA,
    SEATS,
    COMFORT,
    CLIMATIZATION,
    CONVENIENCE,
    PACKAGES,
    WHEELS,
    OTHER_EQUIPMENT
];

export const SELECT_ATTRIBUTE_TYPE = 'select';
