import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/** @namespace Scandipwa/Component/ProductReviewRating/Component */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
    /**
     * Overridden to remove braces from count
     */
    render() {
        const {
            summary,
            code,
            placeholder,
            mix,
            count
        } = this.props;

        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();

        const ariaText = this.getAriaText(summary, code);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="ProductReviewRating"
              title={ `${summary}%` }
              ref={ this.reviewRating }
              aria-label={ ariaText }
              mix={ mix }
            >
                { this.renderStar(fullCount, STAR_FULL) }
                { this.renderStar(halfFullCount, STAR_HALF_FULL) }
                { this.renderStar(emptyCount, STAR_EMPTY) }
                <span block="ProductReviewRating" elem="Counter">
                    { `${count}` }
                </span>
            </div>
        );
    }
}

export default ProductReviewRatingComponent;
