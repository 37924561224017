export * from 'SourceRoute/ProductPage/ProductPage.config';

export const PDP_ADVANTAGES_BLOCK = 'advantages';
export const HOW_IT_WORKS_BLOCK = 'pdp-how-it-works';

// attributes names
export const MAKE = 'uc_make';
export const MODEL = 'uc_model';
export const YEAR = 'uc_year';
export const MILEAGE = 'mileage_decimal';
export const FUEL = 'uc_fuel';
export const POWER = 'uc_power';
export const COLOR = 'uc_color';
export const BODYSTYLE = 'uc_bodystyle';
export const GEARBOX = 'uc_gearbox';
export const SAFETY = 'uc_safety';
export const ASSISTANT = 'uc_assistant';
export const LIGHTS = 'uc_lights';
export const MULTIMEDIA = 'uc_multimedia';
export const SEATS = 'uc_seats';
export const COMFORT = 'uc_comfort';
export const CLIMATIZATION = 'uc_climatization';
export const CONVENIENCE = 'uc_convenience';
export const PACKAGES = 'uc_packages';
export const WHEELS = 'uc_wheels';
export const OTHER_EQUIPMENT = 'uc_other_equipment';
