import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ShowroomBooking/Query */
export class ShowroomBookingQuery {
    getTestDriveCarsQuery() {
        return new Field('getTestDriveCars')
            .addFieldList(this._getTestDriveCarsFields());
    }

    _getTestDriveCarsFields() {
        return [
            'model_name',
            'model_code'
        ];
    }

    getTestDriveTimeSlotsQuery(options) {
        return new Field('getTestDriveTimeSlots')
            .addArgument('testDriveDetails', 'TestDriveInput!', options)
            .addFieldList(this._getTestDriveTimeSlotsFields());
    }

    _getTestDriveTimeSlotsFields() {
        return [
            'label',
            'value'
        ];
    }

    getBookShowroomQuery(options) {
        return new Field('bookShowroom')
            .addArgument('showroomFormDetails', 'ShowroomFormInput!', options)
            .addFieldList(this._getBookShowroomFields());
    }

    _getBookShowroomFields() {
        return [
            'start_time',
            'end_time',
            'booking_id',
            'redirectToMyVehiclesSell'
        ];
    }
}

export default new ShowroomBookingQuery();
