/* eslint-disable max-len */
import { connect } from 'react-redux';

import { MyAccountForgotPasswordPopupComponent } from 'Component/MyAccountForgotPasswordPopup/MyAccountForgotPasswordPopup.component';
import {
    ForgotPasswordContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'Route/ForgotPassword/ForgotPassword.container';

/** @namespace Scandipwa/Component/MyAccountForgotPasswordPopup/Container */
export class MyAccountForgotPasswordPopupContainer extends ForgotPasswordContainer {
    componentDidMount() {
        const { toggleBreadcrumbs } = this.props;
        super.componentDidMount();
        toggleBreadcrumbs(false);
    }

    // Overridden to remove logic related to breadcrumbs in ForgotPasswordContainer
    updateBreadcrumbs() {
        return null;
    }

    // Overridden to remove Redirect component
    render() {
        return (
        <MyAccountForgotPasswordPopupComponent
          { ...this.containerProps() }
          { ...this.containerFunctions }
        />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordPopupContainer);
