/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';
import { ReactComponent as GulfCarLogoMono } from 'Style/icons/gulfcar-logo-mono.svg';

import './Loader.override.style';

/** @namespace Scandipwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        isPayment: PropTypes.bool
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        isPayment: false
    };

    renderMain() {
        const { isPayment } = this.props;

        if (!isPayment) {
            return this.renderGulfCarLoader();
        }

        return (
            <svg
              block="Loader"
              elem="PaymentMain"
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                    // eslint-disable-next-line max-len
                  d="M31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62ZM31 57C45.3594 57 57 45.3594 57 31C57 16.6406 45.3594 5 31 5C16.6406 5 5 16.6406 5 31C5 45.3594 16.6406 57 31 57Z"
                />
                <path
                  d="M31 2.5C48 2.5 59.5 16.5 59.5 31"
                  strokeWidth="5"
                  strokeLinecap="round"
                />
            </svg>
        );
    }

    renderGulfCarLoader() {
        return (
          <div block="Loader" elem="Main">
            <GulfCarLogoMono />
          </div>
        );
    }
}

export default LoaderComponent;
