export * from 'SourceUtil/Orders/Orders';

export const ORDER_STATUSES = {
    new: 'new',
    processing: 'processing',
    closed: 'closed',
    completed: 'completed',
    canceled: 'canceled',
    denied: 'denied',
    reserved: 'reserved',
    ihfApproved: 'ihf_approved',
    ihfDeclined: 'ihf_declined',
    showroomOnly: 'showroom_only',
    review: 'in_review',
    unavailable: 'unavailable'
};

export const SELL_ORDER_STATUSES = {
    CREATED: 'New',
    DENIED: 'Denied',
    PRE_EVALUATION: 'Preliminary_Evaluation_Completed',
    EVALUATED: 'Evaluated',
    ACCEPTED: 'Accepted',
    PRE_ACCEPTED: 'Pre_Accepted',
    NO_STATUS: 'noStatus', // default status if current status is not in the render map
    REJECTED: 'rejected',
    PRE_NEW: 'Pre_New',
    PRE_NEW_NO_PICS: 'Pre_New_No_Pics',
    SHOWROOM_VISIT_BOOKED: 'showroom_visit_booked' // is not defined in the BE, used when is_showroom_booked is true
};

export const ORDER_STATUSES_MAP = {
    [ORDER_STATUSES.closed]: {
        finished: true
    },
    [ORDER_STATUSES.completed]: {
        finished: true
    },
    [ORDER_STATUSES.canceled]: {
        finished: true
    },
    [ORDER_STATUSES.denied]: {
        finished: true
    }
};

/** @namespace Scandipwa/Util/Orders/getCarFromOrder */
export const getCarFromOrder = (order) => order?.find(({ is_car }) => !!is_car);

/** @namespace Scandipwa/Util/Orders/getOptionsFromOrder */
export const getOptionsFromOrder = (order) => {
    const optionItems = {};
    const optionArray = order?.filter(({ is_car }) => !is_car);
    const optionTotal = optionArray?.reduce((acc, option) => {
        if (option.type_id === 'dynamic') {
            return acc;
        }

        optionItems[option.sku] = option;
        return acc + Number(option?.attributes?.price?.attribute_value);
    }, 0);

    return {
        items: optionItems,
        total: optionTotal
    };
};

/** @namespace Scandipwa/Util/Orders/getSortedOrderListItems */
export const getSortedOrderListItems = (items) => items?.sort((a, b) => {
    if (a.label?.status === ORDER_STATUSES.denied || a.label?.status === ORDER_STATUSES.canceled) {
        return 1;
    }
    if (b.label?.status === ORDER_STATUSES.denied || b.label?.status === ORDER_STATUSES.canceled) {
        return -1;
    }

    return 0;
});

/** @namespace Scandipwa/Util/Orders/getSortedOrderList */
export const getSortedOrderList = (orderList) => ({
    ...orderList,
    items: getSortedOrderListItems(orderList?.items)
});

/** @namespace Scandipwa/Util/Orders/getSortedSellList */
export const getSortedSellList = (list) => list?.sort((a, b) => {
    if (a.request_status === SELL_ORDER_STATUSES.DENIED || a.request_status === SELL_ORDER_STATUSES.REJECTED) {
        return 1;
    }
    if (b.request_status === SELL_ORDER_STATUSES.DENIED || a.request_status === SELL_ORDER_STATUSES.REJECTED) {
        return -1;
    }

    // Sort by latest if both of them are not denied
    return new Date(b.created_at) - new Date(a.created_at);
});

/** @namespace Scandipwa/Util/Orders/getMappedSellCarList */
export const getMappedSellCarList = (sellCarList) => (!sellCarList ? {} : sellCarList.reduce(
    (acc, { brand, model, years }) => {
        if (acc?.[brand]) {
            return {
                ...acc,
                [brand]: {
                    ...acc?.[brand],
                    [model]: years
                }
            };
        }

        return {
            ...acc,
            [brand]: {
                [model]: years
            }
        };
    }, {}
));

/** @namespace Scandipwa/Util/Orders/getInsuranceFromOrder */
export const getInsuranceFromOrder = (order) => {
    const insurance = order?.find(({ is_car, type_id }) => !is_car && type_id === 'dynamic');

    if (insurance) {
        const {
            price_range: {
                minimum_price: {
                    default_price: {
                        value = 0
                    } = {}
                } = {}
            } = {}
        } = getCarFromOrder(order);

        const {
            attributes: {
                price_percentage: {
                    attribute_value
                } = {}
            } = {}
        } = insurance;
        const insurancePrice = value * Number(attribute_value);

        return {
            insurance,
            insurancePrice
        };
    }

    return false;
};
