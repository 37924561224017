/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';

import './RemoveOptionButton.style';

/** @namespace Scandipwa/Component/RemoveOptionButton/Component */
export class RemoveOptionButtonComponent extends PureComponent {
    static propTypes = {
        handleRemoveOption: PropTypes.func.isRequired
    };

    render() {
        const { handleRemoveOption } = this.props;

        return (
            <button onClick={ handleRemoveOption } block="RelatedProductTotals" elem="DeleteBtn" mix={ { block: 'RemoveOptionButton', elem: 'Button' } }>
                <CloseIcon />
            </button>
        );
    }
}

export default RemoveOptionButtonComponent;
