/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import WebsiteReviews from './WebsiteReviews.component';
import {
    WEBSITE_REVIEWS_CONTENT,
    WEBSITE_REVIEWS_DATE,
    WEBSITE_REVIEWS_HEADING,
    WEBSITE_REVIEWS_STARS,
    WEBSITE_REVIEWS_WRAPPER,
    WHAT_CUSTOMER_SAY_HEADING,
    WHAT_CUSTOMER_SAY_SUB_HEADING
} from './WebsiteReviews.config';

/** @namespace Scandipwa/Component/WebsiteReviews/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Scandipwa/Component/WebsiteReviews/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Scandipwa/Component/WebsiteReviews/Container */
export class WebsiteReviewsContainer extends PureComponent {
    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        const { data: { children = [] } = {} } = this.props;
        const {
            blockHeading,
            blockSubHeading,
            reviewsData,
            overallStars
        } = this.prepareData(children);

        return {
            blockHeading,
            blockSubHeading,
            reviewsData,
            overallStars
        };
    };

    prepareData(children) {
        const filteredChildren = this.filterChildren(children);
        const blockHeading = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WHAT_CUSTOMER_SAY_HEADING)?.children?.[0]?.data;
        const blockSubHeading = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WHAT_CUSTOMER_SAY_SUB_HEADING)?.children?.[0]?.data;
        const websiteReviews = this.filterChildren(filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WEBSITE_REVIEWS_WRAPPER)?.children);
        const reviewsData = websiteReviews.map(({ children }, index) => this.prepareReviewData(children, index));
        const overallStars = (reviewsData.reduce((
            acc,
            { reviewStars }
        ) => acc + Number(reviewStars), 0) / reviewsData.length)?.toFixed(1);

        return {
            blockHeading, blockSubHeading, reviewsData, overallStars
        };
    }

    prepareReviewData(children, index) {
        const filteredChildren = this.filterChildren(children);
        const reviewStars = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WEBSITE_REVIEWS_STARS)?.children?.[0]?.data;
        const reviewDate = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WEBSITE_REVIEWS_DATE)?.children?.[0]?.data;
        const reviewHeading = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WEBSITE_REVIEWS_HEADING)?.children?.[0]?.data;
        const reviewContent = filteredChildren.find((
            { attribs: { class: className } }
        ) => className === WEBSITE_REVIEWS_CONTENT)?.children?.[0]?.data;

        return {
            reviewStars, reviewDate, reviewHeading, reviewContent, id: index
        };
    }

    filterChildren(children) {
        return children.reduce((acc, child) => {
            if (child?.data && !child?.data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return acc;
            }

            return [...acc, child];
        }, []);
    }

    render() {
        return (
            <WebsiteReviews
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteReviewsContainer);
