import {
    UPDATE_BASE_ORDER_INFO,
    UPDATE_BASE_ORDER_ITEMS,
    UPDATE_DELIVERY_INFO,
    UPDATE_IS_TRADE_IN_USED,
    UPDATE_ORDER,
    UPDATE_PAYMENT_INFO,
    UPDATE_RESERVATION_PAYMENT_DATA,
    UPDATE_STATUS
} from './Reservation.action';

/** @namespace Scandipwa/Store/Reservation/Reducer/getInitialState */
export const getInitialState = () => ({
    order: [],
    isOrderLoading: false,
    baseOrderInfo: {},
    baseOrderItems: {},
    reservationPaymentData: [],
    paymentData: {},
    deliveryData: {},
    isTradeInUsed: false
});

/** @namespace Scandipwa/Store/Reservation/Reducer/ReservationReducer */
export const ReservationReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_ORDER:
        const { payload: order } = action;

        return {
            ...state,
            order
        };
    case UPDATE_STATUS:
        const { payload: isOrderLoading } = action;

        return {
            ...state,
            isOrderLoading
        };
    case UPDATE_BASE_ORDER_INFO:
        const { payload: baseOrderInfo } = action;

        return {
            ...state,
            baseOrderInfo
        };
    case UPDATE_BASE_ORDER_ITEMS:
        const { payload: baseOrderItems } = action;

        return {
            ...state,
            baseOrderItems
        };
    case UPDATE_RESERVATION_PAYMENT_DATA:
        const { payload: reservationPaymentData } = action;

        return {
            ...state,
            reservationPaymentData
        };
    case UPDATE_PAYMENT_INFO:
        const { payload: paymentData } = action;

        return {
            ...state,
            paymentData
        };
    case UPDATE_DELIVERY_INFO:
        const { payload: deliveryData } = action;

        return {
            ...state,
            deliveryData
        };
    case UPDATE_IS_TRADE_IN_USED:
        const { payload: isTradeInUsed } = action;

        return {
            ...state,
            isTradeInUsed
        };
    default:
        return state;
    }
};

export default ReservationReducer;
