export * from 'Component/ProductActions/ProductActions.config';

export const SUMMARY_POPUP_ID = 'SUMMARY_POPUP_ID';
// Attribute names
export const BROCHURE_PATH = '/media/catalog/product/file/';
export const UC_MAKE = 'uc_make';
export const UC_MILEAGE = 'uc_mileage';
export const UC_YEAR = 'uc_year';
export const UC_COLOR = 'uc_color';
export const UC_FUEL = 'uc_fuel';

export const TRIM = 'trim';
export const MODEL_YEAR = 'model_year';
export const IMAGE = 'image';
export const ENGINE = 'engine';
export const COLOR_INTERIOR = 'color_interior';
export const COLOR_EXTERIOR = 'color_exterior';
export const CAR_MODEL = 'car_model';

// Types of media files to filter
export const EXTERIOR_MEDIA_TYPE = 'exterior';
export const INTERIOR_MEDIA_TYPE = 'interior';
export const BASE_MEDIA_TYPE = 'image';

// This array is needed for getMinimumConfigurableAttrPrice function, need to add all attribute codes here
export const ATTRIBUTE_CODES_ARRAY = [TRIM, ENGINE, COLOR_INTERIOR, COLOR_EXTERIOR];

// Step names
export const TRIM_STEP = 'TRIM_STEP';
export const ACCESSORIES_STEP = 'ACCESSORIES_STEP';
export const INSURANCE_STEP = 'INSURANCE_STEP';
export const SUMMARY_STEP = 'SUMMARY_STEP';
export const ADD_TO_CART_STEP = 'ADD_TO_CART_STEP';
export const SERVICE_PACKAGES_STEP = 'SERVICE_PACKAGES_STEP';

export const OUT_OF_STOCK = 'OUT_OF_STOCK';

export const STEPS_MAP = {
    [TRIM_STEP]: {
        number: 1,
        label: () => __('Trims'),
        advance_button_text: () => __('Next step'),
        isLoadedInLeftMenu: false,
        showGallery: true,
        showAdditionalContent: true
    },
    [SERVICE_PACKAGES_STEP]: {
        number: 2,
        attribute_codes: [{ code: '', label: '' }],
        label: () => __('Service packages'),
        advance_button_text: () => __('Next step'),
        last: false,
        isLoadedInLeftMenu: true
    },
    [ACCESSORIES_STEP]: {
        number: 3,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Next step'),
        label: () => __('Accessories'),
        last: false,
        isLoadedInLeftMenu: true
    },
    [INSURANCE_STEP]: {
        number: 4,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Continue without insurance'),
        advance_button_text_alt: () => __('Continue'),
        isNextButtonSecondary: true,
        label: () => __('Insurance'),
        last: false,
        isLoadedInLeftMenu: true
    },
    [SUMMARY_STEP]: {
        number: 5,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        advance_button_text: () => __('Save'),
        isLoadedInLeftMenu: false,
        last: true
    },
    [ADD_TO_CART_STEP]: {
        number: 6,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        buttonText: () => __('Add to cart'),
        loadingText: __('Adding...'),
        isLoadedInLeftMenu: false
    }
};

// Order of steps to render accordingly
export const STEPS_ORDER = [TRIM_STEP, SERVICE_PACKAGES_STEP, ACCESSORIES_STEP, INSURANCE_STEP, SUMMARY_STEP];
