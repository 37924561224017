/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductAttributeValue from 'Component/ProductAttributeValue';
import TextPlaceholder from 'Component/TextPlaceholder';
import { AttributesType } from 'Type/ProductList.type';

import { SUMMARY_ATTRIBUTES_ARRAY } from './ProductSummary.config';

import './ProductSummary.style';

/** @namespace Scandipwa/Component/ProductSummary/Component */
export class ProductSummaryComponent extends PureComponent {
    static propTypes = {
        attributes: AttributesType.isRequired,
        areDetailsLoaded: PropTypes.bool,
        isPlaceholder: PropTypes.bool,
        title: PropTypes.string,
        isSummaryStep: PropTypes.bool
    };

    static defaultProps = {
        isPlaceholder: false,
        areDetailsLoaded: false,
        title: null,
        isSummaryStep: false
    };

    renderAttribute(code) {
        const { attributes = {} } = this.props;
        const attribute = attributes[code] || {};
        const { attribute_label: label = '', attribute_value: value } = attribute;

        if (!value || !label) {
            return null;
        }

        return (
            <li
              key={ code }
              block="ProductSummary"
              elem="Attribute"
            >
                <span
                  block="ProductSummary"
                  elem="AttributeLabel"
                >
                    { label }
                </span>
                <span
                  block="ProductSummary"
                  elem="AttributeValue"
                >
                    <ProductAttributeValue
                      key={ code }
                      attribute={ attribute }
                      isFormattedAsText
                    />
                </span>
            </li>
        );
    }

    renderContent() {
        const { areDetailsLoaded, isPlaceholder } = this.props;

        if (!areDetailsLoaded && isPlaceholder) {
            return SUMMARY_ATTRIBUTES_ARRAY.map(() => <TextPlaceholder length="long" title="Summary" />);
        }

        return SUMMARY_ATTRIBUTES_ARRAY.map(this.renderAttribute.bind(this));
    }

    render() {
        const {
            title,
            isSummaryStep
        } = this.props;

        return (
            <div
              block="ProductSummary"
              mods={ { isSummaryStep } }
            >
                <h3
                  block="ProductSummary"
                  elem="Heading"
                >
                    { title || __('Summary') }
                </h3>
                <ul block="ProductSummary" elem="Content">
                    { this.renderContent() }
                </ul>
            </div>
        );
    }
}

export default ProductSummaryComponent;
