/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import ExpandableContent from 'Component/ExpandableContent';
import Form from 'Component/Form';
import Image from 'Component/Image/Image.component';
import ProductFeatures from 'Component/ProductFeatures';
import ProductMonthlyPrice from 'Component/ProductMonthlyPrice';
import ProductSummary from 'Component/ProductSummary';
import { DEALERS_OFFER_STEP } from 'Route/OrderReservation';
import { formatPrice } from 'Util/Price/Price';

import './DealersOfferStep.style.scss';

/** @namespace Scandipwa/Component/DealersOfferStep/Component */
export class DealersOfferStepComponent extends PureComponent {
    attributesRenderMap = [
        {
            id: 'productSummary',
            name: __('Vehicle'),
            renderContent: this.renderVehicleInformation.bind(this)
        },
        {
            key: 'productFeatures',
            name: __('Features'),
            renderContent: this.renderProductFeatures.bind(this)
        },
        {
            key: 'productUpgrades',
            name: __('Upgrades'),
            renderContent: this.renderProductUpgrades.bind(this)
        }
    ];

    renderHeader() {
        return (
            <div block="DealersOfferStep" elem="Header">
                <p block="DealersOfferStep" elem="SubHeading">
                    { __('Your quotation is valid for 7 days from the quote creation date.') }
                </p>
            </div>
        );
    }

    renderModelBlock() {
        const { carProduct: { image = {}, name } = {}, baseOrderInfo } = this.props;
        const { url = '', label = '' } = image;
        const { created_at = '', increment_id = '' } = baseOrderInfo;

        const baseInfoData = [
            {
                label: __('Quotation No.'),
                value: increment_id
            },
            {
                label: __('Offered Date'),
                value: created_at
            }
        ];

        return (
            <div block="DealersOfferStep" elem="ModelBlock">
                <div block="DealersOfferStep" elem="ImageBlock">
                    <Image
                      src={ url }
                      alt={ label }
                      isPlain
                    />
                </div>
                <div block="DealersOfferStep" elem="BaseInfoBlock">
                    <h3 block="DealersOfferStep" elem="ModelName">{ name }</h3>
                    { baseInfoData.map(({ label, value }) => (
                        <div key={ label } block="DealersOfferStep" elem="BaseInfoEntity">
                            <span block="DealersOfferStep" elem="BaseInfoLabel">{ label }</span>
                            <span block="DealersOfferStep" elem="BaseInfoValue">{ value }</span>
                        </div>
                    )) }
                </div>
            </div>
        );
    }

    renderVehicleInformation() {
        const {
            carProduct: { attributes = {} } = {}
        } = this.props;

        return <ProductSummary attributes={ attributes } />;
    }

    renderProductFeatures() {
        const { carProduct = {} } = this.props;

        return <ProductFeatures product={ carProduct } />;
    }

    renderProductUpgrades() {
        const {
            optionsDataForOrder: {
                items = {}
            } = {},
            baseOrderItems
        } = this.props;

        return (
            <ul
              block="DealersOfferStep"
              elem="ProductUpgrades"
            >
                { Object.values(items).map((item) => {
                    const {
                        name,
                        sku,
                        price_range: {
                            maximum_price: {
                                default_final_price: {
                                    currency = '',
                                    value: priceValue = ''
                                } = {}
                            } = {}
                        } = {}
                    } = item;

                    const price = priceValue || baseOrderItems?.[sku]?.row_subtotal?.value;

                    return this.renderProductUpgrade(name, formatPrice(price, currency));
                }) }
                { this.renderProductInsurance() }
            </ul>
        );
    }

    renderProductInsurance() {
        const {
            insuranceData: {
                insurance: insuranceProduct = {},
                insurance: {
                    sku,
                    price_range: {
                        maximum_price: {
                            default_final_price: {
                                currency = ''
                            } = {}
                        } = {}
                    } = {}
                } = {},
                insurancePrice = 0
            } = {},
            baseOrderItems
        } = this.props;

        if (!Object.keys(insuranceProduct).length) {
            return null;
        }

        const price = insurancePrice || baseOrderItems?.[sku]?.row_subtotal?.value;

        return this.renderProductUpgrade(__('Insurance'), formatPrice(price, currency));
    }

    renderProductUpgrade(name, formattedPrice) {
        return (
            <li
              block="DealersOfferStep"
              elem="ProductUpgrade"
            >
                <span>{ name }</span>
                <span>{ formattedPrice }</span>
            </li>
        );
    }

    renderProductAttributes() {
        return (
            <div block="DealersOfferStep" elem="ProductAttributes">
                { this.attributesRenderMap.map(({ id, name, renderContent }) => (
                    <ExpandableContent
                      key={ id }
                      heading={ name }
                      isArrow
                      renderIcon
                      mix={ {
                          block: 'DealersOfferStep',
                          elem: 'Expandable'
                      } }
                    >
                        { renderContent() }
                    </ExpandableContent>
                )) }
            </div>
        );
    }

    renderBottomBlock() {
        return (
            <div block="DealersOfferStep" elem="BottomBlock">
                { this.renderTotalBlock() }
                { this.renderActions() }
            </div>
        );
    }

    renderTotalBlock() {
        const {
            baseOrderInfo: { grand_total = '', currency_code = 'USD' },
            isMobile
        } = this.props;

        const price = formatPrice(grand_total, currency_code);
        const totalTextContent = isMobile ? __('Total') : __('Total Amount');

        return (
            <div block="DealersOfferStep" elem="TotalInfoBlock">
                <div block="DealersOfferStep" elem="TotalText">
                    <span>{ totalTextContent }</span>
                </div>
                <div block="DealersOfferStep" elem="TotalAmountBlock">
                    <span block="DealersOfferStep" elem="TotalAmount">{ price }</span>
                        <ProductMonthlyPrice
                          block="DealersOfferStep"
                          elem="TotalAmountPerMonth"
                          price={ grand_total }
                          currency={ currency_code }
                          id="MyVehicles"
                        />
                </div>
            </div>
        );
    }

    renderActions() {
        return (
            <div block="DealersOfferStep" elem="ButtonBlock">
                <button
                  type="submit"
                  block="Button"
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { __('Next') }
                    <ChevronIcon />
                </button>
            </div>
        );
    }

    render() {
        const { handleDealersOfferStep } = this.props;

        return (
            <Form
              attr={ {
                  id: DEALERS_OFFER_STEP
              } }
              mix={ { block: 'CheckoutBilling' } }
              onError={ () => {
              } }
              onSubmit={ handleDealersOfferStep }
            >
                <div block="DealersOfferStep" elem="ContentWrapper">
                    { this.renderHeader() }
                    { this.renderModelBlock() }
                    { this.renderProductAttributes() }
                </div>
                { this.renderBottomBlock() }
            </Form>
        );
    }
}

export default DealersOfferStepComponent;
