import { connect } from 'react-redux';

import {
    MY_ACCOUNT_LOGIN_POPUP_ID
} from 'Component/MyAccountLoginPopup/MyAccountLoginPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

import { MY_ACCOUNT_TEXT_CONSTANTS } from '../MyAccountLoginPopup/MyAccountLoginPopup.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchProps(dispatch),
    showLoginPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_LOGIN_POPUP_ID, payload))
});

/** @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    addProductToCart() {
        const { showLoginPopup, isPdp } = this.props;

        const { location: { pathname, search } } = history;

        if (!isSignedIn()) {
            showLoginPopup({
                title: typeof MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText === 'function'
                    ? MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText()
                    : MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText,
                stateToPush: { isPdp, prevLocation: pathname, prevSearch: search },
                onSignIn: this.saveCart
            });

            return;
        }

        this.addProductToCart();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
