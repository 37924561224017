/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { ProductWishlistButton as SourceComponent }
from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace Scandipwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceComponent {
    onClick(e) {
        e.stopPropagation();

        super.onClick(e);
    }
}

export default ProductWishlistButtonComponent;
