import {
    BODYSTYLE, FUEL, GEARBOX, MILEAGE, YEAR
} from 'Route/ProductPage/ProductPage.config';

export * from 'SourceComponent/ProductCard/ProductCard.config';

export const ATTRIBUTE_CODES_FOR_LABELS = [
    /**
     * Change attribute codes for those attributes that need to be rendered in product card
     * Additional Text is added after the main label text.
     */
    {
        code: YEAR
    },
    {
        code: MILEAGE,
        additionalText: () => __('kilometers')
    },
    {
        code: FUEL
    },
    {
        code: BODYSTYLE
    },
    {
        code: GEARBOX
    }
];
