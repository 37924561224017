import {
    defaultConfig,
    getInitialState as sourceGetInitialState,
    ProductListReducer as sourceProductListReducer
} from 'SourceStore/ProductList/ProductList.reducer';
import {
    APPEND_PAGE,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS,
    UPDATE_VISIBLE_PAGES
} from 'Store/ProductList/ProductList.action';

export { defaultConfig };

/** @namespace Scandipwa/Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    visiblePages: []
});

/** @namespace Scandipwa/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type, visiblePages } = action;

    switch (type) {
    case APPEND_PAGE:
    case UPDATE_LOAD_STATUS:
    case UPDATE_PAGE_LOAD_STATUS:
    case UPDATE_PRODUCT_LIST_ITEMS:
        return sourceProductListReducer(state, action);
    case UPDATE_VISIBLE_PAGES:
        return {
            ...state,
            visiblePages
        };

    default:
        return state;
    }
};

export default ProductListReducer;
