/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
import {
    ADD_INSURANCE,
    ADD_OPTION,
    CLEAR_OPTIONS,
    REMOVE_INSURANCE,
    REMOVE_OPTION
} from './ProductOptions.action';

/** @namespace Scandipwa/Store/ProductOptions/Reducer/getInitialState */
export const getInitialState = () => ({
    categorizedItems: {},
    items: {},
    total: 0,
    regularTotal: 0,
    categorizedItemsTotals: {},
    insurance: {
        product: {},
        value: 0
    }
});

/** @namespace Scandipwa/Store/ProductOptions/Reducer/ProductOptionsReducer */
export const ProductOptionsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case ADD_OPTION:
        if (!state.categorizedItems[action.payload.rule]) {
            const {
                product: {
                    price_range: {
                        maximum_price: {
                            default_price: {
                                value: priceRangeValue,
                                currency
                            }
                        }
                    },
                    type_id
                },
                productPrice: {
                    price: {
                        finalPrice: {
                            value: productPriceValue
                        }
                    }
                },
                productPrice
            } = action.payload;

            const value = type_id === 'dynamic' ? productPriceValue : priceRangeValue;

            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.product.sku]: {
                        ...action.payload.product,
                        productPrice
                    }
                },
                categorizedItems: {
                    ...state.categorizedItems,
                    [action.payload.rule]: [
                        action.payload.product.sku
                    ]
                },
                total: state.total + value,
                regularTotal: state.regularTotal + value,
                categorizedItemsTotals: {
                    ...state.categorizedItemsTotals,
                    [action.payload.rule]: parseFloat(value)
                },
                currency
            };
        }

        const {
            product: {
                price_range: {
                    maximum_price: {
                        default_price: {
                            value: priceRangeValue
                        }
                    }
                },
                type_id
            },
            productPrice: {
                price: {
                    finalPrice: {
                        value: productPriceValue
                    }
                }
            },
            productPrice
        } = action.payload;

        const value = type_id === 'dynamic' ? productPriceValue : priceRangeValue;

        return {
            ...state,
            items: {
                ...state.items,
                [action.payload.product.sku]: {
                    ...action.payload.product,
                    productPrice
                }
            },
            categorizedItems: {
                ...state.categorizedItems,
                [action.payload.rule]: [
                    ...state.categorizedItems[action.payload.rule],
                    action.payload.product.sku
                ]
            },
            total: state.total + value,
            regularTotal: state.regularTotal + value,
            categorizedItemsTotals: {
                ...state.categorizedItemsTotals,
                [action.payload.rule]: state.categorizedItemsTotals[action.payload.rule] + parseFloat(value)
            }
        };

    case CLEAR_OPTIONS:
        return {
            ...state,
            items: {},
            categorizedItems: {},
            total: 0,
            regularTotal: 0,
            categorizedItemsTotals: {},
            insurance: {
                product: {},
                value: 0
            }
        };
    case REMOVE_OPTION:
        if (Object.prototype.hasOwnProperty.call(state.items, action.payload.sku)) {
            const { [action.payload.sku]: sku, ...restOfItems } = state.items;

            const ruleAfterDelete = state.categorizedItems[action.payload.rule];
            const prodIndex = state.categorizedItems[action.payload.rule].indexOf(action.payload.sku);

            if (prodIndex === -1) {
                return state;
            }
            ruleAfterDelete.splice(prodIndex, 1);

            const {
                price_range: {
                    maximum_price: {
                        default_price: {
                            value: priceRangeValue
                        }
                    }
                },
                productPrice: {
                    price: {
                        finalPrice: {
                            value: productPriceValue
                        }
                    }
                },
                type_id
            } = state.items[action.payload.sku];

            const value = type_id === 'dynamic' ? productPriceValue : priceRangeValue;

            return {
                ...state,
                items: restOfItems,
                categorizedItems: {
                    ...state.categorizedItems,
                    [action.payload.rule]: ruleAfterDelete
                },
                total: state.total - value,
                regularTotal: state.regularTotal - value,
                categorizedItemsTotals: {
                    ...state.categorizedItemsTotals,
                    [action.payload.rule]: state.categorizedItemsTotals[action.payload.rule]
                    - parseFloat(value)
                }
            };
        }

        return state;

    case ADD_INSURANCE:
        if (!state.insurance.product.sku || state.insurance.product?.sku !== action.payload.product.sku) {
            const {
                product: {
                    price_range: {
                        maximum_price: {
                            default_price: {
                                value: priceRangeValue
                            }
                        }
                    },
                    type_id
                },
                productPrice: {
                    price: {
                        finalPrice: {
                            value: productPriceValue
                        }
                    }
                },
                productPrice
            } = action.payload;

            const value = type_id === 'dynamic' ? productPriceValue : priceRangeValue;

            return {
                ...state,
                insurance: {
                    product: {
                        ...action.payload.product,
                        productPrice
                    },
                    value
                },
                total: state.total + value
            };
        }

        return {
            ...state
        };

    case REMOVE_INSURANCE:
        return {
            ...state,
            insurance: {
                product: {},
                value: 0
            },
            total: state.total - state.insurance.value
        };

    default:
        return state;
    }
};

export default ProductOptionsReducer;
