import { PRODUCT_DETAIL_DELAY } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_PRODUCT_DETAIL } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const render = (args, callback, instance) => {
    const {
        event,
        product
    } = instance.props;

    const { fired = false } = instance.state;

    if (!(Object.keys(product).length === 0) && !fired) {
        instance.setState({ fired: true });

        setTimeout(() => event(EVENT_GTM_PRODUCT_DETAIL, product), PRODUCT_DETAIL_DELAY);
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            render
        }
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
