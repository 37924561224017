/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getPriceFilterLabel } from 'Util/Category';
import { getBooleanLabel } from 'Util/Product';
import { isRtl } from 'Util/CSS/CSS';

import { KEY_MILEAGE_SEARCH, KEY_YEAR } from './CategoryFilterOverlayContainer.plugin';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        currencyRate: state.ProductListInfoReducer.currencyRate
    };
};

export const getFilterOptionsForPrice = (args, callback, instance) => {
    const { currency_code, currencyRate } = instance.props;
    const priceFilterValue = args[0][0];
    const [fromValue, toValue] = priceFilterValue.split('_');

    const convFromValue = fromValue * currencyRate;
    const convToValue = toValue * currencyRate;

    const labelLtr = getPriceFilterLabel(convFromValue, convToValue, currency_code);
    const labelRtl = getPriceFilterLabel(convFromValue, convToValue, currency_code, true);

    const label = isRtl() ? labelRtl : labelLtr;

    return [{ priceFilterValue, label }];
};

export const getSliderFilterLabel = (from, to) => {
    if (from === '*') {
        return __('Up to %s', from);
    }

    if (to === '*') {
        return __('From %s', to);
    }

    return __('From %s to %s', from, to);
};

export const getFilterOptionsForSliders = (values) => {
    const priceFilterValue = values[0];
    const [fromValue, toValue] = priceFilterValue.split('_');

    return [{ priceFilterValue, label: getSliderFilterLabel(fromValue, toValue) }];
};

export const getResetData = (args, callback, instance) => {
    const [attrCode, attrValues] = args;
    const { availableFilters } = instance.props;
    const filterData = availableFilters[attrCode];

    if (!filterData) {
        return {};
    }

    const {
        is_boolean,
        attribute_label,
        attribute_options,
        attribute_code
    } = filterData;

    // eslint-disable-next-line fp/no-let
    let func = attribute_code === 'price'
        ? instance.getFilterOptionsForPrice.bind(instance)
        : instance.getFilterOptionsDefault.bind(instance);

    if (attribute_code === KEY_YEAR || attribute_code === KEY_MILEAGE_SEARCH) {
        func = getFilterOptionsForSliders;
    }

    return {
        [attribute_label]: func(attrValues, Object.values(attribute_options))
            .map(
                (option) => ({
                    ...option,
                    attribute_code,
                    attribute_label,
                    label: getBooleanLabel(option.label, is_boolean)
                })
            )
    };
};

export default {
    'Component/ResetAttributes/Container': {
        'member-function': {
            getFilterOptionsForPrice,
            getResetData
        }
    },
    'Component/ResetAttributes/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
