/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import DatePicker from 'Component/DatePicker';
import ErrorIcon from 'Component/ErrorIcon';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import FieldFile from 'Component/FieldFile';
import FieldSelect from 'Component/FieldSelect';
import Link from 'Component/Link';
import ToolTipButton from 'Component/ToolTipButton';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import { ReactComponent as CheckMarkIcon } from 'Style/icons/checkmark.svg';
import { ReactComponent as EyeHideIcon } from 'Style/icons/eye-hide-icon.svg';
import { ReactComponent as EyeIcon } from 'Style/icons/eye-icon.svg';
import { noopFn } from 'Util/Common';

import FieldMultiselect from '../../../packages/@scandiweb/flexibleforms/src/component/FieldMultiselect/index';
import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    /**
     * Overridden to change method of rendering password field
     * JAID-73 - Overridden to add flexible form fields
     */
    renderMap = {

        ...this.renderMap,

        // Flexible form fields
        [FIELD_TYPE.image]: this.renderFile.bind(this),
        [FIELD_TYPE.url]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.state]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.country]: this.renderSelect.bind(this),
        [FIELD_TYPE.terms]: this.renderTerms.bind(this),
        [FIELD_TYPE.multiselect]: this.renderMultiSelect.bind(this),
        [FIELD_TYPE.send]: this.renderTerms.bind(this),
        // [FIELD_TYPE.star]: this.renderStar.bind(this)

        // Password input
        [FIELD_TYPE.password]: this.renderPasswordInput.bind(this),

        // Render date picker for date fields
        [FIELD_TYPE.date]: this.renderDatePicker.bind(this, FIELD_TYPE.date),
        [FIELD_TYPE.dateWithMonthYearSelector]: this.renderDatePicker.bind(this, FIELD_TYPE.dateWithMonthYearSelector)

    };

    renderCharacterCounter() {
        const {
            characterCount,
            maxCharacterCount
        } = this.props;

        return (
            <span
              block="Field"
              elem="TextArea-CharacterCounter"
              mods={ { isOverflow: characterCount > maxCharacterCount } }
            >
                { `${characterCount}/${maxCharacterCount}` }
                <span
                  block="Field"
                  elem="TextArea-CharacterName"
                >
                    { __('Byte') }
                </span>
            </span>
        );
    }

    renderFileErrorPopup = this.renderFileErrorPopup.bind(this);

    renderFile() {
        const {
            attr,
            events,
            setRef,
            label,
            subLabel,
            hasTooltip,
            tooltipContent,
            tooltipDirection,
            isTooltipHoverable
        } = this.props;

        this.renderRequiredTag();

        return (
            <FieldFile
              attr={ attr }
              events={ events }
              setRef={ setRef }
              label={ label }
              subLabel={ subLabel }
              hasTooltip={ hasTooltip }
              tooltipContent={ tooltipContent }
              renderErrorPopup={ this.renderFileErrorPopup }
              tooltipDirection={ tooltipDirection }
              isTooltipHoverable={ isTooltipHoverable }
              requiredTag={ this.renderRequiredTag() }
            />
        );
    }

    renderDatePicker(fieldType) {
        const {
            setRef,
            attr: {
                name,
                dateFormat,
                availableDates,
                maxDate,
                minDate,
                placeholder,
                value,
                filteredDays,
                selectedDate,
                useValue
            },
            events,
            isDisabled
        } = this.props;

        return (
            <DatePicker
              setRef={ setRef }
              type={ FIELD_DATE_TYPE.date }
              fieldType={ fieldType }
              uid={ name }
              dateFormat={ dateFormat }
              maxDate={ maxDate }
              availableDates={ availableDates }
              isRequired
              placeholder={ placeholder }
              isDisabled={ isDisabled }
              value={ value }
              filteredDays={ filteredDays }
              minDate={ minDate }
              selectedDate={ selectedDate }
              useValue={ useValue }
              { ...events }
            />
        );
    }

    /**
     * Overridden to implement character count
     */
    renderTextArea() {
        const {
            setRef,
            attr,
            events,
            isDisabled,
            countCharacters,
            maxCharacterCount
        } = this.props;

        return (
            <>
                <textarea
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  { ...attr }
                  { ...events }
                  onInput={ countCharacters }
                />
                { maxCharacterCount > 0 && this.renderCharacterCounter() }
            </>
        );
    }

    renderToolTipButton() {
        const {
            hasTooltip,
            tooltipContent,
            isMobile,
            attr: { id = {} } = {}
        } = this.props;

        if (!hasTooltip) {
            return null;
        }

        return (
            <ToolTipButton
              tooltipContent={ tooltipContent }
              id={ id }
              isHoverable={ !isMobile }
            />
        );
    }

    // Renders fields label above field
    renderLabel() {
        const {
            type, label, hasTooltip, attr: { name } = {}
        } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" mods={ { hasTooltip } } htmlFor={ name || `input-${type}` }>
                    { label }
                    { this.renderRequiredTag() }
                    { hasTooltip && this.renderToolTipButton() }
                </label>
            </div>
        );
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            hasTooltip,
            tooltipContent
        } = this.props;

        return (
            <FieldSelect
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
              hasTooltip={ hasTooltip }
              tooltipContent={ tooltipContent }
            />
        );
    }

    /**
     * JAID-73 - Added multi select field
     */
    renderMultiSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false
        } = this.props;

        return (
            <FieldMultiselect
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
            />
        );
    }

    /**
     * Created to add "Caps Lock is on" overlay to password input
     */
    renderPasswordInput() {
        const { warning } = this.props;

        return (
            <>
                { warning && this.renderCapsLockIsOnOverlay() }
                { this.renderDefaultInput() }
            </>
        );
    }

    /**
      * Created to render CapsLock overlay
      * @returns CapsLockOverlay
      */
    renderCapsLockIsOnOverlay() {
        return (
            <div block="Field" elem="CapsNotification">
                <ErrorIcon />
                <span block="Field" elem="CapsNotificationText">{ __('Caps Lock is on') }</span>
                <div block="Field" elem="Triangle" />
            </div>
        );
    }

    /**
     * Overridden to add ErrorIcon on failed validation
     */
    renderDefaultInput() {
        const {
            type, setRef, attr, events, isDisabled, showErrorAsLabel, validationResponse, handleKeyPressDown, handleBlur,
            attr: {
                mods: {
                    hasError = false
                } = {}
            } = {},
            hasCheckmark = false
        } = this.props;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  onKeyDown={ handleKeyPressDown }
                  onBlur={ handleBlur }
                />
                { (!(!showErrorAsLabel || !validationResponse || validationResponse === true) || hasError) && <ErrorIcon /> }
                { ((validationResponse && validationResponse === true) || hasCheckmark) && <CheckMarkIcon /> }
            </>
        );
    }

    renderCheckMark() {
        return (
            <svg
              block="RadioIcon"
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8335 0.179291L13.0825 1.42833L4.92265 9.58821L0.929443 5.595L2.17849 4.34596L4.92265 7.09012L11.8335 0.179291Z" fill="white" />
            </svg>
        );
    }

    renderDot() {
        return (
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="white" />
            </svg>
        );
    }

    /*
     * Overridden to add span to style disabled labels
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            isExactLabel,
            addRequiredTag
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        const resultLabel = isExactLabel ? label : <span>{ label }</span>;
        const requiredTag = addRequiredTag ? this.renderRequiredTag() : null;

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                    <input
                      ref={ (elem) => setRef(elem) }
                      disabled={ isDisabled }
                      type={ type }
                      { ...attr }
                      { ...inputEvents }
                    />
                    <div block="input-control">
                        { type === FIELD_TYPE.checkbox && this.renderCheckMark() }
                        { type === FIELD_TYPE.radio && this.renderDot() }
                    </div>
                    { resultLabel }
                    { requiredTag }
            </label>
        );
    }

    /**
     * JAID-73 - Added Terms and conditions field
     */
    renderTerms() {
        const {
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            type
        } = this.props;

        const newType = FIELD_TYPE.checkbox;
        const elem = newType.charAt(0).toUpperCase() + newType.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        const renderTermsLabel = () => {
            if (type === FIELD_TYPE.terms) {
                return (
                    <>
                        { __('I agree to the') }
                        &nbsp;
                        <Link to={ attr.url }>{ __('Terms and conditions') }</Link>
                    </>
                );
            }

            return (
                <>
                    { __('Send copy to me') }
                </>
            );
        };

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ newType }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control" />
                { renderTermsLabel() }
            </label>
        );
    }

    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name },
            type
        } = this.props;

        if (!showErrorAsLabel
            || !validationResponse
            || validationResponse === true
            || type === FIELD_TYPE.file
            || type === FIELD_TYPE.image) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                { errorMessages.map((message, index) => this.renderErrorMessage.call(this, message, name + index)) }
            </div>
        );
    }

    renderSuccessMessage() {
        const {
            validationResponse,
            type
        } = this.props;

        if (
            !validationResponse
            || validationResponse !== true
            || type === FIELD_TYPE.file
            || type === FIELD_TYPE.image) {
            return null;
        }

        const { errorMessages = [] } = validationResponse;

        if (errorMessages) {
            return null;
        }

        // To be replaced with actual success message
        return (
            <div block="Field" elem="SuccessMessages">
                { __('Success message.') }
            </div>
        );
    }

    renderFileErrorPopup() {
        const { type, validationResponse } = this.props;

        const hasError = validationResponse !== true && Object.keys(validationResponse || {}).length !== 0;

        if ((type === FIELD_TYPE.file || type === FIELD_TYPE.image) && hasError) {
            const { errorMessages } = validationResponse;

            return (
                <div block="FileError" elem="Wrapper">
                    { errorMessages.map((element) => this.renderFileError(element)) }
                </div>
            );
        }

        return null;
    }

    renderFileError(errorMessage) {
        return (
            <div block="FileError" elem="Item">
                <ErrorIcon />
                <span block="FileError" elem="Text">{ errorMessage }</span>
            </div>
        );
    }

    render() {
        const {
            validationResponse,
            mix,
            isPasswordToggle,
            labelLink
        } = this.props;
        let { type } = this.props;
        const inputRenderer = this.renderMap[type];
        const isPasswordToggleVisible = isPasswordToggle
            && (validationResponse === true || validationResponse === null);

        if (type === FIELD_TYPE.terms || type === FIELD_TYPE.send) {
            type = FIELD_TYPE.checkbox;
        }

        return (
            <div block="Field" elem="Wrapper" mods={ { type } } mix={ mix }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.terms
                        && type !== FIELD_TYPE.checkbox
                        && type !== FIELD_TYPE.radio
                        && type !== FIELD_TYPE.send
                        && type !== FIELD_TYPE.file
                        && type !== FIELD_TYPE.image
                        && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                    { isPasswordToggleVisible && this.renderShowPasswordButton() }
                    { labelLink }
                </div>
                { this.renderErrorMessages() }
                { this.renderSuccessMessage() }
                { type !== FIELD_TYPE.file && this.renderSubLabel() }
            </div>
        );
    }

    renderShowPasswordButton() {
        const {
            onShowPassword,
            isHidePassword
        } = this.props;

        const pattern = /^(?=(?:(?!\blike\b).)*Mac).*/;

        // Do not show icon on MacBooks
        if (pattern.test(navigator.userAgent)) {
            return null;
        }

        return (
            <div
              block="Field"
              elem="ShowPassword"
              aria-label={ __('show password') }
              onClick={ onShowPassword }
                // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => { } }
              role="button"
              tabIndex={ -1 }
            >
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { isHidePassword ? <EyeHideIcon /> : <EyeIcon /> }
            </div>
        );
    }
}

export default FieldComponent;
