import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SELECT_ATTRIBUTE_TYPE } from 'Component/ProductFeatures/ProductFeatures.config';
import { ProductType } from 'Type/ProductList.type';

import ProductFeatures from './ProductFeatures.component';
import { FEATURES_ATTRIBUTE_CODES } from './ProductFeatures.config';
/** @namespace Scandipwa/Component/ProductFeatures/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/ProductFeatures/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductFeatures/Container */
export class ProductFeaturesContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        product: ProductType.isRequired
    };

    containerFunctions = {
        getAttributeValueArray: this.getAttributeValueArray.bind(this)
    };

    containerProps() {
        const {
            isMobile,
            product
        } = this.props;

        return {
            isMobile,
            product,
            attributeColumns: this.getAttributeColumns()
        };
    }

    getAttributeColumns() {
        const {
            product: {
                attributes = {}
            } = {}
        } = this.props;

        const filteredAttributeList = FEATURES_ATTRIBUTE_CODES.filter((attributeCode) => {
            const attribute = attributes[attributeCode] || {};
            const {
                attribute_value
            } = attribute;

            return !!attribute_value;
        });

        const middleIndex = Math.ceil(filteredAttributeList.length / 2);

        return [
            filteredAttributeList.slice(0, middleIndex),
            filteredAttributeList.slice(middleIndex)
        ];
    }

    getAttributeValueArray(attribute) {
        const {
            attribute_type,
            attribute_value
        } = attribute;

        if (attribute_type === SELECT_ATTRIBUTE_TYPE) {
            const {
                attribute_options
            } = attribute;

            const { label } = attribute_options[attribute_value];

            return [label];
        }

        return attribute_value ? attribute_value.split('\r\n') : [];
    }

    render() {
        return (
            <ProductFeatures
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFeaturesContainer);
