import { connect } from 'react-redux';

import { SMS_CODE_POPUP } from 'Component/SmsCodePopup/SmsCodePopup.config';
import { SUMMARY_STEP_POPUP } from 'Component/SummaryStep/SummaryStep.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    payload: state.PopupReducer.popupPayload
});

/** @namespace Scandipwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    containerProps() {
        const {
            hasCloseBtn = false,
            clickOutside,
            isNonClosable = false,
            payload,
            activeOverlay
        } = this.props;
        const { isTooltipMobile } = payload[activeOverlay] || {};

        return {
            ...super.containerProps(),
            hasCloseBtn,
            clickOutside,
            isNonClosable,
            isTooltipMobile
        };
    }

    onVisible() {
        const { activeOverlay } = this.props;

        if (activeOverlay === SMS_CODE_POPUP || activeOverlay === SUMMARY_STEP_POPUP) {
            return;
        }

        super.onVisible();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
