/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './MyAccountCreateAccount.style.scss';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...this.propTypes,
        isFullRegistration: PropTypes.bool.isRequired,
        isSalutationRequired: PropTypes.bool.isRequired,
        submitBtnText: PropTypes.string,
        customActionBlock: PropTypes.node
    };

    /**
     * Overridden to change labels and placeholder texts
     */
    renderCreateAccountPersonalInfoFields() {
        const { isFullRegistration } = this.props;
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                { this.renderSalutationField() }
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('First Name') }
                  attr={ {
                      id: 'firstname',
                      name: 'firstname',
                      defaultValue: firstName,
                      placeholder: __('Enter your first name'),
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Last Name') }
                  attr={ {
                      id: 'lastname',
                      name: 'lastname',
                      defaultValue: lastName,
                      placeholder: __('Enter your last name'),
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                { isFullRegistration && this.renderVatNumberField() }
            </fieldset>
        );
    }

    renderSalutationField() {
        const { salutations, isSalutationRequired } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Salutation') }
              attr={ {
                  id: 'salutation',
                  name: 'salutation',
                  defaultValue: '',
                  selectPlaceholder: __('Mr.'),
                  hidePlaceholderOption: true
              } }
              addRequiredTag={ isSalutationRequired }
              options={ salutations }
              validationRule={ {
                  isRequired: isSalutationRequired
              } }
            />
        );
    }

    renderQidField() {
        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('QID (Qatar ID number)') }
              attr={ {
                  id: 'qid',
                  name: 'qid',
                  placeholder: __('Enter your QID'),
                  inputMode: 'numeric'
              } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE.QID,
                  isRequired: true
              } }
              addRequiredTag
            />
        );
    }

    renderPhoneNumberField() {
        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Mobile Phone') }
              subLabel={ __('To activate your account, you must complete the '
              + 'verification process.') }
              attr={ {
                  id: 'phone',
                  name: 'phone',
                  placeholder: __('e.g. 44638777'),
                  inputMode: 'numeric'
              } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE.phone,
                  isRequired: true
              } }
              addRequiredTag
            />
        );
    }

    renderStreetField() {
        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Street Address') }
              attr={ {
                  id: 'street',
                  name: 'street',
                  placeholder: __('Your street address')
              } }
            />
        );
    }

    renderCityField() {
        const { cities } = this.props;

        return (
            <div block="Field" elem="City">
                <Field
                  type={ FIELD_TYPE.select }
                  label={ __('City') }
                  attr={ {
                      id: 'city',
                      name: 'city',
                      defaultValue: '',
                      selectPlaceholder: __('Your city')
                  } }
                  options={ cities }
                />
            </div>
        );
    }

    /**
     * Overridden to change labels and placeholder texts
     */
    renderCreateAccountSignUpInfoFields() {
        const { isFullRegistration } = this.props;
        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                { this.renderQidField() }
                { isFullRegistration && this.renderEmail() }
                { !isFullRegistration && this.renderPhoneNumberField() }
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    { this.renderPassword() }
                    <Field
                      type={ FIELD_TYPE.password }
                      label={ __('Confirm Password') }
                      attr={ {
                          id: 'confirm_password',
                          name: 'confirm_password',
                          placeholder: __('Enter password again'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const password = document.getElementById('password');
                              return value && password.value === value;
                          },
                          customErrorMessages: {
                              onMatchFail: __('Passwords do not match!')
                          }
                      } }
                      addRequiredTag
                      isPasswordToggle
                    />
                </div>
                { this.renderCreateAccountSignUpAdditionalFields() }
            </fieldset>
        );
    }

    renderCreateAccountSignUpAdditionalFields() {
        const { isFullRegistration } = this.props;

        if (!isFullRegistration) {
            return null;
        }

        return (
            <>
            { this.renderPhoneNumberField() }
            { this.renderStreetField() }
            { this.renderCityField() }
            </>
        );
    }

    renderEmail() {
        const { location: { state: { email = '' } = {} } } = history;

        return (
            <div block="Field" elem="Email">
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('ID (Email)') }
                  subLabel={ __('Please enter a valid email address. To activate your account, you'
                   + ' must complete the email verification process.') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      defaultValue: email,
                      placeholder: __('e.g. abc@gulfcar.com'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
            </div>
        );
    }

    renderPassword() {
        return (
            <div block="Field" elem="Password">
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  subLabel={ __('Enter a password of at least 8 characters,including letters,'
                      + ' numbers, and special symbols.') }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter password'),
                      autocomplete: 'new-password'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password,
                      match: (value) => {
                          const email = document.getElementById('email');
                          return value && email.value !== value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Passwords can\'t be the same as email!')
                      },
                      range: {
                          min: 8
                      }
                  } }
                  addRequiredTag
                  isPasswordToggle
                />
            </div>
        );
    }

    renderAcceptanceCheckboxFields() {
        const { isFullRegistration } = this.props;
        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                { isFullRegistration && this.renderSubscribeToNewsletter() }
                { this.renderTermsCheckbox() }
                { this.renderPrivacyCheckbox() }
            </fieldset>
        );
    }

    renderSubscribeToNewsletter() {
        const { newsletterActive } = this.props;

        if (!newsletterActive) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I wish to receive communication on Gulfcar products & services.') }
              attr={ {
                  id: 'is_subscribed',
                  name: 'is_subscribed'
              } }
            />
        );
    }

    renderTermsCheckbox() {
        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I accept the') }
              labelLink={ this.renderLabelLink(__('Terms & Conditions'), '/terms-and-conditions') }
              attr={ {
                  id: 'terms',
                  name: 'terms',
                  defaultChecked: false
              } }
              validationRule={ {
                  isRequired: true,
                  customErrorMessages: {
                      onRequirementFail: __('This checkbox is required')
                  }
              } }
            />
        );
    }

    renderPrivacyCheckbox() {
        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I accept the') }
              labelLink={ this.renderLabelLink(__('Privacy Policy'), '/privacy-policy-cookie') }
              attr={ {
                  id: 'privacy',
                  name: 'privacy',
                  defaultChecked: false
              } }
              validationRule={ {
                  isRequired: true,
                  customErrorMessages: {
                      onRequirementFail: __('This checkbox is required')
                  }
              } }
            />
        );
    }

    renderLabelLink(linkText, link) {
        return (
            <span block="MyAccountOverlay" elem="LabelWithLink">
                <Link to={ appendWithStoreCode(link) } target="_blank">{ linkText }</Link>
                .
                <span block="Field" elem="Label" mods={ { isRequired: true } }>
                    { ' * ' }
                </span>
            </span>
        );
    }

    renderCreateAccountForm() {
        const {
            onError,
            onSuccess,
            customActionBlock
        } = this.props;

        return (
            <Form
              key="create-account"
              onSubmit={ onSuccess }
              onError={ onError }
            >
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderCreateAccountSignUpInfoFields() }
                { this.renderAcceptanceCheckboxFields() }
                { customActionBlock || this.renderSubmitButton() }
            </Form>
        );
    }

    renderSubmitButton() {
        const { submitBtnText } = this.props;

        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button
                  block="Button"
                  type="submit"
                  mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
                >
                    { submitBtnText || __('Sign Up') }
                </button>
            </div>
        );
    }

    render() {
        const { isFullRegistration } = this.props;

        return (
            <>
                { this.renderCreateAccountForm() }
                { isFullRegistration && this.renderAdditionalField() }
            </>
        );
    }
}

export default MyAccountCreateAccountComponent;
