/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Link from '@scandipwa/scandipwa/src/component/Link';
import { appendWithStoreCode } from '@scandipwa/scandipwa/src/util/Url';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import SourceNewsletterSubscription from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { isCrawler, isSSR } from 'Util/Browser';

import newsletterSubscriptionForm from './NewsletterForm.form';

import './NewsletterSubscription.style';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace Scandipwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const { isTOSAccepted, setTOS } = this.props;

        return newsletterSubscriptionForm(isTOSAccepted, setTOS);
    }

    /**
     * Overridden to change submit text
     */
    renderActions() {
        return (
            <button
              type={ FIELD_TYPE.submit }
              block="Button"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
            >
                { __('Send') }
            </button>
        );
    }

    render() {
        const { isTOSAccepted, setTOS } = this.props;

        const urlPathStart = isSSR() || isCrawler() ? location.origin : '';

        return (
            <div block="NewsletterSubscription">
                <Form { ...this.getFormProps() } block="FieldForm">
                    { this.renderFormBody() }
                </Form>
                <div block="NewsletterSubscription" elem="TermsAndConditionsWrapper">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'termsAndConditionsNewsletter',
                          name: 'termsAndConditionsNewsletter',
                          value: 'termsAndConditionsNewsletter',
                          checked: isTOSAccepted
                      } }
                      label={ __('I agree to the ') }
                      events={ {
                          onChange: setTOS
                      } }
                      addRequiredTag
                      mix={ { block: 'NewsletterSubscription', elem: 'TermsAndConditions-Checkbox' } }
                    />
                    <Link
                      to={ `${urlPathStart}${appendWithStoreCode('/terms-and-conditions')}` }
                      block="NewsletterSubscription"
                      elem="TermsAndConditionsLink"
                      target="_blank"
                    >
                        { __('Terms and Conditions.') }
                    </Link>
                </div>
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
