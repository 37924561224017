import { lazy } from 'react';

import { PRODUCT_PAGE } from '../../component/ProductLabel/ProductLabel.config';

export const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-label" */
    '../../component/ProductLabel'
));
const renderLabels = (props) => {
    const {
        activeProduct: { id: productId },
        device: {
            isMobile
        }
    } = props;
    const amLabelMode = PRODUCT_PAGE;

    if (!isNaN(productId)) {
        return (
             <ProductLabel
               productId={ productId }
               amLabelMode={ amLabelMode }
               isMobile={ isMobile }
             />
        );
    }

    return null;
};

const render = (_args, _callback, instance) => {
    const {
        renderAddToCartBlock,
        renderVehicleHeader
    } = instance.props;

    return (
        <div block="ProductSummaryStep">
            { instance.renderQuotationHeader() }
            { renderLabels(instance.props) }
            { renderVehicleHeader() }
            { instance.renderTitleSubText() }
            <div block="ProductSummaryStep" elem="ContentWrapper">
                <div block="ProductSummaryStep" elem="InfoColumn">
                    { instance.renderInfoBlock() }
                    { renderAddToCartBlock() }
                </div>
                <div block="ProductSummaryStep" elem="ImageColumn" ref={ instance.imageContainerRef }>
                    { instance.renderImageBlock() }
                </div>
            </div>
        </div>
    );
};

export const config = {
    'Scandipwa/Component/ProductSummaryStep/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
