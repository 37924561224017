import {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    updateCustomerDetails,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'SourceStore/MyAccount/MyAccount.action';

export {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_IS_LOADING,
    updateCustomerSignInStatus,
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerPasswordForgotStatus,
    updateIsLoading
};

export const UPDATE_CUSTOMER_EXISTS = 'UPDATE_CUSTOMER_EXISTS';

/** @namespace Scandipwa/Store/MyAccount/Action/setIsExistingCustomer */
export const setIsExistingCustomer = (isExistingCustomer) => ({
    type: UPDATE_CUSTOMER_EXISTS,
    isExistingCustomer
});

export const IS_LOADING_NEWSLETTER_UNSUBSCRIBE = 'IS_LOADING_NEWSLETTER_UNSUBSCRIBE';

/** @namespace Scandipwa/Store/MyAccount/Action/updateIsLoadingNewsletterUnsubscribe */
export const updateIsLoadingNewsletterUnsubscribe = (isLoadingNewsletterSubscribe) => ({
    type: IS_LOADING_NEWSLETTER_UNSUBSCRIBE,
    isLoadingNewsletterSubscribe
});
