/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/StarIcon/StarIcon.component';

/** @namespace Scandipwa/Component/StarIcon/Component */
export class StarIconComponent extends SourceAddIcon {
    /**
     * Overridden to add new star svg
     */
    getStarPath() {
        const { starFill } = this.props;

        if (starFill === STAR_EMPTY) {
            return (
                <path opacity="0.5" d="M8.46415 1.28836C8.63415 0.771111 9.36585 0.771111 9.53585 1.28836L11.288 6.61946C11.3642 6.85139 11.581 7.00795 11.8252 7.00739L17.4336 6.99459C17.9783 6.99335 18.2082 7.68861 17.77 8.01232L13.2346 11.3629C13.0399 11.5067 12.9587 11.7586 13.0327 11.989L14.7501 17.3361C14.9166 17.8545 14.3246 18.2845 13.883 17.966L9.33 14.6815C9.13296 14.5394 8.86704 14.5394 8.67001 14.6815L4.11696 17.966C3.67539 18.2845 3.08344 17.8545 3.24993 17.3361L4.96729 11.989C5.04129 11.7586 4.96006 11.5067 4.76541 11.3629L0.229994 8.01232C-0.208189 7.68861 0.0216564 6.99335 0.566441 6.99459L6.17485 7.00739C6.41898 7.00795 6.63576 6.85139 6.71199 6.61946L8.46415 1.28836Z" />
            );
        }

        if (starFill === STAR_HALF_FULL) {
            return (
                <>
                    <path opacity="0.5" d="M8.46415 1.28836C8.63415 0.771111 9.36585 0.771111 9.53585 1.28836L11.288 6.61946C11.3642 6.85139 11.581 7.00795 11.8252 7.00739L17.4336 6.99459C17.9783 6.99335 18.2082 7.68861 17.77 8.01232L13.2346 11.3629C13.0399 11.5067 12.9587 11.7586 13.0327 11.989L14.7501 17.3361C14.9166 17.8545 14.3246 18.2845 13.883 17.966L9.33 14.6815C9.13296 14.5394 8.86704 14.5394 8.67001 14.6815L4.11696 17.966C3.67539 18.2845 3.08344 17.8545 3.24993 17.3361L4.96729 11.989C5.04129 11.7586 4.96006 11.5067 4.76541 11.3629L0.229994 8.01232C-0.208189 7.68861 0.0216564 6.99335 0.566441 6.99459L6.17485 7.00739C6.41898 7.00795 6.63576 6.85139 6.71199 6.61946L8.46415 1.28836Z" fill="#004781" />
                    <g clipPath="url(#clip0_10134_20110)">
                    <path d="M8.46415 1.28836C8.63415 0.771111 9.36585 0.771111 9.53585 1.28836L11.288 6.61946C11.3642 6.85139 11.581 7.00795 11.8252 7.00739L17.4336 6.99459C17.9783 6.99335 18.2082 7.68861 17.77 8.01232L13.2346 11.3629C13.0399 11.5067 12.9587 11.7586 13.0327 11.989L14.7501 17.3361C14.9166 17.8545 14.3246 18.2845 13.883 17.966L9.33 14.6815C9.13296 14.5394 8.86704 14.5394 8.67001 14.6815L4.11696 17.966C3.67539 18.2845 3.08344 17.8545 3.24993 17.3361L4.96729 11.989C5.04129 11.7586 4.96006 11.5067 4.76541 11.3629L0.229994 8.01232C-0.208189 7.68861 0.0216564 6.99335 0.566441 6.99459L6.17485 7.00739C6.41898 7.00795 6.63576 6.85139 6.71199 6.61946L8.46415 1.28836Z" fill="#005AA3" />
                    </g>
                    <defs>
                    <clipPath id="clip0_10134_20110">
                    <rect width="9" height="18" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                    </defs>
                </>
            );
        }

        if (starFill === STAR_FULL) {
            return (
                <path d="M8.46415 1.28836C8.63415 0.771111 9.36585 0.771111 9.53585 1.28836L11.288 6.61946C11.3642 6.85139 11.581 7.00795 11.8252 7.00739L17.4336 6.99459C17.9783 6.99335 18.2082 7.68861 17.77 8.01232L13.2346 11.3629C13.0399 11.5067 12.9587 11.7586 13.0327 11.989L14.7501 17.3361C14.9166 17.8545 14.3246 18.2845 13.883 17.966L9.33 14.6815C9.13296 14.5394 8.86704 14.5394 8.67001 14.6815L4.11696 17.966C3.67539 18.2845 3.08344 17.8545 3.24993 17.3361L4.96729 11.989C5.04129 11.7586 4.96006 11.5067 4.76541 11.3629L0.229994 8.01232C-0.208189 7.68861 0.0216564 6.99335 0.566441 6.99459L6.17485 7.00739C6.41898 7.00795 6.63576 6.85139 6.71199 6.61946L8.46415 1.28836Z" />
            );
        }

        return null;
    }

    render() {
        return (
            <svg
              block="StarIcon"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
