import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getCarFromOrder, getInsuranceFromOrder, getOptionsFromOrder } from 'Util/Orders/Orders';

import DealersOfferStepComponent from './DealersOfferStep.component';

/** @namespace Scandipwa/Component/DealersOfferStep/Container */
export class DealersOfferStepContainer extends PureComponent {
    static propTypes = {
        handleDealersOfferStep: PropTypes.func.isRequired,
        order: PropTypes.arrayOf(PropTypes.object),
        // eslint-disable-next-line react/forbid-prop-types
        baseOrderItems: PropTypes.object,
        isMobile: PropTypes.bool.isRequired,
        baseOrderInfo: PropTypes.objectOf(PropTypes.any).isRequired,
        isIos: PropTypes.bool.isRequired
    };

    static defaultProps = {
        order: [],
        baseOrderItems: {}
    };

    containerFunctions = {
        getAttributeTextValue: this.getAttributeTextValue
    };

    containerProps() {
        const {
            handleDealersOfferStep,
            order,
            isMobile,
            isIos,
            baseOrderInfo,
            baseOrderItems
        } = this.props;

        const carProduct = getCarFromOrder(order);

        const optionsData = getOptionsFromOrder(order);

        return {
            handleDealersOfferStep,
            carProduct,
            isMobile,
            isIos,
            optionsDataForOrder: optionsData,
            insuranceData: getInsuranceFromOrder(order),
            baseOrderInfo,
            baseOrderItems
        };
    }

    getAttributeTextValue(attribute = {}) {
        const { attribute_value = '', attribute_options = '' } = attribute;
        return attribute_options?.[attribute_value]?.label;
    }

    render() {
        return (
            <DealersOfferStepComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default DealersOfferStepContainer;
