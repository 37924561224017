/**
 * @category  Scandiweb
 * @package   ScandiPWA/CatalogLoadMore
 * @author    Ramona Cunska <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React from 'react';

import Link from 'Component/Link';
import { getQueryParam } from 'Util/Url';

import { getNextPageUrl } from '../util/NextPageUrl';
import { getPreviousPageUrl } from '../util/PreviousPageUrl';

import './ProductList.override.style.plugin';

export class ProductListComponentPlugin {
    /**
     * Extended to add additional props to component.
     */
    propTypes = (args) => ({
        ...args,
        isPageLoading: PropTypes.bool.isRequired,
        isNextPagePreloaded: PropTypes.bool.isRequired,
        isLoadMoreVisible: PropTypes.bool.isRequired,
        loadMoreCount: PropTypes.number.isRequired,
        onLoadMoreButtonClick: PropTypes.func.isRequired,
        onLoadPreviousButtonClick: PropTypes.func.isRequired
    });

    /**
     * We need to remove page scroll-up for desktop devices when load more is enabled
     * therefore we can't call the callback method.
     */
    componentDidUpdate = (args, callback, instance) => {
        const {
            isInfiniteLoaderEnabled
        } = instance.props;

        if (isInfiniteLoaderEnabled) {
            instance.observePageChange();
        }
    };

    /**
     * Extended to replace pagination with "Load more"
     */
    renderPagination = (args, callback, instance) => {
        const { isInfiniteLoaderEnabled } = instance.props;

        if (isInfiniteLoaderEnabled) {
            return this.renderLoadMoreButton.apply(instance);
        }

        return callback.apply(instance, args);
    };

    /**
     * Extended to remove the last page from display in case if it is preloaded and "load more"
     * button was not yet clicked, and to adjust placeholder display, and to include load previous button
     */
    renderPages = (args, callback, instance) => {
        const {
            pages,
            isVisible,
            isLoading,
            isPageLoading,
            isNextPagePreloaded,
            isInfiniteLoaderEnabled,
            updateVisiblePages
        } = instance.props;

        if (isLoading) {
            return callback.apply(instance, args);
        }

        const visiblePages = Object.entries(pages);

        if (isNextPagePreloaded && !isPageLoading) {
            visiblePages.splice(-1);
        }

        updateVisiblePages(visiblePages);

        const pageRenders = visiblePages.map(instance.renderProductPage.bind(instance));

        /**
         * Add placeholders to the end of pages if needed.
         * Placeholders contain the element that is observed and calls next page preloading
         * so we don't want to display it if the next page is already preloaded or loading.
        */
        if (isVisible && isInfiniteLoaderEnabled && !isNextPagePreloaded && !isPageLoading) {
            const key = Math.max(...Object.keys(pages)) + 1;
            pageRenders.push(instance.renderPage({ key }));
        }

        return (
            <>
                { this.renderLoadPreviousButton.apply(instance) }
                { pageRenders }
            </>
        );
    };

    /**
     * New method to render load previous button
     */
    renderLoadPreviousButton() {
        const {
            onLoadPreviousButtonClick,
            device: {
                isMobile
            }
        } = this.props;

        const currentPageNumber = getQueryParam('page', location);

        if (!currentPageNumber || currentPageNumber === '1') {
            return null;
        }

        const previousPage = getPreviousPageUrl(location);
        return (
            <div
              block="ProductList"
              elem="LoadPreviousButtonWrapper"
            >
                <Link
                  block="ProductList"
                  elem="LoadPreviousButton"
                  mix={ { block: 'Button', mods: { isHollow: isMobile } } }
                  onClick={ onLoadPreviousButtonClick }
                  to={ previousPage }
                >
                    { __('Load previous') }
                </Link>
            </div>
        );
    }

    renderLoadMoreButton() {
        const {
            isLoadMoreVisible,
            isNextPagePreloaded,
            isPageLoading,
            onLoadMoreButtonClick,
            device: {
                isMobile
            }
        } = this.props;

        if (!isLoadMoreVisible) {
            return null;
        }

        const label = (!isNextPagePreloaded && isPageLoading)
            ? __('Loading...')
            : __('Load more');

        // We need to generate a link address to the next page for the href property so that
        // all products can be accessible by bots
        const nextPage = getNextPageUrl(location);

        return (
            <div
              block="ProductList"
              elem="LoadMoreButtonWrapper"
            >
                <Link
                  block="ProductList"
                  elem="LoadMoreButton"
                  mix={ { block: 'Button', mods: { isHollow: isMobile } } }
                  onClick={ onLoadMoreButtonClick }
                  to={ nextPage }
                >
                    { label }
                </Link>
            </div>
        );
    }
}

const {
    componentDidUpdate,
    renderPagination,
    renderPages
} = new ProductListComponentPlugin();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            componentDidUpdate,
            renderPagination,
            renderPages
        }
    }
};
