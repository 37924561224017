import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import fromCache from 'Util/Cache/Cache';
import { getAttributeValue } from 'Util/Product/Extract';

import { ATTRIBUTE_CODES_FOR_LABELS } from './ProductCard.config';

/** @namespace Scandipwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isPPMEnabled: state.ConfigReducer.monthly_payment_display_enable
});

/** @namespace Scandipwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        isProductSlider: PropTypes.bool,
        isPPMEnabled: PropTypes.bool,
        isPlp: PropTypes.bool,
        isWishlist: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductCardContainer.defaultProps,
        isProductSlider: false,
        isPPMEnabled: true,
        isPlp: false,
        isWishlist: false
    };

    prepareAttributeLabels() {
        const { product: { attributes } = {} } = this.props;

        if (!attributes) {
            return [];
        }

        return ATTRIBUTE_CODES_FOR_LABELS.map(({ code, additionalText }) => {
            const labelText = fromCache(getAttributeValue, [attributes, code]);

            if (!labelText) {
                return null;
            }

            return additionalText ? `${labelText} ${additionalText()}` : labelText;
        });
    }

    containerProps() {
        const {
            isProductSlider,
            isPPMEnabled,
            isPlp,
            isWishlist
        } = this.props;

        return {
            ...super.containerProps(),
            isProductSlider,
            attributesLabels: this.prepareAttributeLabels(),
            isPPMEnabled,
            isPlp,
            isWishlist
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
