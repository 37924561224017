import { generateQuery, trimEndSlash } from 'SourceUtil/Url/Url';
import getStore from 'Util/Store';

export * from 'SourceUtil/Url/Url';

/** @namespace Scandipwa/Util/Url/setQueryParamsWithoutHistory */
export const setQueryParamsWithoutHistory = (keyValueObject, location, history) => {
    const { state } = location;
    const query = generateQuery(keyValueObject, location, history);

    history.replace({ search: query, state });
};

/** @namespace Scandipwa/Util/Url/getPathWithoutStoreCode */
export const getPathWithoutStoreCode = (isWithSlash = true) => {
    const url = window.location.href;
    const { ConfigReducer: { base_link_url = window.location.href } = {} } = getStore().getState() || {};

    if (url.length < base_link_url.length) {
        return '/';
    }

    return url.replace(base_link_url, isWithSlash ? '/' : '');
};

// removes duplicate url paths like /eng/my-vehicle/my-vehicle
// which can happen when the store config is not loaded yet
/** @namespace Scandipwa/Util/Url/removePathnameDuplicates */
export const removePathnameDuplicates = (storePrefix) => {
    const [, storeCode, extraPath] = storePrefix.split('/');

    if (!extraPath) {
        return storePrefix;
    }

    return `/${ storeCode }/`;
};

/** @namespace Scandipwa/Util/Url/appendWithStoreCode */
export const appendWithStoreCode = (pathname) => {
    const { ConfigReducer: { base_link_url = window.location.href } = {} } = getStore().getState() || {};
    const { pathname: storePrefix } = new URL(base_link_url);

    // storePrefix with removed extra path if it existed
    const formattedStorePrefix = removePathnameDuplicates(storePrefix);

    if (!pathname) {
        return trimEndSlash(formattedStorePrefix);
    }

    // match URLs which have the store code in pathname
    if (new RegExp(`^/(${window.storeList.join('|')})/`, 'i').test(pathname)) {
        return pathname;
    }

    // trim the last slash from URL, and append it to pathname
    return trimEndSlash(formattedStorePrefix).concat(!pathname.startsWith('/') ? `/${ pathname }` : pathname);
};
